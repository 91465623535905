import React from "react";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withContext } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, FieldArray } from "redux-form";
import {
  Card,
  CardContent,
  ListSubheader,
  TextField,
  Button,
  CardActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormTextField from "../form/FormTextField";
import FormCurrencyAutoComplete from "../form/FormCurrencyAutoComplete";
import FlexBox from "../ui-core/FlexBox";
import { createNotFalsyValidator } from "../../helpers/FormUtils";
import { parseFloat } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import PageLoading from "../../components/ui-core/PageLoading";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);
    return {
      getLocalisationMessage,
    };
  }),
  useSheet({
    change: { margin: "0 18px" },
  }),
  withContext(
    {
      getCachedCurrency: PropTypes.func,
      getCurrencyPredictions: PropTypes.func,
    },
    props => ({
      getCachedCurrency: props.getCachedCurrency,
      getCurrencyPredictions: props.getCurrencyPredictions,
    }),
  ),
  reduxForm({
    form: "SupplierCurrencySettingsForm",
    enableReinitialize: true,
  }),
);
function CurrencyField(props) {
  const { getLocalisationMessage } = props;
  return (
    <FlexBox gutter={8} flex={true} direction="column">
      <FlexBox flex={true} direction="column">
        {props.fields.map((item, index) => (
          <FlexBox gutter={8} flex={true} key={index}>
            <FlexBox flex={true} direction="column">
              <TextField
                disabled={true}
                fullWidth={true}
                defaultValue="1"
                id={`index${index}`}
                label={getLocalisationMessage("rate", "Rate")}
              />
            </FlexBox>

            <FlexBox flex={true} direction="column">
              <FormCurrencyAutoComplete
                fullWidth={true}
                label={getLocalisationMessage("from", "From")}
                name={`${item}.currency`}
                validate={createNotFalsyValidator(
                  getLocalisationMessage("select_currency", "Select Currency"),
                )}
              />
            </FlexBox>

            <FlexBox justify="center" align="center" direction="column">
              <span className={props.classes.change}>&lt;=&gt;</span>
            </FlexBox>

            <FlexBox flex={true} direction="column">
              <TextField
                disabled={true}
                fullWidth={true}
                label={getLocalisationMessage("to", "To")}
                defaultValue={props.supplierCurrency.get("name")}
                id={fp.toString(index)}
              />
            </FlexBox>

            <FlexBox flex={true} direction="column">
              <FormTextField
                fullWidth={true}
                parseOnBlur={parseFloat}
                label={getLocalisationMessage("exchange_rate", "Exchange rate")}
                name={`${item}.exchangeRate`}
                validate={createNotFalsyValidator(
                  getLocalisationMessage(
                    "enter_exchange_rate",
                    "Enter Exchange Rate",
                  ),
                )}
              />
            </FlexBox>

            <FlexBox direction="column" justify="center">
              <Button
                label={getLocalisationMessage("remove", "Remove")}
                onClick={() => props.fields.remove(index)}
              />
            </FlexBox>
          </FlexBox>
        ))}
      </FlexBox>
      <FlexBox>
        <Button
          label={getLocalisationMessage(
            "add_exchange_rate",
            "Add Exchange Rate",
          )}
          onClick={() => props.fields.push({})}
        />
      </FlexBox>
    </FlexBox>
  );
}

CurrencyField.propTypes = {
  fields: PropTypes.object,
  supplierCurrency: PropTypes.instanceOf(Map),
  classes: PropTypes.object,
  getLocalisationMessage: PropTypes.func.isRequired,
};

SupplierCurrencySettingsForm.propTypes = {
  classes: PropTypes.object,
  dirty: PropTypes.bool,
  reset: PropTypes.func,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  supplierCurrency: PropTypes.instanceOf(Map).isRequired,
  getCachedCurrency: PropTypes.func.isRequired,
  getCurrencyPredictions: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function SupplierCurrencySettingsForm(props) {
  const { getLocalisationMessage } = props;
  return (
    <form onSubmit={props.handleSubmit}>
      <PageLoading isLoading={props.submitting} />

      <Card>
        <CardContent>
          <ListSubheader>
            {getLocalisationMessage(
              "exchange_rate_settings",
              "Exchange rate settings",
            )}
          </ListSubheader>
          <FlexBox container={8}>
            <FieldArray
              name="items"
              component={CurrencyField}
              props={{
                supplierCurrency: props.supplierCurrency,
                classes: props.classes,
                getLocalisationMessage: props.getLocalisationMessage,
              }}
            />
          </FlexBox>
        </CardContent>
        <CardActions>
          <Button
            disabled={
              props.pristine ||
              props.invalid ||
              props.submitting ||
              !props.dirty
            }
            type="submit"
          >
            {getLocalisationMessage("submit", "Submit")}
          </Button>
          <Button
            disabled={props.pristine || props.submitting}
            onClick={props.reset}
          >
            {getLocalisationMessage("reset", "Reset")}
          </Button>
        </CardActions>
      </Card>
    </form>
  );
}

export default enhancer(SupplierCurrencySettingsForm);
