import { Observable } from "rxjs";
import React from "react";
import { fromJS, List, OrderedSet } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  createEventHandler,
  getContext,
  mapPropsStream,
} from "recompose";
import PropTypes from "prop-types";
import { IconButton, MenuItem, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { Add, FilterList } from "@material-ui/icons";
import { isEqualData } from "../../helpers/DataUtils";
import { formatText } from "../../helpers/FormatUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { toUserFilterMapper } from "../../helpers/UserFilterMapper";
import { getUser } from "../../reducers/ProfileReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import { getUserList } from "../../api/admin/AdminUserApi";
import AdminUserFilterWrapper from "../../wrappers/admin/AdminUserFilterWrapper";
import AdminUserEditDialogWrapper from "../../wrappers/admin/AdminUserEditDialogWrapper";
import AdminUserCreateDialogWrapper from "../../wrappers/admin/AdminUserCreateDialogWrapper";
import AdminSupplierEditDialogWrapper from "../../wrappers/admin/AdminSupplierEditDialogWrapper";
import AdminSupplierCreateDialogWrapper from "../../wrappers/admin/AdminSupplierCreateDialogWrapper";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import MenuButtonMore from "../../components/ui-core/MenuButtonMore";
import OverallStatusTabs from "../../components/ui-core/OverallStatusTabs";
import DataList, { DataListColumn } from "../../components/data-list/DataList";
import DateTimeCell from "../../components/data-list/DateTimeCell";
import MultiLineCell from "../../components/data-list/MultiLineCell";
import UploadUsersDialog from "../../components/users-core/UploadUsersDialog";
import CheckOverallStatus from "../../components/route-validators/CheckOverallStatus";
import { updateHash, updateQuery } from "../../../shared/helpers/UrlUtils";
import { ROLE_ADMIN_VIEWER } from "../../../shared/constants/Authorities";
import { CREATE_USER_XLS_URL } from "../../../shared/constants/FileProxyControllerConstants";
import { hasRole } from "../../helpers/RoleUtils";

const USERS_FILTER_DIALOG_HASH = "#UFDH";
const USER_UPLOAD_ORDER_DIALOG_HASH = "#UUODH";

const enhancer = compose(
  connect((state) => {
    const userRoles = getUser(state).get("roles") || [];

    return {
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      isAdminViewer: hasRole(userRoles, ROLE_ADMIN_VIEWER),
    };
  }),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
  }),
  mapPropsStream(
    pipeStreams(
      (propsStream) => {
        const filterStream = propsStream
          .map(fp.flow(fp.get("location.query"), toUserFilterMapper))
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(filterStream, (props, filter) => ({
          ...props,
          filter,
        }));
      },
      (propsStream) => {
        const { handler: onRowSelect, stream: onRowSelectStream } =
          createEventHandler();
        const { handler: onRequestRefresh, stream: onRequestRefreshStream } =
          createEventHandler();

        const listResponseStream = propsStream
          .distinctUntilKeyChanged("filter")
          .switchMap((props) =>
            getUserList(props.filter)
              .repeatWhen(() => onRequestRefreshStream)
              .catch((error) => Observable.of({ error })),
          )
          .map(
            fp.flow(
              (response) => fromJS(response),
              (response) =>
                fromJS({
                  pending: response.get("pending", false),
                  total: response.getIn(["payload", "data", "total"], 0),
                  list: response.getIn(["payload", "data", "list"], List()),
                }),
            ),
          )
          .do(() => onRowSelect(OrderedSet()));

        return propsStream
          .combineLatest(
            listResponseStream,
            onRowSelectStream
              .distinctUntilChanged(isEqualData)
              .startWith(OrderedSet()),
            (props, listResponse, selectedIds) => ({
              ...props,
              onRequestRefresh,
              onRowSelect,
              selectedIds,
              list: listResponse.get("list"),
              total: listResponse.get("total"),
              isLoading: listResponse.get("pending"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

AdminUsersListContainer.propTypes = {
  location: PropTypes.object,
  setLocationHash: PropTypes.func,
  setLocationQueryFilter: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  total: PropTypes.number,
  isLoading: PropTypes.bool,
  list: PropTypes.instanceOf(List),
  filter: PropTypes.instanceOf(DataListFilter),
  onRowSelect: PropTypes.func,
  selectedIds: PropTypes.instanceOf(OrderedSet),
  setLocationQuery: PropTypes.func,
  replaceLocationHash: PropTypes.func,

  isAdminViewer: PropTypes.bool,
  getLocalisationMessage: PropTypes.func,
};

function AdminUsersListContainer(props) {
  const { getLocalisationMessage } = props;
  const NA = getLocalisationMessage("na", "N/A");

  return (
    <AdminAppLayout
      slug="users"
      title={getLocalisationMessage("users", "Users")}
    >
      <UploadUsersDialog
        onComplete={() => {
          props.onRequestRefresh();
          props.replaceLocationHash(null);
        }}
        open={props.location.hash === USER_UPLOAD_ORDER_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
      />
      <CheckOverallStatus location={props.location} />

      <AdminUserCreateDialogWrapper
        open={props.location.query.create === "true"}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("create"));
        }}
        onSupplierClick={(id) =>
          props.setLocationQuery(fp.set("view_supplier", id))
        }
        onCreateSupplierClick={() =>
          props.setLocationQuery(fp.set("create_supplier", true))
        }
      />

      <AdminUserFilterWrapper
        open={props.location.hash === USERS_FILTER_DIALOG_HASH}
        filter={props.filter}
        onRequestClose={() => props.replaceLocationHash(null)}
        onFilterChange={(filter) => {
          props.setLocationQueryFilter(filter);
          props.replaceLocationHash(null);
        }}
      />

      <AdminUserEditDialogWrapper
        userId={fp.toFinite(props.location.query.view)}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("view"));
        }}
        onSupplierClick={(id) =>
          props.setLocationQuery(fp.set("view_supplier", id))
        }
        onCreateSupplierClick={() =>
          props.setLocationQuery(fp.set("create_supplier", true))
        }
      />

      <AdminSupplierCreateDialogWrapper
        open={props.location.query.create_supplier === "true"}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("create_supplier"));
        }}
      />

      <AdminSupplierEditDialogWrapper
        supplierId={fp.toFinite(props.location.query.view_supplier)}
        onRequestClose={() => props.setLocationQuery(fp.unset("view_supplier"))}
      />

      <OverallStatusTabs location={props.location} />

      <DataList
        isLoading={props.isLoading}
        selectedRowCount={props.selectedIds.size}
        totalCount={props.total}
        list={props.list}
        rowCount={props.list.size}
        overscanRowCount={8}
        rowGetter={(options) => props.list.get(options.index)}
        filter={props.filter}
        onFilterChange={(filter) => props.setLocationQueryFilter(filter)}
        cardActionIcons={
          <div>
            {!props.isAdminViewer && (
              <Tooltip
                title={getLocalisationMessage(
                  "create_new_user",
                  "Create New User",
                )}
              >
                <IconButton
                  onClick={() => props.setLocationQuery(fp.set("create", true))}
                >
                  <Add />
                </IconButton>
              </Tooltip>
            )}

            <Tooltip
              title={props.getLocalisationMessage("user_filter", "User Filter")}
            >
              <Link to={updateHash(props.location, USERS_FILTER_DIALOG_HASH)}>
                <IconButton
                  tooltip={props.getLocalisationMessage(
                    "user_filter",
                    "User Filter",
                  )}
                >
                  <FilterList />
                </IconButton>
              </Link>
            </Tooltip>

            <MenuButtonMore>
              <MenuItem
                target="_blank"
                component="a"
                href={updateQuery(
                  CREATE_USER_XLS_URL,
                  props.filter.getDefinedValues(),
                )}
              >
                {props.getLocalisationMessage("download_excel", "Download XLS")}
              </MenuItem>
              <MenuItem
                component="a"
                onClick={() => {
                  props.replaceLocationHash(USER_UPLOAD_ORDER_DIALOG_HASH);
                }}
              >
                {props.getLocalisationMessage("upload_users", "Upload Users")}
              </MenuItem>
            </MenuButtonMore>
          </div>
        }
      >
        <DataListColumn
          width={94}
          label={getLocalisationMessage("id", "ID")}
          dataKey="id"
          disableSort={true}
          justifyContent="center"
          cellRenderer={(row) => (
            <div>
              {!props.isAdminViewer ? (
                <Link
                  to={updateQuery(
                    props.location,
                    fp.set("view", row.cellData.get("user_id")),
                  )}
                >
                  {row.cellData.get("user_id")}
                </Link>
              ) : (
                row.cellData.get("user_id")
              )}
            </div>
          )}
        />

        <DataListColumn
          width={128}
          label={getLocalisationMessage("name", "Name")}
          dataKey="name"
          disableSort={true}
          cellRenderer={(row) => (
            <MultiLineCell firstLine={row.cellData.get("full_name")} />
          )}
        />
        <DataListColumn
          width={128}
          label={getLocalisationMessage("login", "Login")}
          dataKey="login"
          disableSort={true}
          cellRenderer={(row) => row.cellData.get("login")}
        />
        <DataListColumn
          width={128}
          label={getLocalisationMessage("email", "Email")}
          dataKey="email"
          disableSort={true}
          cellRenderer={(row) => row.cellData.get("email") || NA}
        />
        <DataListColumn
          width={128}
          label={getLocalisationMessage("phone", "Phone")}
          dataKey="phone"
          disableSort={true}
          cellRenderer={(row) => row.cellData.get("phone") || NA}
        />
        <DataListColumn
          width={94}
          justifyContent="center"
          label={getLocalisationMessage("status", "Status")}
          dataKey="status"
          disableSort={true}
          cellRenderer={(row) => {
            const status = row.cellData.get("status");
            return getLocalisationMessage(status) || formatText(status);
          }}
        />
        <DataListColumn
          width={94}
          justifyContent="center"
          label={getLocalisationMessage("join_date", "Join date")}
          dataKey="created_date"
          disableSort={true}
          cellRenderer={(row) => (
            <DateTimeCell date={row.cellData.get("created_date")} />
          )}
        />
      </DataList>
    </AdminAppLayout>
  );
}

export default enhancer(AdminUsersListContainer);
