import { Observable } from "rxjs";
import React from "react";
import _ from "lodash";
import { List, Set } from "immutable";
import { compose, createEventHandler, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DataList, {
  DataListCheckbox,
  DataListColumn,
} from "../data-list/DataList";
import { isEqualData } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import useSheet from "react-jss";

const enhancer = compose(
  connect((state) => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);
    return {
      getLocalisationMessage,
    };
  }),
  useSheet({
    checkbox: {
      "&>span": {
        padding: 0,
      },
    },
  }),
  mapPropsStream((propsStream: Observable) => {
    const { handler: onFilterChange, stream: onFilterChangeStream } =
      createEventHandler();

    const filterStream = onFilterChangeStream
      .distinctUntilChanged(isEqualData)
      .startWith(new DataListFilter());

    const listStream = propsStream
      .map((props) => props.list || List())
      .distinctUntilChanged(isEqualData)
      .combineLatest(filterStream, (list, filter) =>
        filter.getSearch()
          ? list.filter((item) =>
              _.includes(
                _.toLower(item.get("index")),
                _.toLower(filter.getSearch()),
              ),
            )
          : list,
      )
      .startWith(List());

    return propsStream
      .combineLatest(listStream, filterStream, (props, list, filter) => ({
        ...props,
        list,
        filter,
        onFilterChange,
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

NeighborhoodsCopyFormTable.propTypes = {
  classes: PropTypes.object,
  list: PropTypes.instanceOf(List),
  headerActions: PropTypes.node,
  onRowSelection: PropTypes.func.isRequired,
  selectedIds: PropTypes.instanceOf(Set).isRequired,
  onFilterChange: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
  isLoading: PropTypes.bool,
  getLocalisationMessage: PropTypes.func.isRequired,
  formatRow: PropTypes.func,
};

function NeighborhoodsCopyFormTable(props) {
  const { classes, getLocalisationMessage } = props;
  return (
    <DataList
      filter={props.filter}
      onFilterChange={props.onFilterChange}
      selectedRowCount={props.selectedIds ? props.selectedIds.size : 0}
      rowCount={props.list.size}
      isLoading={props.isLoading}
      overscanRowCount={1}
      altHeader={props.headerActions}
      rowGetter={(options) => props.list.get(options.index)}
    >
      <DataListCheckbox
        className={classes.checkbox}
        allRowsSelected={() => props.selectedIds.size === props.list.size}
        rowSelected={(row) => props.selectedIds.has(row.cellData.get("id"))}
        onRowSelect={(row) =>
          props.onRowSelection(
            row.selected
              ? props.selectedIds.add(row.cellData.get("id"))
              : props.selectedIds.delete(row.cellData.get("id")),
          )
        }
        onAllRowsSelect={(selected) =>
          props.onRowSelection(
            selected ? props.list.map((o) => o.get("id")).toSet() : Set(),
          )
        }
      />

      <DataListColumn
        width={128}
        disableSort={true}
        label={getLocalisationMessage("name", "Name")}
        dataKey="name"
        // cellRenderer={row => <strong>{row.cellData.get("name")}</strong>}
        cellRenderer={
          props.formatRow
            ? props.formatRow
            : (row) => <strong>{row.cellData.get("name")}</strong>
        }
      />
    </DataList>
  );
}

export default enhancer(NeighborhoodsCopyFormTable);
