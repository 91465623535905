import { Map } from "immutable";
import {
  ACTIVE as ACTIVE_CODE,
  INACTIVE as INACTIVE_CODE,
} from "./CustomerStatusCodes";

export const ALL = "all";
export const ACTIVE = "active";
export const INACTIVE = "inactive";

export default Map({
  [ALL]: null,
  [ACTIVE]: ACTIVE_CODE,
  [INACTIVE]: INACTIVE_CODE,
});
