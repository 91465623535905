import { OrderedSet } from "immutable";

export const ORDER_RULE = "ORDER_RULE";
export const DRIVER_RULE = "DRIVER_RULE";
export const SUPPLIER_RULE = "SUPPLIER_RULE";
export const PICKUP_JOB_AUTOMATION = "PICKUP_JOB_AUTOMATION";
export const ZONING_RULE = "ZONING_RULE";
export const RULE_ALERT = "RULE_ALERT";
export const CUSTOM_PACKAGE = "CUSTOM_PACKAGE";
export const AUTO_DISPATCH = "AUTO_DISPATCH";
export const ORDER_TAG = "ORDER_TAG";
export const ORDER_DEADLINE = "ORDER_DEADLINE";

export default OrderedSet.of(
  ORDER_RULE,
  PICKUP_JOB_AUTOMATION,
  ZONING_RULE,
  AUTO_DISPATCH,
  ORDER_TAG,
  ORDER_DEADLINE,
);

export const SLARuleTypes = OrderedSet.of(RULE_ALERT);
