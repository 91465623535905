import sprintf from "sprintf";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import { ObservableCache } from "../../helpers/loading-cache";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const RULE_URL = `${API_ROOT_URL}/admin/rules`;
const ALERT_RULE_URL = `${API_ROOT_URL}/admin/alert_rules`;
const RULE_ITEM_URL = `${API_ROOT_URL}/admin/rules/%s`;
const ALERT_RULE_ITEM_URL = `${API_ROOT_URL}/admin/alert_rules/%s`;
const RULE_CHOOSE_URL = `${API_ROOT_URL}/admin/rules/choose`;
const CHILD_RULE_CHOOSE_URL = `${API_ROOT_URL}/admin/rules/children/choose`;
const RULE_CLONE_CUSTOMERS_URL = `${API_ROOT_URL}/admin/rules/%s/customers`;
const RULE_CLONE_URL = `${API_ROOT_URL}/admin/rules/%s/clone`;
const RULE_CONDITIONS_UPLOAD_URL = `${API_ROOT_URL}/admin/rules/conditions/upload`;

const RULES_GROUP_URL = `${API_ROOT_URL}/admin/rule_groups`;
const RULES_GROUP_ITEM_URL = `${API_ROOT_URL}/admin/rule_groups/%s`;
const RULE_GROUP_CLONE_URL = `${API_ROOT_URL}/admin/rule_groups/%s/clone`;
const RULES_GROUP_SUPPLIERS_QUOTA_URL = `${API_ROOT_URL}/admin/rule_groups/suppliers`;
const RULE_GROUP_CLONE_CUSTOMERS_URL = `${API_ROOT_URL}/admin/rule_groups/%s/customers`;

type RuleType =
  | "ORDER_RULE"
  | "SUPPLIER_RULE"
  | "PICKUP_JOB_AUTOMATION"
  | "ZONING_RULE";
type RuleStatus = "ACTIVE" | "INACTIVE";
type ConditionalOperator = "AND" | "OR";

type Condition = {
  id: number,
  field: string,
  priority: 0,
  date_value: string,
  string_value: string,
  boolean_value: boolean,
  whole_number_value: number,
  fraction_number_value: number,
  conditional_operator: ConditionalOperator,
};

type ConditionGroup = {
  id: number,
  conditions: Condition[],
  conditional_operator: ConditionalOperator,
};

type SupplierRule = { id: number };

type OrderRule = {
  id: number,
  priority: number,
  rule_type: RuleType,
  rule_status: RuleStatus,
  condition_group: ConditionGroup,
  suppliers: number[],
  drivers: number[],
  any_supplier: boolean,
  supplier_rule: SupplierRule,
};

const itemCache = new ObservableCache({
  expireAfterWrite: 20000,
  loader: id => api.getStream(sprintf(RULE_ITEM_URL, id)),
});
const predictionsCache = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: request =>
    api.getStream(RULE_CHOOSE_URL, { params: request.getDefinedValues() }),
});
const predictionsCacheChild = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: request =>
    api.getStream(CHILD_RULE_CHOOSE_URL, {
      params: request.getDefinedValues(),
    }),
});

export const createRulesGroup = body => api.post(RULES_GROUP_URL, { body });
export const updateRulesGroup = body => api.put(RULES_GROUP_URL, { body });
export const getRulesGroupList = (request: DataListFilter) =>
  api.getStream(RULES_GROUP_URL, { params: request.getDefinedValues() });
export const getRulesGroupItem = id =>
  api.getStream(sprintf(RULES_GROUP_ITEM_URL, id));
export const deleteRulesGroupItem = id =>
  api.delete(sprintf(RULES_GROUP_ITEM_URL, id));
export const updateRulesGroupSuppliersQuota = body =>
  api.put(RULES_GROUP_SUPPLIERS_QUOTA_URL, { body });

export const getRuleGroupCloneCustomers = (id: number) =>
  api.getStream(sprintf(RULE_GROUP_CLONE_CUSTOMERS_URL, id));
export const cloneCustomerRuleGroups = (id: number, body) =>
  api.post(sprintf(RULE_GROUP_CLONE_URL, id), { body });

export const getRule = id => itemCache.refresh(id);
export const getCachedRule = id =>
  itemCache
    .get(id)
    .takeLast(1)
    .map(fp.flow(fp.get("payload.data"), fromJS));
export const getRulePredictions = (request: DataListFilter) =>
  predictionsCache.get(request);

export const getChildRulePredictions = (request: DataListFilter) =>
  predictionsCacheChild.get(request);

export const createRule = (rule: OrderRule) =>
  api.post(RULE_URL, { body: rule });
export const updateRule = (rule: OrderRule) =>
  api.put(RULE_URL, { body: rule });
export const deleteRule = (id: number) =>
  api.delete(sprintf(RULE_ITEM_URL, id));
export const getRuleList = (filter: DataListFilter) =>
  api.getStream(RULE_URL, { params: filter.getDefinedValues() });

export const getRuleCloneCustomers = (id: number) =>
  api.getStream(sprintf(RULE_CLONE_CUSTOMERS_URL, id));
export const cloneCustomerRules = (id: number, body) =>
  api.post(sprintf(RULE_CLONE_URL, id), { body });

export const uploadRuleConditionsCSV = (params, file: File) =>
  api.postStream(RULE_CONDITIONS_UPLOAD_URL, {
    file,
    params,
  });

// Alert Rules

export const getAlertRuleList = (filter: DataListFilter) =>
  api.getStream(ALERT_RULE_URL, { params: filter.getDefinedValues() });

export const getAlertRule = (id: number) =>
  api.getStream(sprintf(ALERT_RULE_ITEM_URL, id));
