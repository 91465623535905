import React from "react";
import fp from "lodash/fp";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Marker, MarkerIcon } from "react-google-map-components";
import { pureComponent } from "../../helpers/HOCUtils";
import Icon from "./assets/icon.svg";

const enhancer = compose(pureComponent(fp.pick(["position", "label"])));

AutoSizeMarker.propTypes = {
  onClick: PropTypes.func,
  position: PropTypes.object.isRequired,
  label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

function AutoSizeMarker(props) {
  const label = String(props.label || "");
  const iconSize =
    label.length < 3 ? 24 : label.length === 3 ? 30 : label.length * 9;

  const size = { height: iconSize, width: iconSize };
  const anchor = { x: iconSize / 2, y: iconSize / 2 };

  return (
    <Marker
      {...props}
      label={label}
      icon={
        <MarkerIcon url={Icon} size={size} anchor={anchor} scaledSize={size} />
      }
    />
  );
}

export default enhancer(AutoSizeMarker);
