import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { createSupplier } from "../../api/admin/AdminSupplierApi";
import {
  getCachedReference,
  getReferencePredictions,
} from "../../api/shared/ReferenceApi";
import {
  getCachedCustomer,
  getCustomerPredictions,
} from "../../api/supplier/SupplierCustomerApi";
import FlexBox from "../../components/ui-core/FlexBox";
import ModalPaper from "../../components/ui-core/ModalPaper";
import SupplierForm from "../../components/suppliers-core/SupplierForm";

const enhancer = compose(
  renderIf("open"),
  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);
      return {
        getLocalisationMessage,
      };
    },
    { showErrorMessage, showSuccessMessage },
  ),
);

AdminSupplierCreateDialogWrapper.propTypes = {
  open: PropTypes.bool.isRequired,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminSupplierCreateDialogWrapper(props) {
  const { getLocalisationMessage } = props;
  return (
    <ModalPaper
      title={getLocalisationMessage("create_supplier", "Create Supplier")}
      open={props.open}
      onRequestClose={props.onRequestClose}
    >
      <FlexBox container={8} direction="column">
        <SupplierForm
          onDismiss={props.onRequestClose}
          onSubmit={values =>
            createSupplier(toSnakeCase(values)).catch(ResponseError.throw)
          }
          onSubmitSuccess={() => {
            props.onRequestClose();
            props.showSuccessMessage(
              getLocalisationMessage(
                "successfully_saved",
                "Successfully saved",
              ),
            );
          }}
          onSubmitFail={props.showErrorMessage}
          getCachedReference={getCachedReference}
          getReferencePredictions={getReferencePredictions}
          getCachedCustomer={getCachedCustomer}
          getCustomerPredictions={getCustomerPredictions}
        />
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(AdminSupplierCreateDialogWrapper);
