import { OrderedSet } from "immutable";

export const EQUAL_TO = "EQUAL_TO";
export const NOT_EQUAL_TO = "NOT_EQUAL_TO";
export const GREATER_THAN = "GREATER_THAN";
export const GREATER_THAN_OR_EQUAL_TO = "GREATER_THAN_OR_EQUAL_TO";
export const LESS_THAN = "LESS_THAN";
export const LESS_THAN_OR_EQUAL_TO = "LESS_THAN_OR_EQUAL_TO";
export const CONTAINS = "CONTAINS";
export const NOT_CONTAINS = "NOT_CONTAINS";

export default OrderedSet.of(
  EQUAL_TO,
  NOT_EQUAL_TO,
  GREATER_THAN,
  GREATER_THAN_OR_EQUAL_TO,
  LESS_THAN,
  LESS_THAN_OR_EQUAL_TO,
  CONTAINS,
  NOT_CONTAINS,
);
