import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Card, CardContent, Button, CardActions } from "@material-ui/core";
import { connect } from "react-redux";
import FormMarketplaceChipAutoComplete from "../form/FormMarketplaceChipAutoComplete";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { getMessages } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),
  useSheet({
    root: {
      height: "300px",
    },
    card: {
      "& > div": {
        flex: 1,
        display: "flex",
        flexDirection: "column",
      },
    },
  }),
  reduxForm({
    form: "SupplierMarketPlaceForm",
    enableReinitialize: true,
  }),
);

SupplierMarketplaceForm.propTypes = {
  classes: PropTypes.object,

  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  i18n: PropTypes.instanceOf(Map),
};

function SupplierMarketplaceForm(props) {
  const { classes, i18n } = props;

  return (
    <FlexBox className={classes.root} container={8} direction="column">
      <FlexBox gutter={8} direction="column" flex={true}>
        <FlexBox
          flex={true}
          element={<form onSubmit={props.handleSubmit} />}
          direction="column"
        >
          <FlexBox
            element={<Card className={classes.card} />}
            gutter={8}
            flex={true}
            direction="column"
          >
            <PageLoading isLoading={props.submitting} />

            <FlexBox
              element={<CardContent />}
              direction="column"
              flex={true}
              align="flex-start"
            >
              <FormMarketplaceChipAutoComplete
                fullWidth={true}
                name="marketplaces"
                label={i18n.get("marketplaces", "Marketplaces")}
              />
            </FlexBox>

            <FlexBox element={<CardActions />} justify="flex-end">
              <Button type="submit">{i18n.get("submit", "Submit")}</Button>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(SupplierMarketplaceForm);
