import React from "react";
import PropTypes from "prop-types";

InfoIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
};
InfoIcon.defaultProps = {
  color: "#10132d",
  size: 16,
  className: "",
};

function InfoIcon(props) {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width={props.size}
      height={props.size}
      viewBox="0 0 44 44"
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="General">
          <g id="Info_2x.png" data-name="Info@2x.png">
            <path d="M24.94,31a2.14,2.14,0,0,1-1.29-.29,1.34,1.34,0,0,1-.37-1.1,5.71,5.71,0,0,1,.12-1,11,11,0,0,1,.26-1.12l1.18-4.06a6.15,6.15,0,0,0,.24-1.23c0-.45.06-.76.06-.94a2.63,2.63,0,0,0-.93-2.09,3.91,3.91,0,0,0-2.64-.8,6.85,6.85,0,0,0-2,.33q-1.06.33-2.23.79L17,20.81c.23-.08.51-.17.83-.27a3.4,3.4,0,0,1,.94-.14,1.92,1.92,0,0,1,1.27.31,1.45,1.45,0,0,1,.33,1.09,4.74,4.74,0,0,1-.11,1c-.07.35-.16.72-.27,1.11l-1.19,4.08a11.65,11.65,0,0,0-.23,1.15,7.14,7.14,0,0,0-.07,1,2.61,2.61,0,0,0,1,2.07,4,4,0,0,0,2.68.82,6.17,6.17,0,0,0,2-.29q.85-.29,2.27-.83l.32-1.26a5.53,5.53,0,0,1-.79.26A3.81,3.81,0,0,1,24.94,31Zm-.75-20a2.87,2.87,0,0,0-2,.75,2.39,2.39,0,0,0,0,3.62,3,3,0,0,0,4,0,2.4,2.4,0,0,0,0-3.62A2.85,2.85,0,0,0,24.19,11ZM22,0A22,22,0,1,0,44,22,22,22,0,0,0,22,0Zm0,42A20,20,0,1,1,42,22,20,20,0,0,1,22,42Z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default InfoIcon;
