import { Observable } from "rxjs";
import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues, formValueSelector } from "redux-form";
import {
  Card,
  CardContent as CardText,
  CardMedia,
  CardHeader,
  Button,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormTextField from "../form/FormTextField";
import FormFileUploadField from "../form/FormFileUploadFieldV2";
import FlexBox from "../ui-core/FlexBox";
import { isEqualData, mapResponseData } from "../../helpers/DataUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { getFileUrl } from "../../api/shared/FileApi";
import imgNotFound from "./assets/notfound.png";

const valueSelector = formValueSelector(
  "OrderDetailsDialogProofOfPaymentSender",
);
const enhancer = compose(
  connect(state => ({
    values: valueSelector(state, "photoId"),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  reduxForm({
    form: "OrderDetailsDialogProofOfPaymentSender",
    enableReinitialize: true,
  }),
  useSheet({
    cardMedia: { margin: "0 6px", textAlign: "center" },
    card: {
      fontFamily: "Blogger Sans",
      height: "100%",
      maxHeight: "400px",
      minHeight: "300px",
    },
    image: {
      maxHeight: "144px",
      objectFit: "contain",
    },
    saveButton: {
      marginLeft: "20px",
      marginRight: "20px",
      marginBottom: "10px",
    },
  }),
  formValues({
    photoId: "photoId",
    receiptNumber: "receiptNumber",
    amount: "amount",
    paymentType: "paymentType",
  }),
  mapPropsStream(prop$ => {
    const getPhotoUrlStream = prop$
      .pluck("photoId")
      .filter(id => fp.isFinite(id) && id > 0)
      .distinctUntilChanged(isEqualData)
      .switchMap(id => getFileUrl(id).catch(error => Observable.of({ error })))
      .startWith({})
      .map(mapResponseData)
      .distinctUntilChanged(isEqualData);

    return prop$.combineLatest(getPhotoUrlStream, (props, photoUrl) => ({
      ...props,
      photoUrl: photoUrl.get("payload"),
    }));
  }),
  withState("edit", "onEdit", false),
);

OrderDetailsDialogProofOfPaymentSender.propTypes = {
  classes: PropTypes.object,
  header: PropTypes.string,
  photoUrl: PropTypes.string,
  values: PropTypes.object,
  onUpload: PropTypes.func,
  setState: PropTypes.any,
  getLocalisationMessage: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  onSubmitting: PropTypes.func,
  edit: PropTypes.bool,
  onEdit: PropTypes.func,
  receiptNumber: PropTypes.string,
  handleSubmit: PropTypes.func,
};

function OrderDetailsDialogProofOfPaymentSender(props) {
  const {
    classes,
    photoUrl,
    getLocalisationMessage,
    edit,
    onEdit,
    receiptNumber,
  } = props;
  return (
    <Card className={classes.card}>
      <CardHeader title={props.header} />

      <CardText>
        <FlexBox>
          <FlexBox direction="column" flex={true}>
            <FormTextField
              name="receiptNumber"
              fullWidth={true}
              disabled={!edit}
              label={getLocalisationMessage("receipt_number", "Receipt Number")}
            />
            <FormTextField
              name={receiptNumber && "amount"}
              readOnly={true}
              fullWidth={true}
              label={getLocalisationMessage("amount", "Amount")}
            />
          </FlexBox>
          <FlexBox flex={true} direction="column" justify="space-between">
            <CardMedia className={classes.cardMedia}>
              <img
                alt="Photos"
                src={photoUrl || imgNotFound}
                className={classes.image}
              />
              {edit && (
                <FormFileUploadField
                  fullWidth={true}
                  name="photoId"
                  label={getLocalisationMessage("upload", "UPLOAD")}
                  accept="image/*"
                />
              )}
            </CardMedia>
          </FlexBox>
        </FlexBox>
      </CardText>
      <FlexBox justify="flex-end">
        {receiptNumber && (
          <Button className={classes.saveButton} onClick={() => onEdit(!edit)}>
            {" "}
            {edit
              ? getLocalisationMessage("cancel", "Cancel")
              : getLocalisationMessage("edit", "edit")}{" "}
          </Button>
        )}
        {edit && (
          <Button className={classes.saveButton} onClick={props.handleSubmit}>
            {" "}
            {getLocalisationMessage("save", "Save")}{" "}
          </Button>
        )}
      </FlexBox>
    </Card>
  );
}

export default enhancer(OrderDetailsDialogProofOfPaymentSender);
