import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withContext, withState } from "recompose";
import PropTypes from "prop-types";
import { FieldArray, formValues, reduxForm } from "redux-form";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  ListItemText,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FormDriverAutoComplete from "../form/FormDriverAutoComplete";
import FormSupplierAutoComplete from "../form/FormSupplierAutoComplete";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { pureComponent } from "../../helpers/HOCUtils";
import {
  createNotNilValidator,
  createObjectIdValidator,
} from "../../helpers/FormUtils";
import { formatText, parseInteger } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import deliveryAllocationTypes, {
  ASSIGN_TO_SUPPLIER,
} from "../../constants/DeliveryAllocationTypes";
import { KeyboardArrowDown } from "@material-ui/icons";

const OrderRuleSuppliers = props => (
  <FlexBox flex={true} direction="column">
    <FlexBox flex={true} direction="column">
      {props.fields.map((suppliers, index) => (
        <FlexBox gutter={8} flex={true} key={index}>
          <FlexBox flex={true} direction="column">
            <FormSupplierAutoComplete
              fullWidth={true}
              label={props.getLocalisationMessage("supplier", "Supplier")}
              name={`${suppliers}.supplier`}
              validate={createObjectIdValidator(
                props.getLocalisationMessage(
                  "select_supplier",
                  "Select Supplier",
                ),
              )}
            />
          </FlexBox>

          <FlexBox flex={true} direction="column">
            <FormTextField
              fullWidth={true}
              parseOnBlur={parseInteger}
              label={props.getLocalisationMessage("daily_quota", "Daily Quota")}
              name={`${suppliers}.dailyQuota`}
              validate={createNotNilValidator(
                props.getLocalisationMessage(
                  "enter_daily_quota",
                  "Enter Daily Quota",
                ),
              )}
            />
          </FlexBox>

          <FlexBox flex={true} direction="column">
            <FormTextField
              fullWidth={true}
              parseOnBlur={parseInteger}
              label={props.getLocalisationMessage("daily_limit", "Daily Limit")}
              name={`${suppliers}.dailyLimit`}
              validate={createNotNilValidator(
                props.getLocalisationMessage(
                  "enter_daily_limit",
                  "Enter Daily Limit",
                ),
              )}
            />
          </FlexBox>

          <FlexBox justify="center" direction="column">
            <Button onClick={() => props.fields.remove(index)}>
              {props.getLocalisationMessage("remove", "Remove")}
            </Button>
          </FlexBox>
        </FlexBox>
      ))}
    </FlexBox>

    <FlexBox>
      <Button onClick={() => props.fields.push({})}>
        {props.getLocalisationMessage("add_supplier", "Add Supplier")}
      </Button>
    </FlexBox>
  </FlexBox>
);

OrderRuleSuppliers.propTypes = {
  fields: PropTypes.object,
  getLocalisationMessage: PropTypes.func.isRequired,
};

const OrderRuleDrivers = props => (
  <FlexBox flex={true} direction="column">
    <FlexBox flex={true} direction="column">
      {props.fields.map((drivers, index) => (
        <FlexBox gutter={8} flex={true} key={index}>
          <FlexBox flex={true} direction="column">
            <FormDriverAutoComplete
              fullWidth={true}
              label={props.getLocalisationMessage("driver", "Driver")}
              name={`${drivers}.driver`}
              validate={createObjectIdValidator(
                props.getLocalisationMessage("select_driver", "Select Driver"),
              )}
            />
          </FlexBox>

          <FlexBox flex={true} direction="column">
            <FormTextField
              fullWidth={true}
              parseOnBlur={parseInteger}
              label={props.getLocalisationMessage("daily_quota", "Daily Quota")}
              name={`${drivers}.dailyQuota`}
              validate={createNotNilValidator(
                props.getLocalisationMessage(
                  "enter_daily_quota",
                  "Enter Daily Quota",
                ),
              )}
            />
          </FlexBox>

          <FlexBox flex={true} direction="column">
            <FormTextField
              fullWidth={true}
              parseOnBlur={parseInteger}
              label={props.getLocalisationMessage("daily_limit", "Daily Limit")}
              name={`${drivers}.dailyLimit`}
              validate={createNotNilValidator(
                props.getLocalisationMessage(
                  "enter_daily_limit",
                  "Enter Daily Limit",
                ),
              )}
            />
          </FlexBox>

          <FlexBox justify="center" direction="column">
            <Button onClick={() => props.fields.remove(index)}>
              {props.getLocalisationMessage("remove", "Remove")}
            </Button>
          </FlexBox>
        </FlexBox>
      ))}
    </FlexBox>

    <FlexBox style={{ paddingTop: "1rem" }}>
      <Button onClick={() => props.fields.push({})}>
        {props.getLocalisationMessage("add_driver", "Add Driver")}
      </Button>
    </FlexBox>
  </FlexBox>
);

OrderRuleDrivers.propTypes = {
  fields: PropTypes.object,
  getLocalisationMessage: PropTypes.func.isRequired,
};

const OrderRules = props => (
  <FlexBox gutter={8} element={<Card />} flex={true} direction="column">
    <FlexBox flex={true}>
      <FlexBox gutter={8} flex={true} direction="column">
        {props.fields.map((rule, ruleIndex, allFields) => {
          const isSupplierOrDriver = fp.isUndefined(props.ruleValues[ruleIndex])
            ? allFields.get(ruleIndex).isSupplier
            : props.ruleValues[ruleIndex].isSupplier;
          return (
            <FlexBox key={ruleIndex} direction="column" flex="none">
              <Accordion
                style={{
                  boxShadow: "none",
                }}
                expandIcon={<KeyboardArrowDown />}
              >
                <AccordionSummary
                  expandIcon={<KeyboardArrowDown />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <ListItemText
                    primary={allFields.get(ruleIndex).name}
                    secondary={
                      fp.size(allFields.get(ruleIndex).suppliers) > 0
                        ? `${fp.size(
                            allFields.get(ruleIndex).suppliers,
                          )} ${props.getLocalisationMessage(
                            "suppliers",
                            "Suppliers",
                          )}`
                        : props.getLocalisationMessage(
                            "all_suppliers",
                            "All Suppliers",
                          )
                    }
                  />
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    minWidth: 992,
                  }}
                >
                  <FlexBox flex={true} gutter={16} direction="column">
                    <FlexBox flex={true}>
                      <FormSelectField
                        name={`${rule}.isSupplier`}
                        autoWidth={true}
                        fullWidth={true}
                        label={props.getLocalisationMessage(
                          "assign_to",
                          "Assign to ...",
                        )}
                        options={deliveryAllocationTypes}
                        formatOption={value =>
                          formatText(props.getLocalisationMessage(value, value))
                        }
                      />
                    </FlexBox>
                    <FlexBox flex={true}>
                      {isSupplierOrDriver === ASSIGN_TO_SUPPLIER ? (
                        <FieldArray
                          name={`${rule}.suppliers`}
                          component={OrderRuleSuppliers}
                          props={{
                            getLocalisationMessage:
                              props.getLocalisationMessage,
                          }}
                        />
                      ) : (
                        <FieldArray
                          name={`${rule}.drivers`}
                          component={OrderRuleDrivers}
                          props={{
                            getLocalisationMessage:
                              props.getLocalisationMessage,
                          }}
                        />
                      )}
                    </FlexBox>
                  </FlexBox>
                </AccordionDetails>
              </Accordion>
            </FlexBox>
          );
        })}
      </FlexBox>
    </FlexBox>

    <FlexBox justify="flex-end">
      {props.dirty && (
        <Button onClick={props.reset}>
          {props.getLocalisationMessage("reset", "Reset")}
        </Button>
      )}

      <Button primary={true} type="submit">
        {props.getLocalisationMessage("submit", "Submit")}
      </Button>
    </FlexBox>
  </FlexBox>
);

OrderRules.propTypes = {
  fields: PropTypes.object,
  ruleValues: PropTypes.array,
  classes: PropTypes.object,
  state: PropTypes.object,
  setState: PropTypes.func,
  reset: PropTypes.func,
  dirty: PropTypes.bool,
  getLocalisationMessage: PropTypes.func.isRequired,
};

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  useSheet({
    border: {
      borderBottom: "1px solid #ccc",
    },
    item: {
      boxShadow: "none",
    },
  }),
  withContext(
    {
      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,
      getCachedDriver: PropTypes.func,
      getDriverPredictions: PropTypes.func,
    },
    props => ({
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
      getCachedDriver: props.getCachedDriver,
      getDriverPredictions: props.getDriverPredictions,
    }),
  ),
  withState("state", "setState", {}),
  reduxForm({
    form: "RulesGroupItemForm",
    enableReinitialize: true,
  }),
  formValues({
    rules: "rules",
  }),
  pureComponent(fp.pick(["submitting", "dirty", "state", "rules"])),
);

RulesGroupItemForm.propTypes = {
  classes: PropTypes.object,

  getSupplierPredictions: PropTypes.func.isRequired,
  getCachedSupplier: PropTypes.func.isRequired,

  getDriverPredictions: PropTypes.func.isRequired,
  getCachedDriver: PropTypes.func.isRequired,

  state: PropTypes.object,
  rules: PropTypes.array,
  setState: PropTypes.func,

  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  reset: PropTypes.func,
  dirty: PropTypes.bool,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function RulesGroupItemForm(props) {
  const { classes } = props;

  return (
    <FlexBox
      container={8}
      element={<form onSubmit={props.handleSubmit} />}
      flex={true}
      direction="column"
    >
      <PageLoading isLoading={props.submitting} />
      <FieldArray
        name="rules"
        component={OrderRules}
        props={{
          classes,
          dirty: props.dirty,
          reset: props.reset,
          state: props.state,
          setState: props.setState,
          ruleValues: props.rules,
          getLocalisationMessage: props.getLocalisationMessage,
        }}
      />
    </FlexBox>
  );
}

export default enhancer(RulesGroupItemForm);
