import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, withState, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Paper, TextField, CardActions, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { CustomControl, DrawingControl } from "react-google-map-components";
import ZoneListMap from "../maps/ZoneListMap";
import PageLoading from "../ui-core/PageLoading";
import { mapArrayResponseStream } from "../../helpers/ApiUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import { parseMultipolygon } from "../../helpers/MapPolygonUtils";
import { getMapsStream } from "../../helpers/GoogleMapsHelper";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  withState("state", "setState", Map({ searchText: "" })),
  mapPropsStream(
    pipeStreams(
      propsStream =>
        propsStream.combineLatest(getMapsStream(), (props, maps) => ({
          ...props,
          maps,
        })),
      propsStream => {
        const zonesStream = propsStream
          .take(1)
          .switchMap(props =>
            props
              .getPolygons()
              .let(mapArrayResponseStream)
              .distinctUntilChanged(isEqualData)
              .map(response => {
                const {
                  maps: { LatLng, LatLngBounds },
                } = props;

                return response.update("payload", payload =>
                  payload.map(item => {
                    const polygon = parseMultipolygon(item.get("wkt"));

                    const center =
                      polygon.length === 0
                        ? null
                        : polygon[0]
                            .reduce(
                              (acc, x) => acc.extend(new LatLng(x)),
                              new LatLngBounds(),
                            )
                            .getCenter();

                    const activePolygon = props.selectedPolygonIDs
                      ? props.selectedPolygonIDs.includes(item.get("id"))
                      : false;

                    return item.delete("wkt").merge(
                      fromJS({
                        polygon,
                        active: activePolygon,
                        center: !center
                          ? null
                          : {
                              lat: center.lat(),
                              lng: center.lng(),
                            },
                      }),
                    );
                  }),
                );
              }),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(zonesStream, (props, zones) => ({
          ...props,
          zones: zones.get("payload"),
          isLoading: zones.get("pending"),
        }));
      },
      propsStream => {
        const searchTextStream = propsStream.map(props =>
          props.state.get("searchText"),
        );

        const toLower = fp.flow(fp.trim, fp.toLower);

        const zonesStream = propsStream
          .distinctUntilKeyChanged("zones", isEqualData)
          .combineLatest(
            searchTextStream
              .take(0)
              .merge(searchTextStream.skip(1).debounceTime(500))
              .map(fp.flow(fp.trim, fp.toLower))
              .distinctUntilChanged(),
            (props, searchText) =>
              fp.isEmpty(searchText)
                ? props.zones
                : props.zones.filter(
                    x => toLower(x.get("name")).indexOf(searchText) !== -1,
                  ),
          );

        return propsStream.combineLatest(zonesStream, (props, zones) => ({
          ...props,
          zones,
          unfilteredZones: props.zones,
        }));
      },
    ),
  ),
);

ZoneSelectToolMap.propTypes = {
  setState: PropTypes.func,
  state: PropTypes.instanceOf(Map),

  maps: PropTypes.object,

  isLoading: PropTypes.bool,
  zones: PropTypes.instanceOf(List),

  style: PropTypes.object,
  className: PropTypes.string,

  onChange: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.number),

  onReset: PropTypes.func,
  onSubmit: PropTypes.func,

  getPolygons: PropTypes.func.isRequired,
  selectedPolygonIDs: PropTypes.array,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function ZoneSelectToolMap(props) {
  const { state, getLocalisationMessage } = props;

  return (
    <ZoneListMap
      zones={props.zones}
      style={props.style}
      selectedIds={props.value}
      className={props.className}
      onZoneClick={zone => {
        const id = zone.get("id");

        props.onChange(
          fp.includes(id, props.value)
            ? fp.pull(id, props.value)
            : fp.union([id], props.value),
        );
      }}
    >
      <PageLoading isLoading={props.isLoading} />

      <CustomControl position="TOP_RIGHT">
        <CardActions>
          <Button primary={true} onClick={() => props.onChange([])}>
            {getLocalisationMessage("clear_selection", "Clear Selection")}
          </Button>

          {Boolean(props.onReset) && (
            <Button primary={true} onClick={props.onReset}>
              {getLocalisationMessage("reset", "Reset")}
            </Button>
          )}

          {Boolean(props.onSubmit) && (
            <Button primary={true} onClick={props.onSubmit}>
              {getLocalisationMessage("submit", "Submit")}
            </Button>
          )}
        </CardActions>
      </CustomControl>

      <CustomControl position="RIGHT_BOTTOM">
        <Paper>
          <CardActions>
            <TextField
              hintText={getLocalisationMessage(
                "type_to_filter",
                "Type to filter...",
              )}
              value={state.get("searchText")}
              onChange={(event, value) =>
                props.setState(x => x.set("searchText", value))
              }
            />
          </CardActions>
        </Paper>
      </CustomControl>

      <DrawingControl
        position="TOP_CENTER"
        drawingModes={["polygon", "rectangle"]}
        onPolygonComplete={overlay => {
          const {
            maps: {
              LatLng,
              geometry: {
                poly: { containsLocation },
              },
            },
          } = props;

          const selected = [];

          props.zones.forEach(zone => {
            const id = zone.get("id");
            const polygon = zone.getIn(["polygon", 0]);

            if (
              polygon &&
              polygon.find(x => containsLocation(new LatLng(x.toJS()), overlay))
            ) {
              selected.push(id);
            }
          });

          if (selected.length > 0) {
            props.onChange(fp.union(selected, props.value));
          }
        }}
        onRectangleComplete={overlay => {
          const bounds = overlay.getBounds();
          const selected = [];

          props.zones.forEach(zone => {
            const id = zone.get("id");
            const polygon = zone.getIn(["polygon", 0]);

            if (polygon && polygon.find(x => bounds.contains(x.toJS()))) {
              selected.push(id);
            }
          });

          if (selected.length > 0) {
            props.onChange(fp.union(selected, props.value));
          }
        }}
      />
    </ZoneListMap>
  );
}

export default enhancer(ZoneSelectToolMap);
