import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  getSupplierBaseCurrency,
  getSupplierCurrencyList,
  saveSupplierCurrencyList,
} from "../../api/admin/AdminSupplierApi";
import {
  getCachedCurrency,
  getCurrencyPredictions,
} from "../../api/shared/CurrencyApi";
import PageLoading from "../../components/ui-core/PageLoading";
import SupplierCurrencySettingsForm from "../../components/suppliers-core/SupplierCurrencySettingsForm";

const enhancer = compose(
  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);
      return {
        getLocalisationMessage,
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const currencyListResponseStream = propsStream
      .distinctUntilKeyChanged("supplierId")
      .switchMap(props =>
        getSupplierCurrencyList(props.supplierId)
          .repeatWhen(() => onRequestRefreshStream)
          .catch(error => Observable.of({ error })),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    const baseCurrencyResponseStream = propsStream
      .distinctUntilKeyChanged("supplierId")
      .switchMap(props =>
        getSupplierBaseCurrency(props.supplierId)
          .repeatWhen(() => onRequestRefreshStream)
          .catch(error => Observable.of({ error })),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        currencyListResponseStream,
        baseCurrencyResponseStream,
        (props, currencyListResponse, baseCurrencyResponse) => ({
          ...props,
          currencyList: currencyListResponse.get("payload"),
          isLoading: currencyListResponse.get("pending"),
          onRequestRefresh,
          baseCurrencyItem: baseCurrencyResponse.get("payload"),
          baseCurrencyLoading: baseCurrencyResponse.get("pending"),
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

AdminSupplierItemCurrencyContainer.propTypes = {
  isLoading: PropTypes.bool,
  supplierId: PropTypes.number.isRequired,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  currencyList: PropTypes.instanceOf(Map),
  onRequestRefresh: PropTypes.func,
  baseCurrencyItem: PropTypes.instanceOf(Map),
  baseCurrencyLoading: PropTypes.bool,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminSupplierItemCurrencyContainer(props) {
  const { getLocalisationMessage } = props;
  return (
    <div>
      <PageLoading isLoading={props.isLoading || props.baseCurrencyLoading} />
      <SupplierCurrencySettingsForm
        initialValues={toCamelCase(props.currencyList)}
        supplierCurrency={props.baseCurrencyItem}
        onSubmit={values =>
          saveSupplierCurrencyList(props.supplierId, toSnakeCase(values)).catch(
            ResponseError.throw,
          )
        }
        onSubmitSuccess={() => {
          props.showSuccessMessage(
            getLocalisationMessage("successfully_saved", "Successfully saved"),
          );
          props.onRequestRefresh();
        }}
        onSubmitFail={props.showErrorMessage}
        getCachedCurrency={getCachedCurrency}
        getCurrencyPredictions={getCurrencyPredictions}
      />
    </div>
  );
}

export default enhancer(AdminSupplierItemCurrencyContainer);
