import React from "react";
import { OrderedSet } from "immutable";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { formValueSelector } from "redux-form";
import { connect } from "react-redux";
import FormDateField from "../form/FormDateField";
import FormTextField from "../form/FormTextField";
import FormTimeField from "../form/FormTimeField";
import FormSelectField from "../form/FormSelectField";
import FormCompanyAutoComplete from "../form/FormCompanyAutoComplete";
import FormCustomerAutoComplete from "../form/FormCustomerAutoComplete";
import FormTimeSlotAutoComplete from "../form/FormTimeSlotAutoComplete";
import FormWarehouseAutoComplete from "../form/FormWarehouseAutoComplete";
import FormCourierTypeSelectField from "../form/FormCourierTypeSelectField";
import FlexBox, { ALIGN_END } from "../ui-core/FlexBox";
import {
  createDateValidator,
  createNotFalsyValidator,
  createNotNilValidator,
  createNotNullValidator,
  createObjectIdValidator,
} from "../../helpers/FormUtils";
import {
  formatText,
  parseFloat,
  parseInteger,
} from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  AUTO_DISPATCH,
  ORDER_DEADLINE,
  ORDER_RULE,
  ORDER_TAG,
  PICKUP_JOB_AUTOMATION,
  ZONING_RULE,
} from "../../constants/RuleType";
import DayOfWeek from "../../constants/DayOfWeek";
import AddressType from "../../constants/AddressType";
import OrderSizeCodes from "../../constants/OrderSizeCodes";
import OrderPayerTypes from "../../constants/OrderPayerTypes";
import TriggerConstants from "../../constants/TriggerConstants";
import OrderPaymentTypes from "../../constants/OrderPaymentTypes";
import {
  COD,
  COMPANY,
  COST,
  COURIER_TYPE,
  CREATED_DATE,
  CREATED_TIME,
  CURRENT_WAREHOUSE,
  CUSTOMER,
  DAY_OF_WEEK,
  DESTINATION_WAREHOUSE,
  HAS_FETCH_ITEMS,
  IS_RETURN,
  ITEM_VALUE,
  JURISDICTION_FROM,
  JURISDICTION_TO,
  LIMIT,
  MERCHANT,
  ORDER_TYPE,
  PARCEL_SIZE,
  PAYMENT_DONE_BY,
  PAYMENT_METHOD,
  PICKUP_WAREHOUSE,
  POSTCODE_FROM,
  POSTCODE_TO,
  PRICE,
  RATING,
  RECIPIENT_ADDRESS,
  RECIPIENT_ADDRESS_TYPE,
  RTO_PRICE,
  SELLER_ID,
  SELLER_NAME,
  SENDER_ADDRESS,
  SENDER_ADDRESS_TYPE,
  SENDER_NAME,
  SOURCE,
  TIME_SLOT_DROP_OFF,
  TIME_SLOT_PICK_UP,
  TRIGGER,
} from "../../constants/RuleConditionField";
import RuleComparationOperator from "../../constants/RuleComparationOperator";
import RuleConditionOrderTypes from "../../constants/RuleConditionOrderTypes";
import FormPostcodeIndexAutoComplete from "../form/FormPostcodeIndexAutoComplete";
import FormJMAutoComplete from "../form/FormJMAutoComplete";

const valueSelector = formValueSelector("OrderRuleTree");

const orderRuleFields = OrderedSet.of(
  COD,
  COMPANY,
  COURIER_TYPE,
  CREATED_DATE,
  CREATED_TIME,
  CURRENT_WAREHOUSE,
  CUSTOMER,
  DAY_OF_WEEK,
  DESTINATION_WAREHOUSE,
  PAYMENT_DONE_BY,
  PAYMENT_METHOD,
  PARCEL_SIZE,
  PICKUP_WAREHOUSE,
  PRICE,
  TIME_SLOT_DROP_OFF,
  TIME_SLOT_PICK_UP,
  MERCHANT,
  HAS_FETCH_ITEMS,
  ORDER_TYPE,
  SENDER_ADDRESS_TYPE,
  RECIPIENT_ADDRESS_TYPE,
  TRIGGER,
  SELLER_ID,
  SELLER_NAME,
  SENDER_NAME,
  SENDER_ADDRESS,
  ITEM_VALUE,
  RECIPIENT_ADDRESS,
  RTO_PRICE,
  IS_RETURN,
  JURISDICTION_FROM,
  JURISDICTION_TO,
  POSTCODE_FROM,
  POSTCODE_TO,
  SOURCE,
);

const supplierRuleFields = OrderedSet.of(COST, RATING);

const deadlineRuleFields = OrderedSet.of(
  COURIER_TYPE,
  JURISDICTION_TO,
  JURISDICTION_FROM,
  PICKUP_WAREHOUSE,
  DESTINATION_WAREHOUSE,
);

const sourceFields = OrderedSet.of("WEB", "MOBILE");

const trueFalseOptions = OrderedSet.of(true, false);
const formatTrueFalseOption = (x, getLocalisationMessage) =>
  x === true
    ? getLocalisationMessage("yes", "Yes")
    : getLocalisationMessage("no", "No");

const enhancer = compose(
  connect((state, props) => ({
    ruleType: valueSelector(state, "ruleType"),
    field: valueSelector(state, `${props.name}.field`),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

OrderRuleTreeLeaf.propTypes = {
  field: PropTypes.string,
  ruleType: PropTypes.string,
  name: PropTypes.string.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderRuleTreeLeaf(props) {
  const fieldName = `${props.name}.${props.field}-value`;
  const extraFieldName = `${props.name}.${props.field}-extra-value`;
  const { getLocalisationMessage } = props;

  return (
    <FlexBox flex={true} gutter={8} align={ALIGN_END}>
      <FlexBox
        style={{
          flex: `${props.field ? "1 1 0%" : "0 1 auto"} 1 auto`,
          width: "32%",
        }}
        align="center"
      >
        <FormSelectField
          maxHeight={320}
          label={getLocalisationMessage("field")}
          formatOption={x => getLocalisationMessage(x) || formatText(x)}
          name={`${props.name}.field`}
          fullWidth={true}
          validate={createNotFalsyValidator(
            getLocalisationMessage("select_field", "Select Field"),
          )}
          options={
            props.ruleType === ORDER_RULE ||
            props.ruleType === PICKUP_JOB_AUTOMATION ||
            props.ruleType === AUTO_DISPATCH ||
            props.ruleType === ORDER_TAG ||
            props.ruleType === ZONING_RULE
              ? orderRuleFields
              : props.ruleType === ORDER_DEADLINE
              ? deadlineRuleFields
              : supplierRuleFields
          }
        />
      </FlexBox>

      {Boolean(props.field) && (
        <FlexBox flex={true} align="center">
          <FormSelectField
            fullWidth={true}
            maxHeight={320}
            label={getLocalisationMessage("compare_by", "Compare By")}
            formatOption={x => getLocalisationMessage(x) || formatText(x)}
            options={RuleComparationOperator}
            name={`${props.name}.comparationOperator`}
            validate={createNotFalsyValidator(
              getLocalisationMessage("select_comparator", "Select Comparator"),
            )}
          />
        </FlexBox>
      )}

      {props.field === COMPANY && (
        <FlexBox flex={true} align="center">
          <FormCompanyAutoComplete
            hintText={getLocalisationMessage("company", "Company")}
            name={fieldName}
            fullWidth={true}
            validate={createObjectIdValidator(
              getLocalisationMessage("select_company", "Select Company"),
            )}
          />
        </FlexBox>
      )}

      {Boolean(
        props.field === POSTCODE_FROM || props.field === POSTCODE_TO,
      ) && (
        <FlexBox flex={true} align="center">
          <FormPostcodeIndexAutoComplete
            hintText={getLocalisationMessage(
              "postcode_index",
              "Postcode Index",
            )}
            name={fieldName}
            fullWidth={true}
            validate={createObjectIdValidator(
              getLocalisationMessage("select_postcode", "Select Postcode"),
            )}
          />
        </FlexBox>
      )}

      {props.field === CUSTOMER && (
        <FlexBox flex={true} align="center">
          <FormCustomerAutoComplete
            hintText={getLocalisationMessage("customer", "Customer")}
            name={fieldName}
            fullWidth={true}
            validate={createObjectIdValidator(
              getLocalisationMessage("select_customer", "Select Customer"),
            )}
          />
        </FlexBox>
      )}

      {Boolean(
        props.field === PICKUP_WAREHOUSE ||
          props.field === CURRENT_WAREHOUSE ||
          props.field === DESTINATION_WAREHOUSE,
      ) && (
        <FlexBox flex={true} align="center">
          <FormWarehouseAutoComplete
            hintText={getLocalisationMessage("warehouse", "Warehouse")}
            name={fieldName}
            fullWidth={true}
            validate={createObjectIdValidator(
              getLocalisationMessage("select_warehouse", "Select Warehouse"),
            )}
          />
        </FlexBox>
      )}

      {Boolean(
        props.field === TIME_SLOT_PICK_UP || props.field === TIME_SLOT_DROP_OFF,
      ) && (
        <FlexBox flex={true} align="center">
          <FormTimeSlotAutoComplete
            hintText={getLocalisationMessage("time_slot", "Time Slot")}
            name={fieldName}
            fullWidth={true}
            validate={createObjectIdValidator(
              getLocalisationMessage("select_time_slot", "Select Time Slot"),
            )}
          />
        </FlexBox>
      )}

      {Boolean(
        props.field === COD ||
          props.field === COST ||
          props.field === PRICE ||
          props.field === RTO_PRICE ||
          props.field === RATING,
      ) && (
        <FlexBox flex={true} align="center">
          <FormTextField
            parseOnBlur={parseFloat}
            hintText={getLocalisationMessage(
              "eg_05_10_365",
              "eg: 0.5, 10, 365",
            )}
            name={fieldName}
            fullWidth={true}
            validate={createNotNullValidator(
              getLocalisationMessage("value", "Value"),
            )}
          />
        </FlexBox>
      )}

      {props.field === LIMIT && (
        <FlexBox flex={true} align="center">
          <FormTextField
            parseOnBlur={parseInteger}
            hintText={getLocalisationMessage("eg_10_50_100", "eg: 10, 50, 100")}
            validate={createNotNullValidator(
              getLocalisationMessage("add_value", "Add Value"),
            )}
            name={fieldName}
            fullWidth={true}
          />
        </FlexBox>
      )}

      {props.field === ITEM_VALUE && (
        <FlexBox flex={true} align="center">
          <FormTextField
            parseOnBlur={parseInteger}
            hintText={getLocalisationMessage("eg_10_50_100", "eg: 10, 50, 100")}
            validate={createNotNullValidator(
              getLocalisationMessage("add_value", "Add Value"),
            )}
            name={fieldName}
            fullWidth={true}
          />
        </FlexBox>
      )}

      {props.field === CREATED_DATE && (
        <FlexBox flex={true} align="center">
          <FormDateField
            hintText={getLocalisationMessage("created_date", "Created Date")}
            name={fieldName}
            fullWidth={true}
            validate={createDateValidator(
              getLocalisationMessage(
                "select_created_date",
                "Select Created Date",
              ),
            )}
          />
        </FlexBox>
      )}

      {props.field === CREATED_TIME && (
        <FlexBox flex={true} align="center">
          <FormTimeField
            hintText={getLocalisationMessage("created_time", "Created Time")}
            name={fieldName}
            fullWidth={true}
            validate={createDateValidator(
              getLocalisationMessage(
                "select_created_time",
                "Select Created Time",
              ),
            )}
          />
        </FlexBox>
      )}

      {props.field === PAYMENT_DONE_BY && (
        <FlexBox flex={true} align="center">
          <FormSelectField
            hintText={getLocalisationMessage("payer", "Payer")}
            options={OrderPayerTypes}
            formatOption={x => getLocalisationMessage(x, formatText(x))}
            name={fieldName}
            fullWidth={true}
            validate={createNotFalsyValidator(
              getLocalisationMessage("select_payer", "Select Payer"),
            )}
          />
        </FlexBox>
      )}

      {props.field === SOURCE && (
        <FlexBox flex={true} align="center">
          <FormSelectField
            hintText={getLocalisationMessage("source", "Source")}
            options={sourceFields}
            formatOption={x =>
              getLocalisationMessage(x.toLowerCase(), formatText(x))
            }
            name={fieldName}
            fullWidth={true}
            validate={createNotFalsyValidator(
              getLocalisationMessage("select_source", "Select Source"),
            )}
          />
        </FlexBox>
      )}

      {props.field === DAY_OF_WEEK && (
        <FlexBox flex={true} align="center">
          <FormSelectField
            hintText={getLocalisationMessage("day_of_week", "Day Of Week")}
            options={DayOfWeek}
            formatOption={x => getLocalisationMessage(x, formatText(x))}
            name={fieldName}
            fullWidth={true}
            validate={createNotFalsyValidator(
              getLocalisationMessage(
                "select_day_of_week",
                "Select Day Of Week",
              ),
            )}
          />
        </FlexBox>
      )}

      {props.field === TRIGGER && (
        <FlexBox flex={true} align="center">
          <FormSelectField
            hintText={getLocalisationMessage("trigger", "Trigger")}
            options={TriggerConstants}
            formatOption={x => getLocalisationMessage(x) || formatText(x)}
            name={fieldName}
            fullWidth={true}
            validate={createNotFalsyValidator(
              getLocalisationMessage("select_trigger", "Select Trigger"),
            )}
          />
        </FlexBox>
      )}

      {props.field === PAYMENT_METHOD && (
        <FlexBox flex={true} align="center">
          <FormSelectField
            hintText={getLocalisationMessage(
              "payment_method",
              "Payment Method",
            )}
            options={OrderPaymentTypes}
            formatOption={x => getLocalisationMessage(x) || formatText(x)}
            name={fieldName}
            fullWidth={true}
            validate={createNotFalsyValidator(
              getLocalisationMessage(
                "select_payment_method",
                "Select Payment Method",
              ),
            )}
          />
        </FlexBox>
      )}

      {props.field === PARCEL_SIZE && (
        <FlexBox flex={true} align="center">
          <FormSelectField
            hintText={getLocalisationMessage("parcel_size", "Parcel Size")}
            options={OrderSizeCodes}
            formatOption={x => getLocalisationMessage(x) || formatText(x)}
            name={fieldName}
            fullWidth={true}
            validate={createNotFalsyValidator(
              getLocalisationMessage(
                "select_parcel_size",
                "Select Parcel Size",
              ),
            )}
          />
        </FlexBox>
      )}

      {props.field === COURIER_TYPE && (
        <FlexBox flex={true} align="center">
          <FormCourierTypeSelectField
            hintText={getLocalisationMessage("courier", "Courier")}
            name={fieldName}
            fullWidth={true}
            validate={createNotFalsyValidator(
              getLocalisationMessage("select_courier", "Select Courier"),
            )}
          />

          {props.ruleType === ORDER_DEADLINE && (
            <FormTextField
              name={extraFieldName}
              fullWidth={true}
              parseOnBlur={parseInteger}
              hintText={getLocalisationMessage(
                "eg_2_5_10_min",
                "eg: 2, 5, 10 (in minutes)",
              )}
              validate={createNotNullValidator(
                getLocalisationMessage("add_value", "Add Value"),
              )}
            />
          )}
        </FlexBox>
      )}

      {props.field === ORDER_TYPE && (
        <FlexBox flex={true} align="center">
          <FormSelectField
            name={fieldName}
            fullWidth={true}
            hintText={getLocalisationMessage("order_type", "Order Type")}
            formatOption={x => getLocalisationMessage(x) || formatText(x)}
            options={RuleConditionOrderTypes}
            validate={createNotFalsyValidator(
              getLocalisationMessage("select_order_type", "Select Order Type"),
            )}
          />
        </FlexBox>
      )}

      {Boolean(
        props.field === MERCHANT ||
          props.field === HAS_FETCH_ITEMS ||
          props.field === IS_RETURN,
      ) && (
        <FlexBox flex={true} align="center">
          <FormSelectField
            name={fieldName}
            fullWidth={true}
            hintText={getLocalisationMessage("value", "Value")}
            options={trueFalseOptions}
            formatOption={x => formatTrueFalseOption(x, getLocalisationMessage)}
            validate={createNotNilValidator(
              getLocalisationMessage("select_value", "Select Value"),
            )}
          />
        </FlexBox>
      )}

      {Boolean(
        props.field === SENDER_ADDRESS_TYPE ||
          props.field === RECIPIENT_ADDRESS_TYPE,
      ) && (
        <FlexBox flex={true} align="center">
          <FormSelectField
            fullWidth={true}
            name={fieldName}
            hintText={getLocalisationMessage("value", "Value")}
            options={AddressType}
            formatOption={x => getLocalisationMessage(x) || formatText(x)}
            validate={createNotNilValidator(
              getLocalisationMessage("select_value", "Select Value"),
            )}
          />
        </FlexBox>
      )}

      {Boolean(
        props.field === JURISDICTION_FROM || props.field === JURISDICTION_TO,
      ) && (
        <FlexBox flex={true} align="center">
          <FormJMAutoComplete
            name={fieldName}
            fullWidth={true}
            label={getLocalisationMessage("jurisdiction", "Jurisdiction")}
            renderOption={option => (
              <FlexBox direction="column">
                <span>{option.name}</span>
                <span
                  style={{
                    fontSize: ".8rem",
                    fontStyle: "italic",
                  }}
                >
                  {option.value.hierarchy.map((item, index, arr) =>
                    index === arr.length - 1 ? item.name : `${item.name} > `,
                  )}
                </span>
              </FlexBox>
            )}
          />
        </FlexBox>
      )}

      {Boolean(
        props.field === SELLER_NAME ||
          props.field === SELLER_ID ||
          props.field === SENDER_ADDRESS ||
          props.field === SENDER_NAME ||
          props.field === RECIPIENT_ADDRESS,
      ) && (
        <FlexBox flex={true} align="center">
          <FormTextField
            fullWidth={true}
            name={fieldName}
            hintText={getLocalisationMessage("value", "Value")}
            validate={createNotNilValidator(
              getLocalisationMessage("value", "Value"),
            )}
          />
        </FlexBox>
      )}
    </FlexBox>
  );
}

export default enhancer(OrderRuleTreeLeaf);
