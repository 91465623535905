import React from "react";
import _ from "lodash";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream, withContext } from "recompose";
import PropTypes from "prop-types";
import {
  FieldArray,
  formValueSelector,
  reduxForm,
  SubmissionError,
} from "redux-form";
import { Button, Card, CardContent, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { DoneAll } from "@material-ui/icons";
import OrderRuleTreeBranch from "./OrderRuleTreeBranch";
import FormTextField from "../form/FormTextField";
import FormRulesChips from "../form/FormRulesChips";
import FormSelectField from "../form/FormSelectField";
import FormTagAutoComplete from "../form/FormTagAutoComplete";
import FormDriverAutoComplete from "../form/FormDriverAutoComplete";
import FormSupplierAutoComplete from "../form/FormSupplierAutoComplete";
import InfoIcon from "../icons/InfoIcon";
import FlexBox from "../ui-core/FlexBox";
import PageFab from "../ui-core/PageFab";
import PageLoading from "../ui-core/PageLoading";
import TooltipOverlay from "../ui-core/TooltipOverlay";
import {
  getValue,
  hasValue,
  isEqualData,
  toArray,
  toJS,
  toPlainObject,
} from "../../helpers/DataUtils";
import { safeParseDate, shortTimeToDate } from "../../helpers/DateUtils";
import {
  createNotFalsyValidator,
  createNotNilValidator,
  createObjectIdValidator,
} from "../../helpers/FormUtils";
import {
  formatText,
  formatTime,
  parseInteger,
} from "../../helpers/FormatUtils";
import { isValidObjectId, validatePrefix } from "../../helpers/ValidateUtils";
import { getMarketplaceId } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import RuleType, {
  AUTO_DISPATCH,
  ORDER_DEADLINE,
  ORDER_RULE,
  ORDER_TAG,
  PICKUP_JOB_AUTOMATION,
  ZONING_RULE,
} from "../../constants/RuleType";
import triggerTypes, { ORDER_CREATED } from "../../constants/TriggerType";
import AssignmentType from "../../constants/AssignmentType";
import {
  COD,
  COMPANY,
  COST,
  COURIER_TYPE,
  CREATED_DATE,
  CREATED_TIME,
  CURRENT_WAREHOUSE,
  CUSTOMER,
  DESTINATION_WAREHOUSE,
  HAS_FETCH_ITEMS,
  IS_RETURN,
  ITEM_VALUE,
  JURISDICTION_FROM,
  JURISDICTION_TO,
  LIMIT,
  MERCHANT,
  NEIGHBORHOOD_FROM,
  NEIGHBORHOOD_TO,
  PARCEL_SIZE,
  PICKUP_WAREHOUSE,
  POSTCODE_FROM,
  POSTCODE_TO,
  PRICE,
  RATING,
  RECIPIENT_ADDRESS_TYPE,
  RTO_PRICE,
  SELLER_ID,
  SELLER_NAME,
  SENDER_ADDRESS_TYPE,
  SENDER_NAME,
  TIME_SLOT_DROP_OFF,
  TIME_SLOT_PICK_UP,
} from "../../constants/RuleConditionField";
import UpperOverallStatus, { ACTIVE } from "../../constants/UpperOverallStatus";
import AllocateByDriverTypes, {
  ONE_BY_ONE,
} from "../../constants/AllocateByDriverTypes";
import deliveryAllocationTypes, {
  ALLOCATE_BY_DRIVER,
  ALLOCATE_BY_PROXIMITY,
  ALLOCATE_BY_SUPPLIER,
  ALLOCATE_BY_WAREHOUSE,
  ASSIGN_TO_DRIVER,
  ASSIGN_TO_SUPPLIER,
  OrderRuleForPickupJobAutomation,
} from "../../constants/DeliveryAllocationTypes";
import { AND, OR } from "../../constants/RuleConditionalOperator";
import { ALLOCATION_ID } from "../../../server/constants/MarketplaceId";
import TransportationType, {
  TERRESTRIAL,
} from "../../constants/TransportationType";
import FormWarehouseAutoComplete from "../form/FormWarehouseAutoComplete";

const styles = {
  infoTooltip: {
    position: "absolute",
    right: "1%",
  },
};
const valueSelector = formValueSelector("OrderRuleTree");

const OrderRuleSuppliers = (props) => (
  <FlexBox flex={true} direction="column">
    <FlexBox flex={true} direction="column">
      {props.fields.map((suppliers, index) => (
        <FlexBox gutter={8} flex={true} key={index}>
          <Card
            style={{
              width: "100%",
              padding: "20px",
              marginBottom: "10px",
              marginTop: "45px",
            }}
          >
            <FlexBox gutter={8} flex={true} direction="column">
              <FlexBox flex={true}>
                <FlexBox flex={true} direction="column">
                  <FormSupplierAutoComplete
                    fullWidth={true}
                    label={`${props.getLocalisationMessage(
                      "supplier",
                      "Supplier",
                    )} *`}
                    name={`${suppliers}.supplier`}
                    validate={createObjectIdValidator(
                      props.getLocalisationMessage(
                        "select_supplier",
                        "Select Supplier",
                      ),
                    )}
                    hintText={props.getLocalisationMessage(
                      "type_to_search",
                      "Type to search ...",
                    )}
                  />
                </FlexBox>

                <FlexBox flex={true} direction="column">
                  <FormTextField
                    fullWidth={true}
                    parseOnBlur={parseInteger}
                    label={`${props.getLocalisationMessage(
                      "daily_ratio",
                      "Daily Ratio",
                    )} *`}
                    name={`${suppliers}.daily_quota`}
                    validate={createNotNilValidator(
                      props.getLocalisationMessage(
                        "enter_daily_ratio",
                        "Enter Daily Ratio",
                      ),
                    )}
                  />
                </FlexBox>

                <FlexBox flex={true} direction="column">
                  <FormTextField
                    fullWidth={true}
                    parseOnBlur={parseInteger}
                    label={`${props.getLocalisationMessage(
                      "daily_limit",
                      "Daily Limit",
                    )} *`}
                    name={`${suppliers}.daily_limit`}
                    validate={createNotNilValidator(
                      props.getLocalisationMessage(
                        "enter_daily_limit",
                        "Enter Daily Limit",
                      ),
                    )}
                  />
                </FlexBox>

                <FlexBox direction="column" justify="center">
                  <Button onClick={() => props.fields.remove(index)}>
                    {props.getLocalisationMessage("remove", "Remove")}
                  </Button>
                </FlexBox>
              </FlexBox>

              {hasValue(
                props.supplierItems,
                `suppliers.${index}.supplier.id`,
              ) && (
                <FlexBox flex={true}>
                  <FlexBox flex={3} align="center">
                    {hasValue(
                      props.supplierItems,
                      `suppliers.${index}.parent.id`,
                    ) && (
                      <div className={props.classes.parentRule}>
                        <p> Parent Rule:</p>
                        <h6>
                          {getValue(
                            props.supplierItems,
                            `suppliers.${index}.parent.name`,
                          )}
                        </h6>
                      </div>
                    )}
                  </FlexBox>
                  <FlexBox flex={true}>
                    <FormRulesChips
                      name={`${suppliers}.children`}
                      supplierId={getValue(
                        props.supplierItems,
                        `suppliers.${index}.supplier.id`,
                      )}
                      fullWidth={true}
                      hintText={props.getLocalisationMessage(
                        "type_to_search",
                        "Type to search ...",
                      )}
                      label={props.getLocalisationMessage(
                        "child_rules",
                        "Child Rules",
                      )}
                    />
                  </FlexBox>
                </FlexBox>
              )}
            </FlexBox>
          </Card>
        </FlexBox>
      ))}
    </FlexBox>
    <FlexBox>
      <Button onClick={() => props.fields.push({})}>
        {props.getLocalisationMessage("add_supplier", "Add Supplier")}
      </Button>
    </FlexBox>
  </FlexBox>
);

OrderRuleSuppliers.propTypes = {
  fields: PropTypes.object,
  supplierItems: PropTypes.object,
  classes: PropTypes.object,
  getLocalisationMessage: PropTypes.func.isRequired,
};

const OrderRuleDrivers = (props) => (
  <FlexBox flex={true} direction="column">
    <FlexBox flex={true} direction="column">
      {props.fields.map((drivers, index) => (
        <FlexBox gutter={8} flex={true} key={index}>
          <FlexBox flex={true} direction="column">
            <FormDriverAutoComplete
              fullWidth={true}
              label={`${props.getLocalisationMessage("driver", "Driver")} *`}
              name={`${drivers}.driver`}
              validate={createObjectIdValidator(
                props.getLocalisationMessage("select_driver", "Select Driver"),
              )}
              hintText={props.getLocalisationMessage(
                "type_to_search",
                "Type to search ...",
              )}
            />
          </FlexBox>

          <FlexBox flex={true} direction="column">
            <FormTextField
              fullWidth={true}
              parseOnBlur={parseInteger}
              label={`${props.getLocalisationMessage(
                "daily_ratio",
                "Daily Ratio",
              )} *`}
              name={`${drivers}.daily_quota`}
              validate={createNotNilValidator(
                props.getLocalisationMessage(
                  "enter_daily_ratio",
                  "Enter Daily Ratio",
                ),
              )}
            />
          </FlexBox>

          <FlexBox flex={true} direction="column">
            <FormTextField
              fullWidth={true}
              parseOnBlur={parseInteger}
              label={`${props.getLocalisationMessage(
                "daily_limit",
                "Daily Limit",
              )} *`}
              name={`${drivers}.daily_limit`}
              validate={createNotNilValidator(
                props.getLocalisationMessage(
                  "enter_daily_limit",
                  "Enter Daily Limit",
                ),
              )}
            />
          </FlexBox>

          <FlexBox direction="column" justify="center">
            <Button onClick={() => props.fields.remove(index)}>
              {props.getLocalisationMessage("remove", "Remove")}
            </Button>
          </FlexBox>
        </FlexBox>
      ))}
    </FlexBox>
    <FlexBox>
      <Button onClick={() => props.fields.push({})}>
        {props.getLocalisationMessage("add_driver", "Add Driver")}
      </Button>
    </FlexBox>
  </FlexBox>
);

OrderRuleDrivers.propTypes = {
  fields: PropTypes.object,
  getLocalisationMessage: PropTypes.func.isRequired,
};

export const isObjectField = (field) => {
  switch (field) {
    case COMPANY:
    case CUSTOMER:
    case PICKUP_WAREHOUSE:
    case CURRENT_WAREHOUSE:
    case DESTINATION_WAREHOUSE:
    case NEIGHBORHOOD_FROM:
    case NEIGHBORHOOD_TO:
    case POSTCODE_FROM:
    case POSTCODE_TO:
    case TIME_SLOT_DROP_OFF:
    case TIME_SLOT_PICK_UP:
    case JURISDICTION_FROM:
    case JURISDICTION_TO:
      return true;

    default:
      return false;
  }
};

export const isNameField = (field) => {
  switch (field) {
    case SELLER_ID:
    case SELLER_NAME:
    case SENDER_NAME:
    case COMPANY:
    case CUSTOMER:
    case PICKUP_WAREHOUSE:
    case CURRENT_WAREHOUSE:
    case DESTINATION_WAREHOUSE:
    case TIME_SLOT_DROP_OFF:
    case TIME_SLOT_PICK_UP:
    case MERCHANT:
    case HAS_FETCH_ITEMS:
    case IS_RETURN:
    case COD:
    case COST:
    case PRICE:
    case RATING:
    case PARCEL_SIZE:
    case RTO_PRICE:
    case RECIPIENT_ADDRESS_TYPE:
    case SENDER_ADDRESS_TYPE:
      return true;

    default:
      return false;
  }
};

export const isDateField = (field) => {
  switch (field) {
    case CREATED_DATE:
      return true;

    default:
      return false;
  }
};

export const isTimeField = (field) => {
  switch (field) {
    case CREATED_TIME:
      return true;

    default:
      return false;
  }
};

export const getFieldFieldKey = (field) => {
  switch (field) {
    case LIMIT:
    case COMPANY:
    case CUSTOMER:
    case PICKUP_WAREHOUSE:
    case CURRENT_WAREHOUSE:
    case DESTINATION_WAREHOUSE:
    case JURISDICTION_FROM:
    case JURISDICTION_TO:
    case POSTCODE_FROM:
    case POSTCODE_TO:
    case TIME_SLOT_DROP_OFF:
    case TIME_SLOT_PICK_UP:
    case ITEM_VALUE:
      return "whole_number_value";

    case COD:
    case COST:
    case PRICE:
    case RATING:
    case RTO_PRICE:
      return "fraction_number_value";

    case MERCHANT:
    case HAS_FETCH_ITEMS:
    case IS_RETURN:
      return "boolean_value";

    case CREATED_DATE:
      return "date_value";

    default:
      return "string_value";
  }
};

const getFieldValue = (item) => {
  const value = item[`${item.field}-value`];

  return isObjectField(item.field) ? value.id : value;
};

const getExtraFieldValue = (item) => {
  const value = item[`${item.field}-extra-value`];

  return isObjectField(item.field) ? value.id : value;
};

const hasRules = fp.flow(
  fp.over([fp.get("isLeaf"), fp.get("branch")]),
  fp.some(Boolean),
);
const enhancer = compose(
  withContext(
    {
      getWarehousePolygons: PropTypes.func.isRequired,
      getNeighborhoodPolygons: PropTypes.func.isRequired,

      getCachedRule: PropTypes.func.isRequired,
      getRulePredictions: PropTypes.func.isRequired,
      getChildRulePredictions: PropTypes.func.isRequired,
      getCachedCustomer: PropTypes.func.isRequired,
      getCustomerPredictions: PropTypes.func.isRequired,
      getCachedSupplier: PropTypes.func.isRequired,
      getSupplierPredictions: PropTypes.func.isRequired,
      getCachedDriver: PropTypes.func.isRequired,
      getDriverPredictions: PropTypes.func.isRequired,
      getCachedTimeSlot: PropTypes.func.isRequired,
      getTimeSlotPredictions: PropTypes.func.isRequired,
      getCachedWarehouse: PropTypes.func.isRequired,
      getWarehousePredictions: PropTypes.func.isRequired,
      getCachedPostcode: PropTypes.func.isRequired,
      getPostcodePredictions: PropTypes.func.isRequired,
    },
    (props) => ({
      getWarehousePolygons: props.getWarehousePolygons,
      getNeighborhoodPolygons: props.getNeighborhoodPolygons,

      getCachedRule: props.getCachedRule,
      getRulePredictions: props.getRulePredictions,
      getChildRulePredictions: props.getChildRulePredictions,
      getCachedCustomer: props.getCachedCustomer,
      getCustomerPredictions: props.getCustomerPredictions,
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
      getCachedDriver: props.getCachedDriver,
      getDriverPredictions: props.getDriverPredictions,
      getCachedTimeSlot: props.getCachedTimeSlot,
      getTimeSlotPredictions: props.getTimeSlotPredictions,
      getCachedWarehouse: props.getCachedWarehouse,
      getWarehousePredictions: props.getWarehousePredictions,
      getCachedPostcode: props.getCachedPostcode,
      getPostcodePredictions: props.getPostcodePredictions,
    }),
  ),
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    marketplaceId: getMarketplaceId(state),
  })),
  mapPropsStream((propsStream) => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("tree", isEqualData)
      .map((props) => {
        const { tree } = props;

        if (!tree) {
          return {
            ...props.initialValues,
            priority: 0,
            transportationType: TERRESTRIAL,
            ruleStatus: ACTIVE,
            ruleType: ORDER_RULE,
            prefix: "",
            triggerType: ORDER_CREATED,
            isSupplierOrDriver: ASSIGN_TO_SUPPLIER,
            assignType: ONE_BY_ONE,
            ruleOutcomeType: ALLOCATE_BY_SUPPLIER,
            numberOfRetries: 1,
            waitTime: 2,
            suggestRadius: 2,
            driverMaxOrders: 0,
          };
        }

        const ruleType = tree.get("rule_type");

        const mapFromCondition = (group) => {
          const isLeaf = Boolean(
            group &&
              group.get("conditions") &&
              !group.get("conditions").isEmpty(),
          );
          const isBranch = Boolean(
            group &&
              group.get("condition_groups") &&
              !group.get("condition_groups").isEmpty(),
          );

          const item = { isLeaf: false, expanded: true, leaf: [], branch: [] };

          if (isLeaf) {
            item.isLeaf = true;
            item.overAny =
              group.getIn(["conditions", 0, "conditional_operator"]) !== AND;

            group
              .get("conditions")
              .sortBy((v) => v.get("priority"))
              .forEach((condition) => {
                const field = condition.get("field");
                const value = condition.get(getFieldFieldKey(field));

                if (field === COURIER_TYPE && ruleType === ORDER_DEADLINE) {
                  const extraValue = condition.get("whole_number_value");

                  item.leaf.push({
                    field,
                    comparationOperator: condition.get("comparison_operator"),
                    [`${field}-value`]: value,
                    [`${field}-extra-value`]: extraValue,
                  });
                } else {
                  item.leaf.push({
                    field,
                    comparationOperator: condition.get("comparison_operator"),
                    [`${field}-value`]: isObjectField(field)
                      ? { id: value }
                      : isTimeField(field)
                      ? shortTimeToDate(value)
                      : isDateField(field)
                      ? safeParseDate(value)
                      : isNameField(field)
                      ? value
                      : _.toUpper(value),
                  });
                }
              });
          }

          if (isBranch) {
            item.overAny =
              group.getIn(["condition_groups", 0, "conditional_operator"]) !==
              AND;

            group
              .get("condition_groups")
              .sortBy((v) => v.get("priority"))
              .forEach((childGroup) => {
                item.branch.push(mapFromCondition(childGroup));
              });
          }

          return item;
        };

        const mapSuppliers = fp.flow(
          toArray,
          fp.map((item) => ({
            supplier: { id: item.supplier_id },
            daily_quota: item.daily_quota,
            daily_limit: item.daily_limit,
            children: item.children,
            parent: item.parent,
          })),
        );

        const mapDrivers = fp.flow(
          toArray,
          fp.map((item) => ({
            driver: { id: item.driver_id },
            daily_quota: item.daily_quota,
            daily_limit: item.daily_limit,
          })),
        );

        const suppliers = mapSuppliers(tree.get("suppliers"));
        const drivers = mapDrivers(tree.get("drivers"));

        const ruleOutcomeType = tree.get(
          "rule_outcome_type",
          ALLOCATE_BY_SUPPLIER,
        );

        const isSupplierOrDriver =
          ruleOutcomeType === ALLOCATE_BY_WAREHOUSE
            ? ALLOCATE_BY_WAREHOUSE
            : ruleOutcomeType === ALLOCATE_BY_SUPPLIER
            ? ASSIGN_TO_SUPPLIER
            : fp.size(drivers) > 0
            ? ASSIGN_TO_DRIVER
            : ruleOutcomeType;

        const assignType =
          isSupplierOrDriver === ALLOCATE_BY_PROXIMITY
            ? tree.get("assign_type", false)
            : null;

        return {
          id: tree.get("id"),
          name: tree.get("name"),
          priority: tree.get("priority"),
          transportationType: tree.get("transportation_type"),
          tag:
            ruleType === ORDER_TAG ? { id: tree.getIn(["tag", "id"]) } : null,
          triggerType: _.toUpper(tree.get("trigger_type")),
          ruleStatus: tree.get("rule_status"),
          description: tree.get("description"),
          warehouse: toJS(tree.get("warehouse")),
          prefix: tree.get("prefix"),
          assignmentType: tree.get("assignment_type"),
          supplierRule: toPlainObject(tree.get("supplier_rule")),
          orderRules: mapFromCondition(tree.get("condition_group")),
          ruleType,
          numberOfRetries: tree.get("number_of_retries"),
          waitTime: tree.get("wait_time"),
          suggestRadius: tree.get("suggest_radius"),
          driverMaxOrders: tree.get("driver_max_orders"),
          ruleOutcomeType,
          suppliers,
          drivers,
          isSupplierOrDriver,
          assignType,
        };
      });

    const handleSubmit = fp.curry((props, values) => {
      const mapToCondition = (item, priority = 0, overAny = false) => {
        const group = { priority, conditional_operator: overAny ? OR : AND };

        if (item.isLeaf) {
          group.conditions = _.map(item.leaf, (v, k) => {
            const condition = Map({
              priority: k,
              field: v.field,
              comparison_operator: v.comparationOperator,
              conditional_operator: item.overAny ? OR : AND,
              [getFieldFieldKey(v.field)]: isTimeField(v.field)
                ? formatTime(getFieldValue(v))
                : isNameField(v.field)
                ? getFieldValue(v)
                : _.toLower(getFieldValue(v)),
            }).asMutable();

            if (v.field === COURIER_TYPE && values.ruleType === ORDER_DEADLINE)
              condition.set("whole_number_value", getExtraFieldValue(v));

            return condition.asImmutable().toJS();
          });
        } else {
          group.condition_groups = _.map(item.branch, (v, k) =>
            mapToCondition(v, k, item.overAny),
          );
        }

        return group;
      };

      const mapSuppliers = fp.flow(
        fp.toArray,
        fp.map((item) => ({
          supplier_id: item.supplier.id,
          daily_quota: item.daily_quota,
          daily_limit: item.daily_limit,
          children: item.children,
          parent: item.parent,
        })),
      );

      const mapDrivers = fp.flow(
        fp.toArray,
        fp.map((item) => ({
          driver_id: item.driver.id,
          daily_quota: item.daily_quota,
          daily_limit: item.daily_limit,
        })),
      );

      const isOrderRule =
        values.ruleType === ORDER_RULE ||
        values.ruleType === PICKUP_JOB_AUTOMATION ||
        values.ruleType === AUTO_DISPATCH ||
        values.ruleType === ORDER_TAG ||
        values.ruleType === ORDER_DEADLINE ||
        values.ruleType === ZONING_RULE;

      const assignType =
        props.marketplaceId !== ALLOCATION_ID &&
        values.isSupplierOrDriver === ALLOCATE_BY_PROXIMITY
          ? values.assignType
          : null;

      let ruleOutcomeType = null;
      switch (values.isSupplierOrDriver) {
        case ASSIGN_TO_SUPPLIER:
          ruleOutcomeType = ALLOCATE_BY_SUPPLIER;
          break;
        case ASSIGN_TO_DRIVER:
          ruleOutcomeType = ALLOCATE_BY_DRIVER;
          break;
        default:
          ruleOutcomeType = values.isSupplierOrDriver;
          break;
      }

      const conditionRules = isOrderRule
        ? values.orderRules
        : values.supplierRules;
      if (!hasRules(conditionRules))
        throw new SubmissionError({
          _error: props.getLocalisationMessage(
            "please_add_condition_rule",
            "Please add condition rule!",
          ),
        });

      const rule = {
        id: values.id,
        name: values.name,
        rule_type: values.ruleType,
        prefix: values.prefix,
        rule_status: values.ruleStatus,
        description: values.description,
        warehouse: values.warehouse,
        assignment_type: values.assignmentType,
        number_of_retries: values.numberOfRetries,
        wait_time: values.waitTime,
        suggest_radius: values.suggestRadius,
        driver_max_orders: values.driverMaxOrders,
        priority: fp.toInteger(values.priority),
        transportation_type: values.transportationType,
        trigger_type: isOrderRule ? values.triggerType : null,
        rule_outcome_type:
          values.ruleType !== ORDER_TAG ? ruleOutcomeType : null,
        assign_type: assignType,
        tag: values.ruleType === ORDER_TAG ? values.tag : null,
        suppliers: isOrderRule
          ? values.isSupplierOrDriver === ASSIGN_TO_SUPPLIER ||
            values.isSupplierOrDriver === ALLOCATE_BY_PROXIMITY
            ? mapSuppliers(values.suppliers)
            : []
          : [],
        drivers: isOrderRule
          ? values.isSupplierOrDriver === ASSIGN_TO_DRIVER
            ? mapDrivers(values.drivers)
            : []
          : [],
        any_supplier: isOrderRule
          ? values.isSupplierOrDriver === ASSIGN_TO_SUPPLIER ||
            values.isSupplierOrDriver === ALLOCATE_BY_PROXIMITY
            ? fp.isEmpty(values.suppliers)
            : false
          : false,
        supplier_rule:
          isOrderRule && isValidObjectId(values.supplierRule)
            ? { id: values.supplierRule.id }
            : null,
        condition_group: mapToCondition(conditionRules),
      };

      return props.onSubmit(rule);
    });

    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,
        initialValues,
        onSubmit: handleSubmit(props),
      }))
      .distinctUntilChanged(isEqualData);
  }),
  reduxForm({
    form: "OrderRuleTree",
    enableReinitialize: true,
    destroyOnUnmount: !module.hot,
    forceUnregisterOnUnmount: false,
    validate: (values, props) => ({
      prefix:
        validatePrefix(values.prefix) &&
        props.getLocalisationMessage(
          "only_alphanumeric_characters_are_allowed_here",
          "Only alphanumeric characters are allowed here",
        ),
      numberOfRetries:
        (values.numberOfRetries < 1 || values.numberOfRetries > 15) &&
        props.getLocalisationMessage("incorrect_value", "Incorrect Value"),
      waitTime:
        values.waitTime < 2 &&
        props.getLocalisationMessage("incorrect_value", "Incorrect Value"),
      suggestRadius:
        values.suggestRadius < 2 &&
        props.getLocalisationMessage("incorrect_value", "Incorrect Value"),
    }),
  }),
  connect((state) => ({
    values: valueSelector(
      state,
      "ruleType",
      "prefix",
      "numberOfRetries",
      "waitTime",
      "suggestRadius",
      "supplierRule",
      "isSupplierOrDriver",
      "suppliers",
    ),
  })),
  useSheet({
    parentRule: {
      " & > h6": {
        fontWeight: "bold",
      },
      " & > p": {
        marginBottom: 0,
      },
    },
    infoIconParent: {
      position: "relative",
    },
    infoIcon: {
      width: "13px",
    },
  }),
);

OrderRuleTree.propTypes = {
  classes: PropTypes.object,
  values: PropTypes.object,
  marketplaceId: PropTypes.number,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderRuleTree(props) {
  const { values, getLocalisationMessage } = props;

  const isOrderRule =
    values.ruleType === ORDER_RULE ||
    values.ruleType === PICKUP_JOB_AUTOMATION ||
    values.ruleType === ZONING_RULE ||
    values.ruleType === AUTO_DISPATCH ||
    values.ruleType === ORDER_DEADLINE ||
    values.ruleType === ORDER_TAG;

  return (
    <FlexBox
      container={8}
      direction="column"
      element={<form onSubmit={props.handleSubmit} />}
    >
      <PageLoading isLoading={props.submitting} />

      <PageFab type="submit" autoHide={false}>
        <DoneAll />
      </PageFab>

      <FlexBox direction="column" gutter={8}>
        <FlexBox flex="none" direction="column">
          <Card>
            <CardContent>
              <FlexBox gutter={8}>
                <FlexBox flex={true} direction="column">
                  <FormTextField
                    name="name"
                    fullWidth={true}
                    label={`${getLocalisationMessage("name", "Name")} *`}
                    validate={createNotFalsyValidator(
                      getLocalisationMessage(
                        "enter_rule_name",
                        "Enter Rule Name",
                      ),
                    )}
                  />
                </FlexBox>

                <FlexBox flex={true} direction="column">
                  <FormTextField
                    name="description"
                    fullWidth={true}
                    label={getLocalisationMessage("description", "Description")}
                  />
                </FlexBox>

                {props.marketplaceId === ALLOCATION_ID && (
                  <FlexBox flex={true} direction="column">
                    <FormSelectField
                      autoWidth={true}
                      fullWidth={true}
                      name="assignmentType"
                      label={getLocalisationMessage(
                        "assigment_type",
                        "Assignment Type",
                      )}
                      options={AssignmentType}
                    />
                  </FlexBox>
                )}

                <FlexBox flex={true} direction="column">
                  <FormSelectField
                    autoWidth={true}
                    fullWidth={true}
                    name="ruleStatus"
                    label={getLocalisationMessage("status", "Status")}
                    formatOption={(x) =>
                      getLocalisationMessage(x) || formatText(x)
                    }
                    options={UpperOverallStatus}
                    validate={createNotFalsyValidator(
                      getLocalisationMessage("select_status", "Select Status"),
                    )}
                  />
                </FlexBox>

                <FlexBox flex={true} direction="column">
                  <FormTextField
                    name="priority"
                    fullWidth={true}
                    parseOnBlur={parseInteger}
                    label={getLocalisationMessage("priority", "Priority")}
                  />
                </FlexBox>
                <FlexBox flex={true} direction="column">
                  <FormSelectField
                    name="transportationType"
                    fullWidth={true}
                    options={TransportationType}
                    formatOption={(x) =>
                      getLocalisationMessage(x.toLowerCase(), formatText(x))
                    }
                    label={getLocalisationMessage(
                      "transportation_type",
                      "Transportation Type",
                    )}
                  />
                </FlexBox>
              </FlexBox>

              {props.marketplaceId !== ALLOCATION_ID && (
                <FlexBox style={{ marginTop: "1rem" }} gutter={8}>
                  <FlexBox flex={true} direction="column">
                    <FormSelectField
                      name="ruleType"
                      autoWidth={true}
                      fullWidth={true}
                      options={RuleType}
                      formatOption={(x) =>
                        getLocalisationMessage(x) || formatText(x)
                      }
                      label={getLocalisationMessage("rule_type", "Rule Type")}
                      validate={createNotFalsyValidator(
                        getLocalisationMessage(
                          "select_rule_type",
                          "Select Rule Type",
                        ),
                      )}
                    />
                  </FlexBox>

                  {isOrderRule && (
                    <FlexBox flex={true} direction="column">
                      <FormSelectField
                        name="triggerType"
                        autoWidth={true}
                        fullWidth={true}
                        label={getLocalisationMessage(
                          "trigger_type",
                          "Trigger Type",
                        )}
                        options={triggerTypes}
                        formatOption={(x) =>
                          getLocalisationMessage(x) || formatText(x)
                        }
                      />
                    </FlexBox>
                  )}

                  {isOrderRule && values.ruleType === ZONING_RULE && (
                    <FlexBox flex={true} direction="column">
                      <FormTextField
                        name="prefix"
                        fullWidth={true}
                        label={`${getLocalisationMessage(
                          "prefix",
                          "Prefix",
                        )} *`}
                      />
                    </FlexBox>
                  )}

                  <FlexBox flex={true}>
                    {Boolean(values.ruleType === ORDER_RULE) && (
                      <FlexBox gutter={8} flex={true}>
                        <FlexBox flex={true} direction="column">
                          <FormSelectField
                            name="isSupplierOrDriver"
                            autoWidth={true}
                            fullWidth={true}
                            label={getLocalisationMessage(
                              "assign_to",
                              "Assign to ...",
                            )}
                            options={deliveryAllocationTypes}
                            formatOption={(x) =>
                              getLocalisationMessage(x) || formatText(x)
                            }
                          />
                        </FlexBox>
                        {values.isSupplierOrDriver ===
                          ALLOCATE_BY_PROXIMITY && (
                          <FlexBox flex={true} direction="column">
                            <FormSelectField
                              name="assignType"
                              autoWidth={true}
                              fullWidth={true}
                              label={getLocalisationMessage(
                                "assign_type",
                                "Assign Type",
                              )}
                              options={AllocateByDriverTypes}
                              formatOption={(x) =>
                                getLocalisationMessage(x) || formatText(x)
                              }
                            />
                          </FlexBox>
                        )}
                        {values.isSupplierOrDriver ===
                          ALLOCATE_BY_WAREHOUSE && (
                          <FlexBox flex={true}>
                            <FormWarehouseAutoComplete
                              name="warehouse"
                              fullWidth={true}
                              label={getLocalisationMessage("warehouse")}
                              hintText={getLocalisationMessage(
                                "type_to_search",
                                "Type to search ...",
                              )}
                            />
                          </FlexBox>
                        )}
                      </FlexBox>
                    )}
                    {Boolean(values.ruleType === PICKUP_JOB_AUTOMATION) && (
                      <FlexBox gutter={8}>
                        <FlexBox direction="column">
                          <FormSelectField
                            name="isSupplierOrDriver"
                            autoWidth={true}
                            fullWidth={true}
                            label={getLocalisationMessage(
                              "assign_to",
                              "Assign to ...",
                            )}
                            options={OrderRuleForPickupJobAutomation}
                            formatOption={(x) =>
                              getLocalisationMessage(x) || formatText(x)
                            }
                          />
                        </FlexBox>
                      </FlexBox>
                    )}
                    {values.ruleType === ORDER_TAG && (
                      <FormTagAutoComplete
                        name="tag"
                        label={getLocalisationMessage(
                          "select_tag",
                          "Select Tag",
                        )}
                        fullWidth={true}
                        hintText={getLocalisationMessage(
                          "type_to_search",
                          "Type to search ...",
                        )}
                      />
                    )}
                  </FlexBox>
                </FlexBox>
              )}
              {Boolean(values.ruleType === ORDER_RULE) && (
                <div>
                  {props.marketplaceId !== ALLOCATION_ID &&
                    values.isSupplierOrDriver === ALLOCATE_BY_PROXIMITY && (
                      <FlexBox gutter={8}>
                        <FlexBox
                          flex={true}
                          className={props.classes.infoIconParent}
                        >
                          <FormTextField
                            name="waitTime"
                            fullWidth={true}
                            parseOnBlur={parseInteger}
                            label={`${getLocalisationMessage(
                              "wait_time",
                              "Wait Time",
                            )}`}
                          />
                          <TooltipOverlay
                            label={getLocalisationMessage(
                              "minimum_wait_time_is_2_minutes",
                              "Minimum Wait Time is 2 minutes",
                            )}
                            style={styles.infoTooltip}
                          >
                            <IconButton aria-label="delete">
                              <InfoIcon className={props.classes.infoIcon} />
                            </IconButton>
                          </TooltipOverlay>
                        </FlexBox>
                        <FlexBox
                          flex={true}
                          className={props.classes.infoIconParent}
                        >
                          <FormTextField
                            name="numberOfRetries"
                            fullWidth={true}
                            parseOnBlur={parseInteger}
                            label={`${getLocalisationMessage(
                              "number_of_retries",
                              "Number Of Retries",
                            )} *`}
                          />
                          <TooltipOverlay
                            label={getLocalisationMessage(
                              "number_of_retries_should_be_set_1-15",
                              "Number of Retries should be set 1-15",
                            )}
                            style={styles.infoTooltip}
                          >
                            <IconButton aria-label="delete">
                              <InfoIcon className={props.classes.infoIcon} />
                            </IconButton>
                          </TooltipOverlay>
                        </FlexBox>
                        <FlexBox
                          flex={true}
                          className={props.classes.infoIconParent}
                        >
                          <FormTextField
                            name="suggestRadius"
                            fullWidth={true}
                            parseOnBlur={parseInteger}
                            label={`${getLocalisationMessage(
                              "suggest_radius",
                              "Suggest Radius",
                            )} *`}
                          />
                          <TooltipOverlay
                            label={getLocalisationMessage(
                              "preferable_radius_is_5_km",
                              "Preferable Radius is 5 KM",
                            )}
                            style={styles.infoTooltip}
                          >
                            <IconButton aria-label="delete">
                              <InfoIcon className={props.classes.infoIcon} />
                            </IconButton>
                          </TooltipOverlay>
                        </FlexBox>
                        <FlexBox
                          flex={true}
                          className={props.classes.infoIconParent}
                        >
                          <FormTextField
                            name="driverMaxOrders"
                            fullWidth={true}
                            parseOnBlur={parseInteger}
                            label={`${getLocalisationMessage(
                              "driver_max_orders",
                              "Driver Max Orders",
                            )} *`}
                          />
                          <TooltipOverlay
                            label={getLocalisationMessage(
                              "set_maximum_number_of_orders_which_can_be_assigned_to_driver",
                              "Set maximum number of orders which can be assigned to driver",
                            )}
                            style={styles.infoTooltip}
                          >
                            <IconButton aria-label="delete">
                              <InfoIcon className={props.classes.infoIcon} />
                            </IconButton>
                          </TooltipOverlay>
                        </FlexBox>
                      </FlexBox>
                    )}
                </div>
              )}

              {Boolean(
                values.ruleType === ORDER_RULE ||
                  values.ruleType === PICKUP_JOB_AUTOMATION,
              ) && (
                <div>
                  {values.isSupplierOrDriver === ASSIGN_TO_SUPPLIER ||
                  values.isSupplierOrDriver === ALLOCATE_BY_PROXIMITY ? (
                    <FieldArray
                      name="suppliers"
                      props={{
                        classes: props.classes,
                        supplierItems: values,
                      }}
                      component={OrderRuleSuppliers}
                      getLocalisationMessage={getLocalisationMessage}
                    />
                  ) : values.isSupplierOrDriver === ASSIGN_TO_DRIVER ? (
                    <FieldArray
                      name="drivers"
                      component={OrderRuleDrivers}
                      getLocalisationMessage={getLocalisationMessage}
                    />
                  ) : null}
                </div>
              )}
            </CardContent>
          </Card>
        </FlexBox>

        {Boolean(values.ruleType) && (
          <FlexBox
            flex="none"
            direction="column"
            style={{ paddingBottom: "128px", marginLeft: "-1rem" }}
          >
            {isOrderRule ? (
              <OrderRuleTreeBranch root={true} name="orderRules" />
            ) : (
              <OrderRuleTreeBranch root={true} name="supplierRules" />
            )}
          </FlexBox>
        )}
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(OrderRuleTree);
