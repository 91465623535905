import React from "react";
import { isDate, endOfDay, isThisMonth, startOfMonth } from "date-fns";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withHandlers } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, FieldArray } from "redux-form";
import { Card, CardContent, TextField, Button } from "@material-ui/core";
import { connect } from "react-redux";
import FormDateField from "../form/FormDateField";
import FormTextField from "../form/FormTextField";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { pureComponent } from "../../helpers/HOCUtils";
import { formatText, parseInteger } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const getTotalQuota = fp.reduce(
  (acc, x) => fp.toInteger(x.count) * fp.toInteger(x.quota) + acc,
  0,
);
const validateQuota = x => (fp.isFinite(x) && x > 0 ? null : "Invalid Value");

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);
    return {
      getLocalisationMessage,
    };
  }),
  useSheet({ actions: { textAlign: "right" } }),
  withHandlers({
    onSubmit: props => values =>
      props.onSubmit({
        ...values,
        totalQuota: getTotalQuota(values.vehiclesQuota),
      }),
  }),
  reduxForm({
    enableReinitialize: true,
    form: "SupplierItemMonthlyQuotaForm",
    validate: (values, props) => ({
      applyFrom:
        Boolean(!isDate(values.applyFrom) || !isThisMonth(values.applyFrom)) &&
        props.getLocalisationMessage(
          "invalid_application_date",
          "Invalid Application Date",
        ),
    }),
  }),
  pureComponent(fp.pick(["submitting"])),
);

VehiclesQuota.propTypes = {
  fields: PropTypes.object,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function VehiclesQuota(props) {
  const { getLocalisationMessage } = props;
  return (
    <FlexBox direction="column">
      {props.fields.map((name, index) => {
        const value = props.fields.get(index);
        const count = fp.toInteger(value.count);

        return (
          <FlexBox gutter={8} key={index}>
            <FlexBox flex={true} direction="column">
              <TextField
                tabIndex={-1}
                readOnly={true}
                fullWidth={true}
                label={getLocalisationMessage("vehicle_type", "Vehicle Type")}
                value={formatText(value.vehicleType)}
              />
            </FlexBox>

            <FlexBox flex={true} direction="column">
              <TextField
                value={count}
                tabIndex={-1}
                readOnly={true}
                fullWidth={true}
                label={getLocalisationMessage("count", "Count")}
              />
            </FlexBox>

            <FlexBox flex={true} direction="column">
              <FormTextField
                fullWidth={true}
                name={`${name}.quota`}
                validate={validateQuota}
                parseOnBlur={parseInteger}
                label={getLocalisationMessage(
                  "quota_per_driver",
                  "Quota Per Driver",
                )}
              />
            </FlexBox>

            <FlexBox flex={true} direction="column">
              <TextField
                tabIndex={-1}
                readOnly={true}
                fullWidth={true}
                label={getLocalisationMessage("subtotal", "Subtotal")}
                value={count * fp.toInteger(value.quota)}
              />
            </FlexBox>
          </FlexBox>
        );
      })}
    </FlexBox>
  );
}

TotalQuota.propTypes = {
  fields: PropTypes.object,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function TotalQuota(props) {
  const { getLocalisationMessage } = props;
  const totalQuota = getTotalQuota(props.fields.getAll());

  return (
    <TextField
      readOnly={true}
      fullWidth={true}
      value={totalQuota}
      label={getLocalisationMessage("total_quota", "Total Quota")}
    />
  );
}

SupplierItemMonthlyQuotaForm.propTypes = {
  classes: PropTypes.object,

  reset: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  totalQuota: PropTypes.number,

  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function SupplierItemMonthlyQuotaForm(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <Card>
      <PageLoading isLoading={props.submitting} />

      <CardContent>
        <form autoComplete="off" onSubmit={props.handleSubmit}>
          <FieldArray
            props={props}
            name="vehiclesQuota"
            component={VehiclesQuota}
          />

          <FlexBox gutter={8} justify="flex-end">
            <FlexBox flex={3} direction="column">
              <FormDateField
                name="applyFrom"
                fullWidth={true}
                label={getLocalisationMessage("apply_from", "Apply From")}
                minDate={startOfMonth(new Date())}
                maxDate={endOfDay(new Date())}
              />
            </FlexBox>

            <FlexBox flex={3} direction="column">
              <FieldArray
                props={props}
                name="vehiclesQuota"
                component={TotalQuota}
              />
            </FlexBox>
          </FlexBox>

          <div className={classes.actions}>
            <Button onClick={props.reset}>
              {getLocalisationMessage("reset", "Reset")}
            </Button>

            <Button type="submit">
              {getLocalisationMessage("submit", "Submit")}
            </Button>
          </div>
        </form>
      </CardContent>
    </Card>
  );
}

export default enhancer(SupplierItemMonthlyQuotaForm);
