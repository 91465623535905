import { Observable } from "rxjs";
import React from "react";
import fp from "lodash/fp";
import { componentFromStream } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { AutoCompleteComponent } from "./FormAutoComplete";
import { isEqualData, isEqualDataIn } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { ACTIVE } from "../../constants/OverallStatus";
import {
  getCachedPostcode,
  getPostcodePredictions,
} from "../../api/admin/AdminPostcodesApi";

const baseFilter = new DataListFilter({ page: 0, size: 20, status: ACTIVE });

const parseInput = (name) => ({ name });
const formatOption = fp.get("name");

const PostcodeIndexAutoComplete = componentFromStream((propsStream) => {
  const valueStream = propsStream
    .distinctUntilChanged(isEqualDataIn("input.value"))
    .switchMap(({ input: { value } }) =>
      value && value.id && !value.name
        ? getCachedPostcode(value.id)
            .map((item) => ({ id: item.get("id"), name: item.get("index") }))
            .catch(() => Observable.of(value))
            .startWith(value)
        : Observable.of(value),
    );

  const optionsStream = propsStream
    .distinctUntilChanged(isEqualDataIn("input.value.name"))
    .switchMap(({ input: { value } }) =>
      getPostcodePredictions(baseFilter.setSearch(value && value.name))
        .map(fp.flow(fp.get("payload.data"), fp.toArray))
        .catch(() => Observable.of([])),
    )
    .distinctUntilChanged(isEqualData)
    .startWith([]);

  return propsStream
    .map(fp.omit(["getCachedPostcode", "getPostcodePredictions"]))
    .combineLatest(valueStream, optionsStream, (props, value, options) => (
      <AutoCompleteComponent
        {...props}
        options={options}
        filter={fp.stubTrue}
        parseInput={parseInput}
        formatOption={formatOption}
        input={{ ...props.input, value }}
        fullWidth={true}
      />
    ));
});

FormPostcodeIndexAutoComplete.propTypes = {
  name: PropTypes.string.isRequired,

  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  openOnFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  maxSearchResults: PropTypes.number,

  validate: PropTypes.func,

  hintText: PropTypes.node,
  label: PropTypes.node,
};

FormPostcodeIndexAutoComplete.defaultProps = {
  maxSearchResults: 10,
};

export default function FormPostcodeIndexAutoComplete(props) {
  return <Field {...props} component={PostcodeIndexAutoComplete} />;
}
