import { Observable } from "rxjs";
import React from "react";
import { fromJS, List, Map } from "immutable";
import fp from "lodash/fp";
import { compose, createEventHandler, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { RTO } from "../../constants/OrderChargeItemTypes";
import { getOrder, updateOrderCharge } from "../../api/admin/AdminOrderApi";
import OrderChargeEditDialog from "../../components/orders-core/OrderChargeEditDialog";

const enhancer = compose(
  renderIf("open"),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const chargesListResponseStream = propsStream
      .distinctUntilKeyChanged("orderId")
      .switchMap(props =>
        getOrder(props.orderId)
          .repeatWhen(() => onRequestRefreshStream)
          .catch(error => Observable.of({ error })),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update(
            "payload",
            fp.flow(fp.get("data.charge_items"), fp.toArray),
          ),
          fromJS,
        ),
      );

    return propsStream
      .combineLatest(chargesListResponseStream, (props, chargesList) => ({
        ...props,
        onRequestRefresh,
        isLoading: chargesList.get("pending"),
        charges: chargesList.get("payload"),
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

AdminOrderRTOEditDialogWrapper.propTypes = {
  orderId: PropTypes.number.isRequired,
  charges: PropTypes.instanceOf(List),
  isLoading: PropTypes.bool,
  onRequestRefresh: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired,
  messages: PropTypes.instanceOf(Map),
};

function AdminOrderRTOEditDialogWrapper(props) {
  return (
    <OrderChargeEditDialog
      messages={props.messages}
      open={true}
      charges={props.charges}
      isLoading={props.isLoading}
      chargeTypes={[RTO]}
      onSubmit={({ type, paid, charge }) => {
        const chargeItem = props.charges.find(
          item => item.get("charge_type") === type,
        );

        return updateOrderCharge(
          props.orderId,
          chargeItem.merge({ paid, charge }).toJS(),
        );
      }}
      onRequestClose={props.onRequestClose}
      onSubmitSuccess={props.onRequestRefresh}
    />
  );
}

export default enhancer(AdminOrderRTOEditDialogWrapper);
