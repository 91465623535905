import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import ModalPaper from "../../components/ui-core/ModalPaper";
import CustomerFilterForm from "../../components/customer/CustomerFilterForm";

const enhancer = compose(
  renderIf("open"),
  useSheet({
    paper: {
      minWidth: "400px",
      maxWidth: "500px",
      minHeight: "auto",
    },
  }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);

AdminCustomerFilterDialogWrapper.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminCustomerFilterDialogWrapper(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <ModalPaper
      open={true}
      title={getLocalisationMessage("filter", "Filter")}
      paperClassName={classes.paper}
      autoScrollBodyContent={false}
      onRequestClose={props.onRequestClose}
    >
      <CustomerFilterForm
        filter={props.filter}
        onFilterChange={props.onFilterChange}
        onDismiss={props.onRequestClose}
      />
    </ModalPaper>
  );
}

export default enhancer(AdminCustomerFilterDialogWrapper);
