import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, withState, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEqualData, isEqualWithoutFunctions } from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  doNotSentAllEvents,
  getCommunicationMessage,
  saveCommunicationMessage,
  deleteCommunicationMessage,
  getCommunicationEventsList,
} from "../../api/shared/CommunicationApi";
import FormDialog from "../../components/form/FormDialog";
import FlexBox from "../../components/ui-core/FlexBox";
import PageLoading from "../../components/ui-core/PageLoading";
import CommunicationSettingsForm from "../../components/communication-settings/CommunicationSettingsForm";
import CommunicationSettingsTable, {
  CUSTOMER,
  BY_EVENTS,
} from "../../components/communication-settings/CommunicationSettingsTable";

const enhancer = compose(
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    setLocation: PropTypes.func.isRequired,
  }),
  withState("state", "setState", { doNotSentAllLoading: false }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showSuccessMessage, showErrorMessage },
  ),
  mapPropsStream(propsStream => {
    const customerIdStream = propsStream
      .pluck("params", "customerId")
      .filter(fp.toFinite)
      .distinctUntilChanged(isEqualData);

    const createInitialValuesStream = propsStream
      .pluck("location", "query")
      .filter(filter => filter.communicationItem === "create")
      .map(
        fp.flow(
          fp.pick([
            "type",
            "target",
            "messageType",
            "eventCourierType",
            "toJurisdictionId",
            "transportationType",
            "notificationWarehouseType",
          ]),
          fp.update("toJurisdictionId", fp.toFinite),
          fp.set("duplicateOfPush", false),
        ),
      )
      .distinctUntilChanged(isEqualData)
      .startWith(null);

    const communicationItemResponseStream = propsStream
      .pluck("location", "query", "communicationItem")
      .distinctUntilChanged(isEqualData)
      .filter(fp.toFinite)
      .switchMap(id =>
        getCommunicationMessage(id).catch(error => Observable.of({ error })),
      )
      .startWith({})
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              item: response.getIn(["payload", "data"], Map()),
              isLoading: response.get("pending", false),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        customerIdStream,
        createInitialValuesStream,
        communicationItemResponseStream,
        customerIdStream,
        (
          props,
          customerId,
          createInitialValues,
          communicationItemResponse,
        ) => ({
          ...props,
          customerId,
          createInitialValues,
          item: communicationItemResponse.get("item"),
          isLoading: communicationItemResponse.get("isLoading"),
        }),
      )
      .distinctUntilChanged(isEqualWithoutFunctions);
  }),
);

AdminCustomerItemCommunicationContainer.propTypes = {
  location: PropTypes.object.isRequired,

  customerIsLoading: PropTypes.bool,
  setLocationQuery: PropTypes.func,

  createInitialValues: PropTypes.object,
  item: PropTypes.instanceOf(Map),
  isLoading: PropTypes.bool,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,

  customerId: PropTypes.string,

  state: PropTypes.object,
  setState: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function AdminCustomerItemCommunicationContainer(props) {
  const { location, state, getLocalisationMessage } = props;

  return (
    <FlexBox direction="column" flex={true}>
      <PageLoading isLoading={state.doNotSentAllLoading} />

      <FormDialog
        open={Boolean(location.query.removeId)}
        onRequestClose={() => props.setLocationQuery(fp.unset("removeId"))}
        onSubmitFail={props.showErrorMessage}
        onSubmit={() =>
          deleteCommunicationMessage(location.query.communicationItem).catch(
            ResponseError.throw,
          )
        }
        onSubmitSuccess={() => {
          props.setLocationQuery(fp.omit(["communicationItem", "removeId"]));
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_removed",
              "Successfully Removed",
            ),
          );
        }}
      >
        {getLocalisationMessage(
          "are_you_sure_you_want_to_remove_this_message",
          "Are You Sure You Want To Remove This Message",
        )}
      </FormDialog>

      {!location.query.communicationItem && (
        <CommunicationSettingsTable
          customerIsLoading={props.customerIsLoading}
          entityType={CUSTOMER}
          doNotSentAllEvents={() => {
            props.setState(fp.set("doNotSentAllLoading", true));

            doNotSentAllEvents(props.customerId)
              .then(() => {
                props.setState(fp.set("doNotSentAllLoading", false));
                props.showSuccessMessage(
                  getLocalisationMessage(
                    "it_might_take_some_time_for_change_to_reflect",
                    "It Might Take Some Time For Change To Reflect",
                  ),
                );
              })
              .catch(error => {
                props.setState(fp.set("doNotSentAllLoading", false));
                props.showErrorMessage(error);
              });
          }}
          onChangeFilter={(type, value) =>
            type === "courier"
              ? props.setLocationQuery(fp.set("eventCourierType", value))
              : type === "notificationWarehouseType"
              ? props.setLocationQuery(
                  fp.set("notificationWarehouseType", value),
                )
              : type === "toJurisdictionId"
              ? props.setLocationQuery(fp.set("toJurisdictionId", value))
              : props.setLocationQuery(fp.set("transportationType", value))
          }
          getCommunicationList={getCommunicationEventsList}
          location={props.location}
          entityId={props.customerId}
          onFilterChange={data => props.setLocationQuery({ filterType: data })}
          onItemClick={id =>
            props.setLocationQuery(fp.set("communicationItem", id))
          }
          onCreateClick={query =>
            props.setLocationQuery(currentQuery => ({
              ...currentQuery,
              ...query,
              communicationItem: "create",
            }))
          }
          onEventChange={(filterType, data) =>
            filterType === BY_EVENTS
              ? props.setLocationQuery(fp.set("eventType", data))
              : props.setLocationQuery(fp.set("eventTarget", data))
          }
        />
      )}

      {location.query.communicationItem > 0 && (
        <CommunicationSettingsForm
          initialValues={toCamelCase(props.item)}
          isLoading={props.isLoading}
          breadcrumbTitle={getLocalisationMessage(
            "edit_message",
            "Edit Message",
          )}
          onBackClick={() =>
            props.setLocationQuery(fp.unset("communicationItem"))
          }
          onDeleteClick={() =>
            props.setLocationQuery(fp.set("removeId", props.item.get("id")))
          }
          onSubmitFail={props.showErrorMessage}
          onSubmitSuccess={() =>
            props.showSuccessMessage(
              getLocalisationMessage(
                "successfully_saved",
                "Successfully Saved",
              ),
            )
          }
          onSubmit={values =>
            saveCommunicationMessage(toSnakeCase(values)).catch(
              ResponseError.throw,
            )
          }
        />
      )}

      {location.query.communicationItem === "create" && (
        <CommunicationSettingsForm
          initialValues={props.createInitialValues}
          onSubmitFail={props.showErrorMessage}
          breadcrumbTitle={getLocalisationMessage("create_message")}
          onSubmitSuccess={response => {
            const id = fp.get("data.id")(response);

            props.showSuccessMessage(
              getLocalisationMessage("successfully_saved"),
            );

            if (id) {
              props.setLocationQuery(
                fp.flow(
                  fp.omit(["type", "target", "messageType"]),
                  fp.set("communicationItem", id),
                ),
              );
            }
          }}
          onSubmit={values =>
            saveCommunicationMessage(
              toSnakeCase({
                ...values,
                entityType: CUSTOMER,
                entityID: props.customerId,
              }),
            ).catch(ResponseError.throw)
          }
        />
      )}
    </FlexBox>
  );
}

export default enhancer(AdminCustomerItemCommunicationContainer);
