import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import Popover from "react-bootstrap/es/Popover";
import OverlayTrigger from "react-bootstrap/es/OverlayTrigger";

const enhancer = compose(
  useSheet({
    content: { fontSize: "12px" },
    popover: {
      position: "absolute",
    },
    header: {
      paddingBottom: 0,
      "& > div": { paddingRight: 0 },
    },
    element: {
      cursor: "pointer",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  }),
);

PopoverOverlay.propTypes = {
  classes: PropTypes.object,
  style: PropTypes.object,
  placement: PropTypes.string,
  header: PropTypes.string,
  content: PropTypes.any,
  width: PropTypes.number,
  children: PropTypes.node.isRequired,
};

PopoverOverlay.defaultProps = {
  placement: "bottom",
  width: 250,
};

function PopoverOverlay(props) {
  const { classes } = props;

  return (
    <OverlayTrigger
      delayShow={200}
      trigger={["hover", "focus"]}
      placement={props.placement}
      overlay={
        <Popover
          id="popover-trigger-focus"
          style={{ maxWidth: `${props.width}px`, zIndex: 10 }}
          className={classes.popover}
        >
          <Card>
            {props.header && (
              <CardHeader className={classes.header}>{props.header}</CardHeader>
            )}

            <CardContent className={classes.content}>
              {props.content}
            </CardContent>
          </Card>
        </Popover>
      }
    >
      {!React.isValidElement(props.children)
        ? props.children
        : React.cloneElement(props.children, {
            style: props.style,
            className: classes.element,
          })}
    </OverlayTrigger>
  );
}

export default enhancer(PopoverOverlay);
