import { Observable } from "rxjs";
import React from "react";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  withContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import FormCloneRules from "../form/FormCloneRules";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import {
  getCachedCustomer,
  getCustomerPredictions,
} from "../../api/admin/AdminCustomerApi";
import { getRuleCloneCustomers } from "../../api/admin/AdminOrderRulesApi";
import useSheet from "react-jss";

const enhancer = compose(
  renderIf(props => props.ruleId > 0),
  reduxForm({ form: "FormDialog" }),
  withContext(
    {
      getCachedCustomer: PropTypes.func,
      getCustomerPredictions: PropTypes.func,
    },
    props => ({
      getCachedCustomer: props.getCachedCustomer,
      getCustomerPredictions: props.getCustomerPredictions,
    }),
  ),
  useSheet({ content: { maxWidth: "320px" } }),
  mapPropsStream(propsStream => {
    const {
      stream: onRuleCustomersRequestRefreshStream,
    } = createEventHandler();

    const ruleCustomersResponseStream = propsStream
      .distinctUntilKeyChanged("ruleId", isEqualData)
      .switchMap(props =>
        getRuleCloneCustomers(props.ruleId)
          .repeatWhen(() => onRuleCustomersRequestRefreshStream)
          .catch(error => Observable.of({ error })),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update(
            "payload",
            fp.flow(fp.get("data"), fp.toArray, x => {
              const ruleCustomers = [];
              x.forEach(ind => {
                ruleCustomers.push({
                  oldCustomer: {
                    id: ind,
                  },
                  newCustomer: {
                    id: ind,
                  },
                });
              });
              return ruleCustomers;
            }),
          ),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        ruleCustomersResponseStream,
        (props, ruleCustomersResponse) => ({
          ...props,
          ruleCustomers: ruleCustomersResponse.get("payload").toJS(),
          isLoading: ruleCustomersResponse.get("pending"),
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

CloneRulesDialog.propTypes = {
  title: PropTypes.node,
  ruleId: PropTypes.number.isRequired,
  onRequestClose: PropTypes.func,
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  ruleCustomers: PropTypes.object,
  isLoading: PropTypes.bool,
};

function CloneRulesDialog(props) {
  return (
    <Dialog
      open={props.ruleId > 0}
      PaperProps={{
        style: {
          width: 800,
        },
      }}
      className={props.className}
      onClose={props.onRequestClose}
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <PageLoading isLoading={props.isLoading} />

        <FormCloneRules
          getCachedCustomer={getCachedCustomer}
          getCustomerPredictions={getCustomerPredictions}
          initialValues={{ ruleCustomers: props.ruleCustomers }}
          onDismiss={props.onRequestClose}
          onSubmit={props.onSubmit}
          onSubmitSuccess={props.onSubmitSuccess}
          onSubmitFail={props.onSubmitFail}
        />
      </DialogContent>
    </Dialog>
  );
}

export default enhancer(CloneRulesDialog);
