import React from "react";
import { fromJS } from "immutable";
import { getContext } from "recompose";
import PropTypes from "prop-types";
import FormAbstractChips from "./FormAbstractChips";
import DataListFilter from "../../helpers/DataListFilter";
import { ACTIVE } from "../../constants/OverallStatus";

const baseFilter = new DataListFilter({ page: 0, size: 20, status: ACTIVE });
const enhancer = getContext({
  getCachedRule: PropTypes.func.isRequired,
  getChildRulePredictions: PropTypes.func.isRequired,
});

FormRulesChips.propTypes = {
  getCachedRule: PropTypes.func,
  getChildRulePredictions: PropTypes.func,

  fullWidth: PropTypes.bool,
  autoWidth: PropTypes.bool,
  canAutoPosition: PropTypes.bool,

  maxHeight: PropTypes.number,

  openOnFocus: PropTypes.bool,
  clearOnBlur: PropTypes.bool,
  maxItems: PropTypes.number,
  maxSearchResults: PropTypes.number,

  onGetValue: PropTypes.func,

  validate: PropTypes.func,

  hintText: PropTypes.node,
  label: PropTypes.node,

  name: PropTypes.string.isRequired,
  supplierId: PropTypes.number.isRequired,
};

function FormRulesChips({
  getCachedRule,
  getChildRulePredictions,
  supplierId,
  ...props
}) {
  return (
    <FormAbstractChips
      {...props}
      getValue={value =>
        getCachedRule(value).map(item =>
          fromJS({ id: item.get("id"), name: item.get("name") }),
        )
      }
      getPredictions={searchText =>
        getChildRulePredictions(
          baseFilter.setValue("supplierId", supplierId).setSearch(searchText),
        )
      }
    />
  );
}

export default enhancer(FormRulesChips);
