import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withContext, withState } from "recompose";
import PropTypes from "prop-types";
import { FieldArray, reduxForm } from "redux-form";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ListItemText,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FormRuleAutoComplete, { ORDER_RULE } from "../form/FormRuleAutoComplete";
import Text, { DANGER, SUCCESS } from "../ui-core/Text";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { pureComponent } from "../../helpers/HOCUtils";
import {
  createNotEmptyValidator,
  createObjectIdValidator,
} from "../../helpers/FormUtils";
import { formatText } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { ORDER_RULE_ITEM_URL } from "../../constants/AdminPathConstants";
import UpperOverallStatus, {
  ACTIVE,
  INACTIVE,
} from "../../constants/UpperOverallStatus";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { KeyboardArrowDown } from "@material-ui/icons";

const GroupStatus = ({ status, rules, getLocalisationMessage }) =>
  status === ACTIVE ? (
    <span>
      <Text type={SUCCESS}>
        {formatText(getLocalisationMessage(status, status))}{" "}
      </Text>
      {getLocalisationMessage("with", "with")} {fp.size(rules)}{" "}
      {getLocalisationMessage("rules", "Rules")}
    </span>
  ) : status === INACTIVE ? (
    <span>
      <Text type={DANGER}>{formatText(status)} </Text>
      {getLocalisationMessage("with", "with")} {fp.size(rules)}{" "}
      {getLocalisationMessage("rules", "Rules")}
    </span>
  ) : (
    <span>
      {fp.size(rules)} {getLocalisationMessage("rules", "Rules")}
    </span>
  );

GroupStatus.propTypes = {
  status: PropTypes.string,
  rules: PropTypes.array,
  getLocalisationMessage: PropTypes.func,
};

const Rules = props => (
  <FlexBox gutter={8} flex={true} direction="column">
    <FlexBox flex={true} direction="column" className={props.classes.rulesList}>
      {props.fields.map((rule, index, allFields) => (
        <FlexBox gutter={8} flex="none" key={index}>
          <FlexBox flex={true} direction="column">
            <FormRuleAutoComplete
              name={rule}
              ruleType={ORDER_RULE}
              fullWidth={true}
              label={`${props.getLocalisationMessage("rule", "Rule")} *`}
              validate={createObjectIdValidator(
                props.getLocalisationMessage("select_rule", "Select Rule"),
              )}
              hintText={props.getLocalisationMessage(
                "type_to_search",
                "Type To Search...",
              )}
            />
          </FlexBox>

          {allFields.get(index).id > 0 && (
            <FlexBox direction="column" justify="center">
              <Button
                primary={true}
                component="a"
                target="_blank"
                href={updateQuery(
                  ORDER_RULE_ITEM_URL + allFields.get(index).id,
                )}
              >
                {props.getLocalisationMessage("edit_rules", "Edit rules")}
              </Button>
            </FlexBox>
          )}

          <FlexBox direction="column" justify="center">
            <Button onClick={() => props.fields.remove(index)}>
              {props.getLocalisationMessage("remove", "Remove")}
            </Button>
          </FlexBox>
        </FlexBox>
      ))}
    </FlexBox>
    <FlexBox>
      <Button onClick={() => props.fields.push({})}>
        {props.getLocalisationMessage("add_rule", "Add Rule")}
      </Button>
    </FlexBox>
  </FlexBox>
);

Rules.propTypes = {
  fields: PropTypes.object,
  classes: PropTypes.object,
  getLocalisationMessage: PropTypes.func,
};

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  useSheet({
    card: {
      "& > div": { display: "flex", flexDirection: "column", flex: "1 1 0%" },
    },
    rulesList: {
      padding: "14px 4px",
    },
  }),
  withState("state", "setState", {}),
  withContext(
    {
      getCachedRule: PropTypes.func,
      getRulePredictions: PropTypes.func,
    },
    props => ({
      getCachedRule: props.getCachedRule,
      getRulePredictions: props.getRulePredictions,
    }),
  ),
  reduxForm({
    form: "RulesGroupForm",
    enableReinitialize: true,
  }),
  pureComponent(
    fp.pick([
      "dirty",
      "showClearButton",
      "submitting",
      "initialValues",
      "state",
      "isItem",
    ]),
  ),
);

RulesGroupForm.propTypes = {
  classes: PropTypes.object,

  handleSubmit: PropTypes.func,

  getCachedRule: PropTypes.func.isRequired,
  getRulePredictions: PropTypes.func.isRequired,

  onDismiss: PropTypes.func,

  initialValues: PropTypes.object,
  change: PropTypes.func,
  dirty: PropTypes.bool,
  reset: PropTypes.func,

  setState: PropTypes.func,
  state: PropTypes.object,

  submitting: PropTypes.bool,

  isItem: PropTypes.bool,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function RulesGroupForm(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <FlexBox
      container={8}
      flex={true}
      element={<form onSubmit={props.handleSubmit} />}
    >
      <PageLoading isLoading={props.submitting} />

      <Accordion
        expandIcon={<KeyboardArrowDown />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <AccordionSummary expandIcon={<KeyboardArrowDown />}>
          <ListItemText
            primary={props.initialValues.name}
            secondary={
              <GroupStatus
                status={props.initialValues.ruleGroupStatus}
                rules={props.initialValues.rules}
                getLocalisationMessage={props.getLocalisationMessage}
              />
            }
          />
        </AccordionSummary>
        <AccordionDetails
          style={{
            minWidth: 992,
          }}
        >
          <FlexBox flex={true} direction="column">
            <FlexBox gutter={8} flex={true} direction="column">
              <FlexBox>
                <FlexBox gutter={8} flex={true}>
                  <FlexBox flex={true}>
                    <FormTextField
                      name="name"
                      fullWidth={true}
                      validate={createNotEmptyValidator(
                        getLocalisationMessage(
                          "provide_group_name",
                          "Provide Group Name",
                        ),
                      )}
                      label={`${getLocalisationMessage("name")} *`}
                    />
                  </FlexBox>

                  <FlexBox flex={true}>
                    <FormSelectField
                      name="ruleGroupStatus"
                      fullWidth={true}
                      label={getLocalisationMessage("status")}
                      options={UpperOverallStatus}
                      formatOption={value =>
                        formatText(getLocalisationMessage(value, value))
                      }
                    />
                  </FlexBox>
                </FlexBox>
              </FlexBox>

              <FlexBox flex={true}>
                <FieldArray
                  name="rules"
                  component={Rules}
                  props={{
                    classes,
                    getLocalisationMessage: props.getLocalisationMessage,
                  }}
                />
              </FlexBox>
            </FlexBox>

            <FlexBox flex={true} justify="flex-end">
              {!props.isItem && (
                <Button
                  onClick={() => {
                    fp.keys(props.initialValues).forEach(key => {
                      props.change(key, null);
                    });
                  }}
                >
                  {getLocalisationMessage("clear", "Clear")}
                </Button>
              )}

              {props.dirty ? (
                <Button onClick={props.reset}>
                  {getLocalisationMessage("reset", "Reset")}
                </Button>
              ) : (
                Boolean(props.onDismiss) && (
                  <Button onClick={props.onDismiss}>
                    {getLocalisationMessage("dismiss", "Dismiss")}
                  </Button>
                )
              )}

              <Button primary={true} type="submit">
                {getLocalisationMessage("submit", "Submit")}
              </Button>
            </FlexBox>
          </FlexBox>
        </AccordionDetails>
      </Accordion>
    </FlexBox>
  );
}

export default enhancer(RulesGroupForm);
