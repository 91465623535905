import React from "react";
import { Map, OrderedSet } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream, withContext, withHandlers } from "recompose";
import PropTypes from "prop-types";
import { formValueSelector, reduxForm } from "redux-form";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FormCustomerAutoComplete from "../form/FormCustomerAutoComplete";
import FormReferenceAutoComplete from "../form/FormReferenceAutoComplete";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { getValue, isEqualData } from "../../helpers/DataUtils";
import { getObjectId } from "../../helpers/FormUtils";
import { toCamelCase } from "../../helpers/CaseMapper";
import {
  formatText,
  parseFloat,
  parseInteger,
} from "../../helpers/FormatUtils";
import { validateEmail, validateString } from "../../helpers/ValidateUtils";
import { getIsRTL, getMessage } from "../../reducers/LocalizationReducer";
import { ACTIVE, INACTIVE } from "../../constants/OverallStatus";

const valueSelector = formValueSelector("SupplierForm");

const Statuses = OrderedSet([ACTIVE, INACTIVE]);

const enhancer = compose(
  useSheet({ subheader: { padding: 0 } }),
  withContext(
    {
      getCachedReference: PropTypes.func.isRequired,
      getReferencePredictions: PropTypes.func.isRequired,
      getCachedCustomer: PropTypes.func,
      getCustomerPredictions: PropTypes.func,
    },
    (props) => ({
      getCachedReference: props.getCachedReference,
      getReferencePredictions: props.getReferencePredictions,
      getCachedCustomer: props.getCachedCustomer,
      getCustomerPredictions: props.getCustomerPredictions,
    }),
  ),
  mapPropsStream((propsStream) => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("item", isEqualData)
      .filter((props) => props.item && props.item.get("id"))
      .map((props) => {
        const { item } = props;
        const values = item.asMutable();
        values.set("default_customer_id", {
          id: item.get("default_customer_id"),
        });
        return toCamelCase(values.asImmutable());
      })
      .startWith(null)
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,

        initialValues,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    isRTL: getIsRTL(state),
  })),
  withHandlers({
    onSubmit: (props) => (values) => {
      if (!props.onSubmit) {
        return null;
      }

      return props.onSubmit({
        ...values,
        default_customer_id: getObjectId(values.defaultCustomerId),
      });
    },
  }),
  reduxForm({
    form: "SupplierForm",
    enableReinitialize: true,
    validate: (values, props) => ({
      code: validateString(values.code)
        ? props.getLocalisationMessage("enter_code", "Enter code")
        : null,
      contactEmail: validateEmail(values.contactEmail)
        ? props.getLocalisationMessage("enter_valid_email", "Enter valid Email")
        : null,
      supplierEmail: validateEmail(values.supplierEmail)
        ? props.getLocalisationMessage("enter_valid_email", "Enter valid Email")
        : null,
      name: validateString(values.name)
        ? props.getLocalisationMessage(
            "enter_courier_name",
            "Enter Courier Name",
          )
        : null,
    }),
  }),
  connect((state) => ({
    values: valueSelector(state, "shippingAddress", "billingAddress", "status"),
  })),
);

SupplierForm.propTypes = {
  values: PropTypes.object,
  dirty: PropTypes.bool,
  reset: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  onDismiss: PropTypes.func,
  item: PropTypes.instanceOf(Map),
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,

  getCachedReference: PropTypes.func.isRequired,
  getReferencePredictions: PropTypes.func.isRequired,

  getLocalisationMessage: PropTypes.func,
  isRTL: PropTypes.bool,
};

function SupplierForm(props) {
  const { values, getLocalisationMessage, isRTL } = props;

  return (
    <form>
      <PageLoading isLoading={props.submitting} />

      <FlexBox container={8} direction="column">
        <FlexBox gutter={8}>
          <FlexBox flex={true} direction="column">
            <Card>
              <CardHeader
                title={getLocalisationMessage(
                  "business_details",
                  "Business Details",
                )}
              />
              <CardContent>
                <FormTextField
                  name="code"
                  fullWidth={true}
                  label={`${getLocalisationMessage("code", "Code")} *`}
                />
                <FormTextField
                  name="name"
                  fullWidth={true}
                  label={`${getLocalisationMessage(
                    "courier_name",
                    "Courier Name",
                  )} *`}
                />
                <FormTextField
                  name="website"
                  fullWidth={true}
                  label={getLocalisationMessage("website_url", "Website URL")}
                />
                <FormTextField
                  name="trackingUrl"
                  fullWidth={true}
                  label={getLocalisationMessage("tracking_url", "Tracking URL")}
                />
                <FormTextField
                  name="supplierEmail"
                  fullWidth={true}
                  label={`${getLocalisationMessage("email", "Email")} *`}
                />

                <FlexBox gutter={8}>
                  <FlexBox flex={true}>
                    <FormTextField
                      ignoreRtl={isRTL}
                      name="supplierPhone"
                      fullWidth={true}
                      label={getLocalisationMessage(
                        "customer_service_number",
                        "Customer Service Number",
                      )}
                    />
                  </FlexBox>
                </FlexBox>

                <FlexBox gutter={8}>
                  <FlexBox flex={true}>
                    <FormTextField
                      name="fax"
                      fullWidth={true}
                      label={getLocalisationMessage("fax", "Fax")}
                    />
                  </FlexBox>
                </FlexBox>
                <FlexBox gutter={8}>
                  <FlexBox flex={true}>
                    <FormTextField
                      fullWidth={true}
                      name="numberOfFleet"
                      parseOnBlur={parseFloat}
                      label={getLocalisationMessage("of_fleet", "# of Fleet")}
                    />
                  </FlexBox>

                  <FlexBox flex={true}>
                    <FormTextField
                      fullWidth={true}
                      name="numberOfDrivers"
                      parseOnBlur={parseFloat}
                      label={getLocalisationMessage(
                        "of_drivers",
                        "# of Drivers",
                      )}
                    />
                  </FlexBox>
                </FlexBox>

                <FlexBox gutter={8}>
                  <FlexBox flex={true}>
                    <FormTextField
                      fullWidth={true}
                      name="dailyLimit"
                      parseOnBlur={parseFloat}
                      label={getLocalisationMessage(
                        "daily_limit",
                        "Daily Limit",
                      )}
                    />
                  </FlexBox>

                  <FlexBox flex={true}>
                    <FormTextField
                      fullWidth={true}
                      name="dailyQuota"
                      parseOnBlur={parseFloat}
                      label={getLocalisationMessage(
                        "daily_quota",
                        "Daily Quota",
                      )}
                    />
                  </FlexBox>
                </FlexBox>
                <FormTextField
                  name="prefix"
                  maxLength={4}
                  parseOnBlur={fp.flow(parseInteger, (x) => x || "")}
                  fullWidth={true}
                  label={getLocalisationMessage("prefix", "Prefix")}
                />
              </CardContent>
            </Card>
          </FlexBox>

          <FlexBox flex={true} direction="column">
            <Card>
              <CardHeader
                title={getLocalisationMessage(
                  "contact_person_details",
                  "Contact Person Details",
                )}
              />
              <CardContent>
                <FlexBox gutter={8}>
                  <FlexBox flex={true}>
                    <FormTextField
                      name="contactFirstName"
                      fullWidth={true}
                      label={getLocalisationMessage("first_name", "First Name")}
                    />
                  </FlexBox>

                  <FlexBox flex={true}>
                    <FormTextField
                      name="contactLastName"
                      fullWidth={true}
                      label={getLocalisationMessage("last_name", "Last Name")}
                    />
                  </FlexBox>
                </FlexBox>

                <FormTextField
                  ignoreRtl={isRTL}
                  name="contactPhone"
                  fullWidth={true}
                  label={getLocalisationMessage(
                    "contact_phone",
                    "Contact Phone",
                  )}
                />
                <FormTextField
                  name="contactEmail"
                  fullWidth={true}
                  label={`${getLocalisationMessage(
                    "contact_email",
                    "Contact Email",
                  )} *`}
                />
                <FormSelectField
                  name="status"
                  fullWidth={true}
                  options={Statuses}
                  formatOption={(x) =>
                    getLocalisationMessage(x) || formatText(x)
                  }
                  label={getLocalisationMessage("status", "Status")}
                />
                <FormCustomerAutoComplete
                  name="defaultCustomerId"
                  fullWidth={true}
                  hintText={getLocalisationMessage(
                    "type_to_search",
                    "Type to search ...",
                  )}
                  label={getLocalisationMessage(
                    "default_customer_user",
                    "Default Customer User",
                  )}
                />
              </CardContent>
            </Card>
          </FlexBox>

          <FlexBox flex={true} direction="column">
            <Card>
              <CardHeader
                title={getLocalisationMessage(
                  "shipping_address",
                  "Shipping Address",
                )}
              />

              <CardContent>
                <FormReferenceAutoComplete
                  name="shippingAddress.country"
                  parentCode="country"
                  fullWidth={true}
                  openOnFocus={false}
                  label={getLocalisationMessage("country", "Country")}
                  hintText={getLocalisationMessage(
                    "type_to_search",
                    "Type to search ...",
                  )}
                />

                <FormReferenceAutoComplete
                  name="shippingAddress.city"
                  parentCode={getValue(values, [
                    "shippingAddress",
                    "country",
                    "code",
                  ])}
                  fullWidth={true}
                  openOnFocus={false}
                  label={getLocalisationMessage("city", "City")}
                  hintText={getLocalisationMessage(
                    "type_to_search",
                    "Type to search ...",
                  )}
                />

                <FormTextField
                  name="shippingAddress.address"
                  fullWidth={true}
                  label={getLocalisationMessage("address", "Address")}
                />
                <FormTextField
                  name="shippingAddress.zip"
                  fullWidth={true}
                  label={getLocalisationMessage("post_code", "Post Code")}
                />
              </CardContent>

              <CardHeader
                title={getLocalisationMessage(
                  "billing_address",
                  "Billing Address",
                )}
              />

              <CardContent>
                <FormTextField
                  name="billingAddress.address"
                  fullWidth={true}
                  label={getLocalisationMessage("address", "Address")}
                />
                <FormReferenceAutoComplete
                  name="billingAddress.country"
                  parentCode="country"
                  fullWidth={true}
                  openOnFocus={false}
                  label={getLocalisationMessage("country", "Country")}
                  hintText={getLocalisationMessage(
                    "type_to_search",
                    "Type to search ...",
                  )}
                />
                <FormReferenceAutoComplete
                  name="billingAddress.city"
                  parentCode={getValue(values, [
                    "billingAddress",
                    "country",
                    "code",
                  ])}
                  fullWidth={true}
                  openOnFocus={false}
                  label={getLocalisationMessage("city", "City")}
                  hintText={getLocalisationMessage(
                    "type_to_search",
                    "Type to search ...",
                  )}
                />
                <FormTextField
                  name="billingAddress.zip"
                  fullWidth={true}
                  label={getLocalisationMessage("post_code", "Post Code")}
                />
              </CardContent>
            </Card>
          </FlexBox>
        </FlexBox>
      </FlexBox>

      <FlexBox justify="flex-end">
        <CardActions>
          {props.dirty ? (
            <Button onClick={props.reset}>
              {getLocalisationMessage("reset", "Reset")}
            </Button>
          ) : (
            Boolean(props.onDismiss) && (
              <Button onClick={props.onDismiss}>
                {getLocalisationMessage("dismiss", "Dismiss")}
              </Button>
            )
          )}

          <Button onClick={props.handleSubmit}>
            {getLocalisationMessage("submit", "Submit")}
          </Button>
        </CardActions>
      </FlexBox>
    </form>
  );
}

export default enhancer(SupplierForm);
