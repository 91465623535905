import { Observable } from "rxjs";
import React from "react";
import _ from "lodash";
import { Map, List, OrderedSet } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FormChipAutoComplete from "./FormChipAutoComplete";
import { isEqualData } from "../../helpers/DataUtils";
import { formatText } from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  ORDER_TAG,
  ORDER_RULE,
  RULE_ALERT,
  // DRIVER_RULE,
  ZONING_RULE,
  AUTO_DISPATCH,
  SUPPLIER_RULE,
  CUSTOM_PACKAGE,
  ORDER_DEADLINE,
  PICKUP_JOB_AUTOMATION,
} from "../../constants/RuleType";
import { ACTIVE } from "../../constants/OverallStatus";

const RuleTypes = OrderedSet.of(
  ORDER_RULE,
  SUPPLIER_RULE,
  CUSTOM_PACKAGE,
  PICKUP_JOB_AUTOMATION,
  ZONING_RULE,
  RULE_ALERT,
  AUTO_DISPATCH,
  ORDER_TAG,
  ORDER_DEADLINE,
);

export const baseFilter = new DataListFilter({
  page: 0,
  size: 50,
  status: ACTIVE,
});
const getHeaderOptions = headers => headers.map((key, value) => value).toSet();

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream(propsStream => {
    const dataSourceStream = propsStream
      .distinctUntilChanged(isEqualData)
      .map(props => ({
        ...props,
        filterPayload: baseFilter.setValueMap(props.filterPayload),
      }))
      .switchMap(() => Observable.of(RuleTypes))
      .distinctUntilChanged(isEqualData)
      .map(v =>
        Map().withMutations(map =>
          v.forEach(item => {
            map.set(item, fp.startCase(item));
          }),
        ),
      )
      .startWith(List());

    return propsStream
      .combineLatest(dataSourceStream, (props, options) => ({
        ...props,
        options,
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

FormRuleTypeChips.propTypes = {
  fullWidth: PropTypes.bool,
  autoWidth: PropTypes.bool,
  canAutoPosition: PropTypes.bool,

  maxHeight: PropTypes.number,

  openOnFocus: PropTypes.bool,
  clearOnBlur: PropTypes.bool,
  maxItems: PropTypes.number,
  maxSearchResults: PropTypes.number,

  onGetValue: PropTypes.func,

  validate: PropTypes.func,

  hintText: PropTypes.node,
  label: PropTypes.node,
  chipContainer: PropTypes.object,
  options: PropTypes.object,
  filterPayload: PropTypes.object,

  name: PropTypes.string.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};
FormRuleTypeChips.defaultPropTypes = {
  filterPayload: {},
};

function FormRuleTypeChips({
  options,
  chipContainer,
  getLocalisationMessage,
  ...props
}) {
  return options.size > 0 ? (
    <FormChipAutoComplete
      {...props}
      fullWidth={true}
      options={getHeaderOptions(options)}
      formatOption={code =>
        _.toUpper(getLocalisationMessage(code, formatText(code)))
      }
      chipContainerStyle={chipContainer}
    />
  ) : (
    <div />
  );
}

export default enhancer(FormRuleTypeChips);
