import { Observable } from "rxjs";
import React from "react";
import { List, fromJS } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { Button, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { Add, FilterList } from "@material-ui/icons";
import { isEqualData } from "../../helpers/DataUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { formatText } from "../../helpers/FormatUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import ResponseError from "../../helpers/ResponseError";
import DataListFilter, {
  getListFilterSchema,
} from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  ORDER_RULE_ITEM_URL,
  ORDER_RULE_CREATE_URL,
} from "../../constants/AdminPathConstants";
import {
  getCachedCustomer,
  getCustomerPredictions,
} from "../../api/admin/AdminCustomerApi";
import {
  deleteRule,
  getRuleList,
  cloneCustomerRules,
} from "../../api/admin/AdminOrderRulesApi";
import AdminOrderRuleFilterWrapper from "../../wrappers/admin/AdminOrderRuleFilterWrapper";
import FormDialog from "../../components/form/FormDialog";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import TextWithLink from "../../components/router/TextWithLink";
import Text, { MUTED } from "../../components/ui-core/Text";
import PopoverOverlay from "../../components/ui-core/PopoverOverlay";
import DataList, { DataListColumn } from "../../components/data-list/DataList";
import CloneRulesDialog from "../../components/settings-core/CloneRulesDialog";
import { updateHash, updateQuery } from "../../../shared/helpers/UrlUtils";
import {
  types,
  mapSchema,
  queryTypes,
} from "../../../shared/helpers/ObjectMapper";

const FILTER_DIALOG_HASH = "#FDH";

export const toOrderRuleFilter = fp.compose(
  DataListFilter.create,
  mapSchema({
    ...getListFilterSchema(),

    service_types: queryTypes.stringSeq,
    vehicle_types: queryTypes.stringSeq,
    supplier_ids: queryTypes.IDSeq,
    rule_status: types.string,
    rule_types: queryTypes.stringSeq,
  }),
);
const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
  }),
  mapPropsStream(
    pipeStreams(
      propsStream =>
        propsStream.combineLatest(
          propsStream
            .map(fp.flow(fp.get("location.query"), toOrderRuleFilter))
            .distinctUntilChanged(isEqualData),
          (props, filter) => ({ ...props, filter }),
        ),
      propsStream => {
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const responseStream = propsStream
          .pluck("filter")
          .distinctUntilChanged(isEqualData)
          .switchMap(filter =>
            getRuleList(filter)
              .catch(error => Observable.of({ error }))
              .repeatWhen(() => onRequestRefreshStream),
          )
          .map(
            fp.flow(
              response => fromJS(response),
              response =>
                fromJS({
                  error: response.get("error", null),
                  pending: response.get("pending", false),
                  total: response.getIn(["payload", "data", "total"], 0),
                  list: response.getIn(["payload", "data", "list"], List()),
                }),
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(responseStream, (props, response) => ({
          ...props,
          onRequestRefresh,
          list: response.get("list"),
          total: response.get("total"),
          isFetching: response.get("pending"),
        }));
      },
    ),
  ),
);

AdminOrderRuleListContainer.propTypes = {
  location: PropTypes.object,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  setLocationQuery: PropTypes.func,
  setLocationQueryFilter: PropTypes.func,
  replaceLocationHash: PropTypes.func,
  onRequestRefresh: PropTypes.func,

  total: PropTypes.number,
  isFetching: PropTypes.bool,
  list: PropTypes.instanceOf(List),
  filter: PropTypes.instanceOf(DataListFilter),
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminOrderRuleListContainer(props) {
  const removeId = fp.toFinite(props.location.query.remove);
  const cloneId = fp.toFinite(props.location.query.clone);
  const { getLocalisationMessage } = props;
  const NA = getLocalisationMessage("na", "N/A");

  return (
    <AdminAppLayout title={getLocalisationMessage("order_rule", "Order Rule")}>
      <FormDialog
        open={removeId > 0}
        onRequestClose={() => props.setLocationQuery(fp.unset("remove"))}
        onSubmit={() => deleteRule(removeId).catch(ResponseError.throw)}
        onSubmitSuccess={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("remove"));
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_removed",
              "Successfully Removed",
            ),
          );
        }}
        onSubmitFail={error => props.showErrorMessage(error)}
      >
        {getLocalisationMessage(
          "are_you_sure_you_want_to_remove_this_rule",
          "Are you sure you want to remove this rule?",
        )}
      </FormDialog>

      <CloneRulesDialog
        ruleId={cloneId}
        getCachedCustomer={getCachedCustomer}
        getCustomerPredictions={getCustomerPredictions}
        onRequestClose={() => props.setLocationQuery(fp.unset("clone"))}
        onSubmit={values => cloneCustomerRules(cloneId, toSnakeCase(values))}
        onSubmitSuccess={() => {
          props.onRequestRefresh();
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_cloned",
              "Successfully Cloned",
            ),
          );
        }}
        onSubmitFail={error => props.showErrorMessage(error)}
      />

      <AdminOrderRuleFilterWrapper
        open={props.location.hash === FILTER_DIALOG_HASH}
        filter={props.filter}
        onRequestClose={() => props.replaceLocationHash(null)}
        onFilterChange={filter => {
          props.setLocationQueryFilter(filter);
          props.replaceLocationHash(null);
        }}
      />

      <DataList
        overscanRowCount={10}
        totalCount={props.total}
        rowCount={props.list.size}
        isLoading={props.isFetching}
        filter={props.filter}
        onFilterChange={props.setLocationQueryFilter}
        rowGetter={options => props.list.get(options.index)}
        cardActionIcons={
          <div>
            <Link to={ORDER_RULE_CREATE_URL}>
              <IconButton>
                <Add />
              </IconButton>
            </Link>

            <Link to={updateHash(props.location, FILTER_DIALOG_HASH)}>
              <IconButton>
                <FilterList />
              </IconButton>
            </Link>
          </div>
        }
      >
        <DataListColumn
          label={getLocalisationMessage("id", "ID")}
          width={120}
          dataKey="id"
          disableSort={true}
          justifyContent="end"
          cellRenderer={row => (
            <TextWithLink
              to={`${ORDER_RULE_ITEM_URL}${row.cellData.get("id")}`}
            >
              {row.cellData.get("id")}
            </TextWithLink>
          )}
        />

        <DataListColumn
          label={getLocalisationMessage("name", "Name")}
          width={120}
          dataKey="name"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => (
            <Text fallbackValue={NA} fallbackType={MUTED}>
              {row.cellData.get("name")}
            </Text>
          )}
        />

        <DataListColumn
          label={getLocalisationMessage("priority", "Priority")}
          width={120}
          dataKey="priority"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => (
            <Text fallbackValue={NA} fallbackType={MUTED}>
              {row.cellData.get("priority")}
            </Text>
          )}
        />

        <DataListColumn
          label={getLocalisationMessage("type", "Type")}
          width={120}
          dataKey="type"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => (
            <Text fallbackValue={NA} fallbackType={MUTED}>
              {getLocalisationMessage(row.cellData.get("rule_type")) ||
                formatText(row.cellData.get("rule_type"))}
            </Text>
          )}
        />

        <DataListColumn
          label={getLocalisationMessage("trigger_type", "Trigger Type")}
          width={120}
          dataKey="trigger_type"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => (
            <Text fallbackValue={NA} fallbackType={MUTED}>
              {getLocalisationMessage(row.cellData.get("trigger_type")) ||
                formatText(row.cellData.get("trigger_type"))}
            </Text>
          )}
        />

        <DataListColumn
          label={getLocalisationMessage("customers", "Customers")}
          width={120}
          dataKey="customer_names"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => (
            <PopoverOverlay
              width={350}
              header={getLocalisationMessage("customers", "Customers")}
              content={row.cellData.get("customer_names") || NA}
            >
              <span>{row.cellData.get("customer_names")}</span>
            </PopoverOverlay>
          )}
        />

        <DataListColumn
          label={getLocalisationMessage("customers_count", "Customers Count")}
          width={120}
          dataKey="customer_count"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => (
            <Text fallbackValue={NA} fallbackType={MUTED}>
              {row.cellData.get("customer_count")}
            </Text>
          )}
        />

        <DataListColumn
          label={getLocalisationMessage("status", "Status")}
          width={120}
          dataKey="status"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => (
            <Text fallbackValue={NA} fallbackType={MUTED}>
              {getLocalisationMessage(row.cellData.get("rule_status")) ||
                formatText(row.cellData.get("rule_status"))}
            </Text>
          )}
        />

        <DataListColumn
          label={getLocalisationMessage("action", "Action")}
          width={200}
          dataKey="status"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => (
            <div>
              <Link
                to={updateQuery(props.location, {
                  remove: row.cellData.get("id"),
                })}
              >
                <Button>{getLocalisationMessage("remove", "Remove")}</Button>
              </Link>
              <Link
                to={updateQuery(props.location, {
                  clone: row.cellData.get("id"),
                })}
              >
                <Button>{getLocalisationMessage("clone", "Clone")}</Button>
              </Link>
            </div>
          )}
        />
      </DataList>
    </AdminAppLayout>
  );
}

export default enhancer(AdminOrderRuleListContainer);
