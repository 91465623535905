import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import ResponseError from "../../helpers/ResponseError";
import { getMessages } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  ORDER_RULE_ITEM_URL,
  ORDER_RULE_LIST_URL,
  ORDER_RULE_CREATE_URL,
} from "../../constants/AdminPathConstants";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../api/admin/AdminDriverApi";
import {
  getCachedCustomer,
  getCustomerPredictions,
} from "../../api/admin/AdminCustomerApi";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import {
  getCachedTimeSlot,
  getTimeSlotPredictions,
} from "../../api/admin/AdminTimeSlotApi";
import {
  getCachedWarehouse,
  getWarehousePolygons,
  getWarehousePredictions,
} from "../../api/admin/AdminWarehouseApi";
import {
  getRule,
  updateRule,
  getCachedRule,
  getRulePredictions,
  getChildRulePredictions,
} from "../../api/admin/AdminOrderRulesApi";
import {
  getCachedPostcode,
  getNeighborhoodPolygons,
  getPostcodePredictions,
} from "../../api/admin/AdminPostcodesApi";
import {
  getCachedCity,
  getCachedCountry,
  getCityPredictions,
  getCountryPredictions,
} from "../../api/shared/CountryV2Api";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import PageLoading from "../../components/ui-core/PageLoading";
import OrderRuleTree from "../../components/order-rule-tree/OrderRuleTree";

const enhancer = compose(
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({ setLocation: PropTypes.func.isRequired }),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const responseStream = propsStream
      .map(fp.get("params.id"))
      .distinctUntilChanged()
      .switchMap(id =>
        getRule(id)
          .catch(error => Observable.of({ error }))
          .repeatWhen(() => onRequestRefreshStream),
      )
      .distinctUntilChanged(isEqualData)
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              error: response.get("error", null),
              pending: response.get("pending", false),
              item: response.getIn(["payload", "data"], Map()),
            }),
        ),
      );

    return propsStream.combineLatest(responseStream, (props, response) => ({
      ...props,
      onRequestRefresh,
      item: response.get("item"),
      isFetching: response.get("pending"),
    }));
  }),
);

AdminOrderRuleItemContainer.propTypes = {
  setLocation: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  isFetching: PropTypes.bool,
  item: PropTypes.instanceOf(Map),
  onRequestRefresh: PropTypes.func,
  i18n: PropTypes.instanceOf(Map),
};

function AdminOrderRuleItemContainer(props) {
  return (
    <AdminAppLayout
      title={props.i18n.get("edit_order_rule", "Edit Order Rule")}
    >
      <PageLoading isLoading={props.isFetching} />

      {!props.isFetching && (
        <OrderRuleTree
          tree={props.item}
          onSubmit={values => updateRule(values).catch(ResponseError.throw)}
          onSubmitSuccess={() => {
            props.showSuccessMessage(
              props.i18n.get(
                "successfully_order_rule_updated",
                "Successfully Order Rule Updated",
              ),
            );
            props.setLocation(ORDER_RULE_LIST_URL);
          }}
          onSubmitFail={error => props.showErrorMessage(error)}
          createRuleUrl={ORDER_RULE_CREATE_URL}
          getRuleUrl={rule => `${ORDER_RULE_ITEM_URL}/${rule.id}`}
          getWarehousePolygons={getWarehousePolygons}
          getNeighborhoodPolygons={getNeighborhoodPolygons}
          getCachedRule={getCachedRule}
          getRulePredictions={getRulePredictions}
          getChildRulePredictions={getChildRulePredictions}
          getCachedCustomer={getCachedCustomer}
          getCustomerPredictions={getCustomerPredictions}
          getCachedSupplier={getCachedSupplier}
          getSupplierPredictions={getSupplierPredictions}
          getCachedDriver={getCachedDriver}
          getDriverPredictions={getDriverPredictions}
          getCachedTimeSlot={getCachedTimeSlot}
          getTimeSlotPredictions={getTimeSlotPredictions}
          getCachedWarehouse={getCachedWarehouse}
          getWarehousePredictions={getWarehousePredictions}
          getCachedPostcode={getCachedPostcode}
          getPostcodePredictions={getPostcodePredictions}
          getCachedCountry={getCachedCountry}
          getCountryPredictions={getCountryPredictions}
          getCachedCity={getCachedCity}
          getCityPredictions={getCityPredictions}
        />
      )}
    </AdminAppLayout>
  );
}

export default enhancer(AdminOrderRuleItemContainer);
