import React from "react";
import { List } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { Paper, Button, IconButton, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { Add } from "@material-ui/icons";
import { toRulesGroupListFilter } from "../../helpers/Rule";
import { mapListResponseStream } from "../../helpers/ApiUtils";
import { pureComponent } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { formatText, formatNumber } from "../../helpers/FormatUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import ResponseError from "../../helpers/ResponseError";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { ORDER_RULES_GROUP_ITEM_URL } from "../../constants/AdminPathConstants";
import { ACTIVE, INACTIVE } from "../../constants/UpperOverallStatus";
import {
  getCachedCustomer,
  getCustomerPredictions,
} from "../../api/admin/AdminCustomerApi";
import {
  getRulesGroupList,
  deleteRulesGroupItem,
  cloneCustomerRuleGroups,
} from "../../api/admin/AdminOrderRulesApi";
import FormDialog from "../../components/form/FormDialog";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import Text, { MUTED, DANGER, SUCCESS } from "../../components/ui-core/Text";
import FlexBox from "../../components/ui-core/FlexBox";
import NavTabs from "../../components/ui-core/NavTabs";
import DataList, { DataListColumn } from "../../components/data-list/DataList";
import CloneRuleGroupsDialog from "../../components/settings-core/CloneRuleGroupsDialog";
import { updateQuery } from "../../../shared/helpers/UrlUtils";

const NA = <Text type={MUTED}>N/A</Text>;
const GroupStatus = ({ status, getLocalisationMessage }) =>
  status === ACTIVE ? (
    <Text type={SUCCESS}>
      {formatText(getLocalisationMessage(status, status))}
    </Text>
  ) : status === INACTIVE ? (
    <Text type={DANGER}>
      {formatText(getLocalisationMessage(status, status))}
    </Text>
  ) : (
    NA
  );

GroupStatus.propTypes = {
  status: PropTypes.string,
  getLocalisationMessage: PropTypes.func.isRequired,
};

const enhancer = compose(
  useSheet({
    paper: {
      width: "1000px",
    },
  }),
  getContext({
    replaceLocation: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
      showSuccessMessage,
      showErrorMessage,
    };
  }),
  mapPropsStream(
    pipeStreams(
      propsStream =>
        propsStream
          .combineLatest(
            propsStream
              .map(fp.flow(fp.get("location.query"), toRulesGroupListFilter))
              .distinctUntilChanged(isEqualData),
            (props, filter) => ({
              ...props,
              filter,
            }),
          )
          .distinctUntilChanged(isEqualData),
      propsStream => {
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const getRulesGroupListStream = propsStream
          .distinctUntilKeyChanged("filter", isEqualData)
          .switchMap(props =>
            getRulesGroupList(props.filter)
              .let(mapListResponseStream)
              .repeatWhen(() => onRequestRefreshStream),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(
            getRulesGroupListStream,
            (props, responseRulesGroupList) => ({
              ...props,
              list: responseRulesGroupList.getIn(["payload", "list"]),
              total: responseRulesGroupList.getIn(["payload", "total"]),
              isLoading: responseRulesGroupList.get("pending"),
              onRequestRefresh,
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
  pureComponent(fp.pick(["location", "list", "total", "isLoading", "filter"])),
);

AdminOrderRulesGroupListContainer.propTypes = {
  classes: PropTypes.object,
  location: PropTypes.object,

  list: PropTypes.instanceOf(List),
  total: PropTypes.number,
  isLoading: PropTypes.bool,

  replaceLocation: PropTypes.func,
  setLocationQuery: PropTypes.func,
  setLocationQueryFilter: PropTypes.func,

  onRequestRefresh: PropTypes.func,

  filter: PropTypes.instanceOf(DataListFilter),

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminOrderRulesGroupListContainer(props) {
  const { classes, location, getLocalisationMessage } = props;

  const currentTab = updateQuery(location, fp.unset("page"));
  const cloneId = fp.toFinite(props.location.query.clone);

  return (
    <AdminAppLayout
      title={getLocalisationMessage("rules_group", "Rules Group")}
    >
      <FormDialog
        onSubmitFail={props.showErrorMessage}
        onRequestClose={() => props.setLocationQuery(fp.unset("delete"))}
        open={fp.toFinite(location.query.delete) > 0}
        onSubmit={() =>
          deleteRulesGroupItem(location.query.delete).catch(ResponseError.throw)
        }
        onSubmitSuccess={() => {
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_removed",
              "Successfully Removed",
            ),
          );
          props.setLocationQuery(fp.unset("delete"));
          props.onRequestRefresh();
        }}
      >
        {getLocalisationMessage(
          "are_you_sure_you_want_to_remove_this_group",
          "Are you sure you want to remove this group?",
        )}
      </FormDialog>

      <CloneRuleGroupsDialog
        ruleId={cloneId}
        getCachedCustomer={getCachedCustomer}
        getCustomerPredictions={getCustomerPredictions}
        onRequestClose={() => props.setLocationQuery(fp.unset("clone"))}
        onSubmit={values =>
          cloneCustomerRuleGroups(cloneId, toSnakeCase(values)).catch(
            ResponseError.throw,
          )
        }
        onSubmitSuccess={() => {
          props.onRequestRefresh();
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_cloned",
              "Successfully Cloned",
            ),
          );
        }}
        onSubmitFail={error => props.showErrorMessage(error)}
      />

      <FlexBox container={16} justify="center" flex={true}>
        <FlexBox
          element={<Paper className={classes.paper} />}
          direction="column"
        >
          <FlexBox>
            <NavTabs
              width={300}
              value={currentTab}
              tabs={[
                {
                  label: getLocalisationMessage("all", "All"),
                  value: updateQuery(currentTab, fp.unset("rule_group_status")),
                },
                {
                  label: getLocalisationMessage("active", "Active"),
                  value: updateQuery(
                    currentTab,
                    fp.set("rule_group_status", ACTIVE),
                  ),
                },
                {
                  label: getLocalisationMessage("inactive", "Inactive"),
                  value: updateQuery(
                    currentTab,
                    fp.set("rule_group_status", INACTIVE),
                  ),
                },
              ]}
            />
          </FlexBox>

          <FlexBox flex={true}>
            <DataList
              isLoading={props.isLoading}
              totalCount={props.total}
              list={props.list}
              rowCount={props.list.size}
              overscanRowCount={8}
              filter={props.filter}
              onFilterChange={filter => props.setLocationQueryFilter(filter)}
              rowGetter={options => props.list.get(options.index)}
              cardActionIcons={
                <Tooltip
                  title={getLocalisationMessage(
                    "add_rules_group",
                    "Add Rules Group",
                  )}
                >
                  <IconButton
                    onClick={() =>
                      props.replaceLocation(ORDER_RULES_GROUP_ITEM_URL)
                    }
                  >
                    <Add />
                  </IconButton>
                </Tooltip>
              }
            >
              <DataListColumn
                width={250}
                label={getLocalisationMessage("name", "Name")}
                justifyContent="center"
                dataKey="name"
                disableSort={true}
                cellRenderer={row =>
                  row.cellData.get("name") ? (
                    <Link
                      to={updateQuery(
                        `${ORDER_RULES_GROUP_ITEM_URL}/${row.cellData.get(
                          "id",
                        )}`,
                      )}
                    >
                      {row.cellData.get("name")}
                    </Link>
                  ) : (
                    NA
                  )
                }
              />

              <DataListColumn
                width={250}
                label={getLocalisationMessage("status", "Status")}
                justifyContent="center"
                dataKey="rule_group_status"
                disableSort={true}
                cellRenderer={row => (
                  <GroupStatus
                    status={row.cellData.get("rule_group_status")}
                    getLocalisationMessage={getLocalisationMessage}
                  />
                )}
              />

              <DataListColumn
                width={250}
                label={getLocalisationMessage("rule_count", "Rule Count")}
                justifyContent="center"
                dataKey="rule_count"
                disableSort={true}
                cellRenderer={row =>
                  formatNumber(row.cellData.get("rule_count")) || NA
                }
              />

              <DataListColumn
                width={200}
                label={getLocalisationMessage("actions", "Actions")}
                justifyContent="center"
                dataKey="rule_count"
                disableSort={true}
                cellRenderer={row => (
                  <div>
                    <Button
                      onClick={() =>
                        props.setLocationQuery(
                          fp.set("delete", row.cellData.get("id")),
                        )
                      }
                    >
                      {getLocalisationMessage("remove", "Remove")}
                    </Button>
                    <Button
                      onClick={() =>
                        props.setLocationQuery(
                          fp.set("clone", row.cellData.get("id")),
                        )
                      }
                    >
                      {getLocalisationMessage("clone", "Clone")}
                    </Button>
                  </div>
                )}
              />
            </DataList>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </AdminAppLayout>
  );
}

export default enhancer(AdminOrderRulesGroupListContainer);
