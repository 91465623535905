import { OrderedSet } from "immutable";

export const ASSIGN_TO_SUPPLIER = "ASSIGN_TO_SUPPLIER";
export const ASSIGN_TO_DRIVER = "ASSIGN_TO_DRIVER";

export const ALLOCATE_BY_PROXIMITY = "ALLOCATE_BY_PROXIMITY";
export const ALLOCATE_BY_SUPPLIER = "ALLOCATE_BY_SUPPLIER";
export const ALLOCATE_BY_DRIVER = "ALLOCATE_BY_DRIVER";
export const ALLOCATE_BY_WAREHOUSE = "ALLOCATE_BY_WAREHOUSE";

export default OrderedSet.of(
  ASSIGN_TO_SUPPLIER,
  ASSIGN_TO_DRIVER,
  ALLOCATE_BY_PROXIMITY,
  ALLOCATE_BY_WAREHOUSE,
);

export const OrderRuleForPickupJobAutomation = OrderedSet.of(
  ASSIGN_TO_SUPPLIER,
  ASSIGN_TO_DRIVER,
);
