import React from "react";
import fp from "lodash/fp";
import {
  compose,
  mapPropsStream,
  createEventHandler,
  getContext,
} from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { mapObjectResponseStream } from "../../helpers/ApiUtils";
import { getValue, isEqualData } from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { getCustomer, updateCustomer } from "../../api/admin/AdminCustomerApi";
import {
  getCachedReference,
  getReferencePredictions,
} from "../../api/shared/ReferenceApi";
import AdminCustomerForm from "../../components/admin/AdminCustomerForm";
import PageLoading from "../../components/ui-core/PageLoading";
import { MERCHANTS } from "../../constants/MerchantTypes";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import FlexBox from "../../components/ui-core/FlexBox";
import { makeStyles } from "@material-ui/core";
import AdminCustomerItemCommunicationContainer from "./AdminCustomerItemCommunicationContainer";
import AdminCustomerMerchantForm from "../../components/admin/AdminCustomerMerchantForm";
import { CUSTOMER_LIST_ALL_URL } from "../../constants/AdminPathConstants";

const styles = makeStyles({
  root: {
    height: "100%",
    padding: 10,
  },
});

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({
    setLocation: PropTypes.func.isRequired,
  }),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const customerStream = propsStream
      .map(fp.flow(fp.get("params.customerId"), fp.toInteger))
      .distinctUntilChanged()
      .switchMap(customerId =>
        getCustomer(customerId).repeatWhen(() => onRequestRefreshStream),
      )
      .let(mapObjectResponseStream)
      .map(response => response.update("payload", toCamelCase))
      .distinctUntilChanged(isEqualData);

    return propsStream.combineLatest(customerStream, (props, customer) => ({
      ...props,
      onRequestRefresh,
      isLoading: customer.get("pending"),
      customer: customer.get("payload"),
    }));
  }),
);

AdminCustomerItemDetailsContainer.propTypes = {
  params: PropTypes.object,
  location: PropTypes.object,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,

  isLoading: PropTypes.bool,
  customer: PropTypes.object,
  onRequestRefresh: PropTypes.func,
  setLocation: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function AdminCustomerItemDetailsContainer(props) {
  if (props.isLoading) {
    return <PageLoading isLoading={true} />;
  }
  const { getLocalisationMessage } = props;

  const classes = styles();

  const [show, setShow] = React.useState(
    getValue(props.customer, "merchantType") === MERCHANTS,
  );

  return (
    <AdminAppLayout
      className={classes.root}
      title={getLocalisationMessage("customers", "Customers")}
    >
      <FlexBox direction="column" style={{ gap: 16, height: "100%" }}>
        {!getValue(props.customer, "company.id", null) && (
          <AdminCustomerForm
            setShow={setShow}
            edit={true}
            initialValues={{
              ...props.customer,
              merchant: props.customer.merchantType === MERCHANTS,
            }}
            onSubmit={fp.flow(toSnakeCase, values =>
              updateCustomer(props.params.customerId, values)
                .then(() => {
                  props.onRequestRefresh();
                  props.showSuccessMessage(
                    getLocalisationMessage(
                      "successfully_saved",
                      "Successfully Saved",
                    ),
                  );
                  props.setLocation(CUSTOMER_LIST_ALL_URL);
                })
                .catch(error => props.showErrorMessage(error)),
            )}
            getCachedReference={getCachedReference}
            getReferencePredictions={getReferencePredictions}
          />
        )}

        {getValue(props.customer, "company.id", null) && (
          <AdminCustomerMerchantForm
            setShow={setShow}
            edit={true}
            merchantInnOld={getValue(props.customer, "merchantInn", null)}
            initialValues={{
              ...props.customer,
              merchant: props.customer.merchantType === MERCHANTS,
              key1: Object.keys(
                getValue(props.customer, "company.headers", {}),
              )[0],
              key2: Object.values(
                getValue(props.customer, "company.headers", {}),
              )[0],
            }}
            onSubmit={fp.flow(toSnakeCase, values => {
              const data = {
                ...values,
                company: {
                  ...values.company,
                  headers: {
                    [values.key_1]: values.key_2,
                  },
                },
              };
              return updateCustomer(props.params.customerId, data)
                .then(() => {
                  props.onRequestRefresh();
                  props.showSuccessMessage(
                    getLocalisationMessage(
                      "successfully_saved",
                      "Successfully Saved",
                    ),
                  );
                })
                .catch(error => props.showErrorMessage(error));
            })}
            getCachedReference={getCachedReference}
            getReferencePredictions={getReferencePredictions}
          />
        )}

        {getValue(props.customer, "company.id", null) && show && (
          <AdminCustomerItemCommunicationContainer
            params={props.params}
            customerIsLoading={true}
            location={props.location}
          />
        )}
      </FlexBox>
    </AdminAppLayout>
  );
}

export default enhancer(AdminCustomerItemDetailsContainer);
