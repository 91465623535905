import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../ui-core/CustomButton";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({ content: { maxWidth: "320px" } }),
);

ConfirmDialogWithoutClose.propTypes = {
  sheet: PropTypes.object,
  classes: PropTypes.object,

  contentWidth: PropTypes.oneOf(["sm", "md"]),

  modal: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func,
  title: PropTypes.node,
  children: PropTypes.node,

  contentClassName: PropTypes.string,
  dismissButtonLabel: PropTypes.string,
  onConfirm: PropTypes.func,
  confirmButtonLabel: PropTypes.string,
  getLocalisationMessage: PropTypes.func,
};

ConfirmDialogWithoutClose.defaultProps = {
  contentWidth: "sm",
};

function ConfirmDialogWithoutClose({
  sheet,
  classes,
  contentClassName,
  onConfirm,
  dismissButtonLabel,
  confirmButtonLabel,
  ...props
}) {
  return (
    <Dialog
      open={props.open}
      contentClassName={cx(
        props.contentWidth === "sm" && classes.content,
        contentClassName,
      )}
    >
      {props.title && <DialogTitle>{props.title}</DialogTitle>}
      <DialogContent
        style={{
          minWidth: "600px",
          maxWidth: "600px",
          color: "gray",
        }}
      >
        {props.children}
      </DialogContent>
      <DialogActions>
        {props.onRequestClose && (
          <CustomButton
            variant={OUTLINED}
            color={SECONDARY}
            onClick={props.onRequestClose}
          >
            {dismissButtonLabel ||
              props.getLocalisationMessage("dismiss", "Dismiss")}
          </CustomButton>
        )}

        {onConfirm && (
          <CustomButton
            variant={CONTAINED}
            color={SECONDARY}
            onClick={onConfirm}
          >
            {confirmButtonLabel ||
              props.getLocalisationMessage("confirm", "Confirm")}
          </CustomButton>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(ConfirmDialogWithoutClose);
