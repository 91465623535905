import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEqualData, isEqualWithoutFunctions } from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  getCommunicationMessage,
  deleteCommunicationMessage,
  saveSupplierCommunicationMessage,
  getSupplierCommunicationEventsList,
} from "../../api/shared/CommunicationApi";
import FormDialog from "../../components/form/FormDialog";
import FlexBox from "../../components/ui-core/FlexBox";
import CommunicationSettingsForm from "../../components/communication-settings/CommunicationSettingsForm";
import CommunicationSettingsTable, {
  SUPPLIER,
  BY_EVENTS,
} from "../../components/communication-settings/CommunicationSettingsTable";

const enhancer = compose(
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    setLocation: PropTypes.func.isRequired,
  }),
  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);
      return {
        getLocalisationMessage,
      };
    },
    { showSuccessMessage, showErrorMessage },
  ),
  mapPropsStream(propsStream => {
    const supplierIdStream = propsStream
      .pluck("params", "supplierId")
      .filter(fp.toFinite)
      .distinctUntilChanged(isEqualData);

    const createInitialValuesStream = propsStream
      .pluck("location", "query")
      .filter(filter => filter.communicationItem === "create")
      .map(
        fp.flow(
          fp.pick(["type", "target", "messageType", "eventCourierType"]),
          fp.set("duplicateOfPush", false),
        ),
      )
      .distinctUntilChanged(isEqualData)
      .startWith(null);

    const communicationItemResponseStream = propsStream
      .pluck("location", "query", "communicationItem")
      .filter(fp.toFinite)
      .switchMap(id => getCommunicationMessage(id).catch(ResponseError.throw))
      .startWith({})
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              item: response.getIn(["payload", "data"], Map()),
              isLoading: response.get("pending", false),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        supplierIdStream,
        createInitialValuesStream,
        communicationItemResponseStream,
        supplierIdStream,
        (
          props,
          supplierId,
          createInitialValues,
          communicationItemResponse,
        ) => ({
          ...props,
          supplierId,
          createInitialValues,
          item: communicationItemResponse.get("item"),
          isLoading: communicationItemResponse.get("isLoading"),
        }),
      )
      .distinctUntilChanged(isEqualWithoutFunctions);
  }),
);

AdminSupplierItemCommunicationContainer.propTypes = {
  params: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,

  setLocation: PropTypes.func,
  setLocationQuery: PropTypes.func,

  createInitialValues: PropTypes.object,
  item: PropTypes.instanceOf(Map),
  isLoading: PropTypes.bool,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,

  supplierId: PropTypes.string,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminSupplierItemCommunicationContainer(props) {
  const { location, getLocalisationMessage } = props;

  return (
    <FlexBox direction="column" flex={true}>
      <FormDialog
        open={Boolean(location.query.removeId)}
        onRequestClose={() => props.setLocationQuery(fp.unset("removeId"))}
        onSubmitFail={props.showErrorMessage}
        onSubmit={() =>
          deleteCommunicationMessage(location.query.communicationItem).catch(
            ResponseError.throw,
          )
        }
        onSubmitSuccess={() => {
          props.setLocationQuery(fp.omit(["communicationItem", "removeId"]));
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_removed",
              "Successfully Removed",
            ),
          );
        }}
      >
        {getLocalisationMessage(
          "are_you_sure_you_want_to_remove_this_message",
          "Are you sure you want to remove this message?",
        )}
      </FormDialog>

      {!location.query.communicationItem && (
        <CommunicationSettingsTable
          entityType={SUPPLIER}
          onChangeFilter={value =>
            props.setLocationQuery(fp.set("eventCourierType", value))
          }
          getCommunicationList={getSupplierCommunicationEventsList}
          location={props.location}
          entityId={props.supplierId}
          onFilterChange={data => props.setLocationQuery({ filterType: data })}
          onItemClick={id =>
            props.setLocationQuery(fp.set("communicationItem", id))
          }
          onCreateClick={query =>
            props.setLocationQuery(currentQuery => ({
              ...currentQuery,
              ...query,
              communicationItem: "create",
            }))
          }
          onEventChange={(filterType, data) =>
            filterType === BY_EVENTS
              ? props.setLocationQuery(fp.set("eventType", data))
              : props.setLocationQuery(fp.set("eventTarget", data))
          }
        />
      )}

      {location.query.communicationItem > 0 && (
        <CommunicationSettingsForm
          initialValues={toCamelCase(props.item)}
          isLoading={props.isLoading}
          breadcrumbTitle={getLocalisationMessage(
            "edit_message",
            "Edit Message",
          )}
          onBackClick={() =>
            props.setLocationQuery(fp.unset("communicationItem"))
          }
          onDeleteClick={() =>
            props.setLocationQuery(fp.set("removeId", props.item.get("id")))
          }
          onSubmitFail={props.showErrorMessage}
          onSubmitSuccess={() =>
            props.showSuccessMessage(
              getLocalisationMessage(
                "successfully_saved",
                "Successfully saved",
              ),
            )
          }
          onSubmit={values =>
            saveSupplierCommunicationMessage(toSnakeCase(values)).catch(
              ResponseError.throw,
            )
          }
        />
      )}

      {location.query.communicationItem === "create" && (
        <CommunicationSettingsForm
          initialValues={props.createInitialValues}
          onSubmitFail={props.showErrorMessage}
          breadcrumbTitle={getLocalisationMessage(
            "create_message",
            "Create Message",
          )}
          onSubmitSuccess={response => {
            const id = fp.get("data.id")(response);

            props.showSuccessMessage(
              getLocalisationMessage(
                "successfully_saved",
                "Successfully saved",
              ),
            );

            if (id) {
              props.setLocationQuery(
                fp.flow(
                  fp.omit(["type", "target", "messageType"]),
                  fp.set("communicationItem", id),
                ),
              );
            }
          }}
          onSubmit={values =>
            saveSupplierCommunicationMessage(
              toSnakeCase({
                ...values,
                entityType: SUPPLIER,
                entityID: props.supplierId,
              }),
            ).catch(ResponseError.throw)
          }
        />
      )}
    </FlexBox>
  );
}

export default enhancer(AdminSupplierItemCommunicationContainer);
