import { Observable } from "rxjs";
import React from "react";
import _ from "lodash";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  getPostcodeIndexList,
  getSupplierNeighborhoods,
  updateSupplierNeighborhood,
} from "../../api/admin/AdminPostcodesApi";
import NeighborhoodsCopyForm from "../../components/neighborhoods-core/NeighborhoodsCopyForm";

const enhancer = compose(
  getContext({
    setLocationHash: PropTypes.func,
  }),
  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);
      return {
        getLocalisationMessage,
      };
    },
    { showErrorMessage, showSuccessMessage },
  ),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const filterStream = propsStream
      .map(props => new DataListFilter(props.location.query))
      .distinctUntilChanged(isEqualData);

    const allNeighborhoodsStream = propsStream.take(1).switchMap(() => {
      const baseFilter = new DataListFilter().setPageSize(100);

      const createFetchPageStream = filter =>
        getPostcodeIndexList(filter)
          .catch(() => Observable.of({ pending: false }))
          .filter(fp.flow(fp.get("pending"), fp.eq(false)))
          .map(response => fromJS(response))
          .map(response =>
            Map({
              list: response.getIn(["payload", "data", "list"], List()),
              total: response.getIn(["payload", "data", "total"], 0),
            }),
          );

      return createFetchPageStream(baseFilter)
        .switchMap(response => {
          const maxPage = Math.ceil(
            response.get("total") / baseFilter.getSize(),
          );
          const streams = _.map(_.range(maxPage), page =>
            page === 0
              ? Observable.of(response)
              : createFetchPageStream(baseFilter.setPage(page)),
          );

          return Observable.from(streams);
        })
        .concatAll()
        .reduce((list, response) => list.concat(response.get("list")), List())
        .repeatWhen(() => onRequestRefreshStream);
    });

    const initialValuesStream = propsStream
      .map(fp.flow(fp.get(["params", "supplierId"]), fp.toFinite))
      .distinctUntilChanged()
      .switchMap(id =>
        getSupplierNeighborhoods(id)
          .repeatWhen(() => onRequestRefreshStream)
          .catch(error => Observable.of({ error })),
      )
      .map(response =>
        fromJS(response)
          .getIn(["payload", "data", "warehouse_list"], List())
          .map(item => item.get("id")),
      )
      .distinctUntilChanged(isEqualData)
      .map(ids => ({ neighborhoodIds: ids.toArray() }));

    return propsStream
      .combineLatest(
        allNeighborhoodsStream,
        initialValuesStream,
        filterStream,
        (props, neighborhoodsList, initialValues, filter) => ({
          ...props,
          onRequestRefresh,
          filter,
          initialValues,
          neighborhoodsList,
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

AdminSupplierItemPostcodesContainer.propTypes = {
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  setLocationHash: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  params: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,

  filter: PropTypes.instanceOf(DataListFilter),
  initialValues: PropTypes.object,
  neighborhoodsList: PropTypes.instanceOf(List),
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminSupplierItemPostcodesContainer(props) {
  const { getLocalisationMessage } = props;
  if (!props.neighborhoodsList) {
    return null;
  }

  return (
    <NeighborhoodsCopyForm
      initialValues={props.initialValues}
      getPostcodeIndexList={getPostcodeIndexList}
      onSubmit={values =>
        updateSupplierNeighborhood(props.params.supplierId, {
          supplier: { id: props.params.supplierId },
          warehouse_list: _.map(values.neighborhoodIds, id => ({ id })),
        }).catch(e => {
          props.showErrorMessage(e);

          throw e.response;
        })
      }
      onSubmitSuccess={() => {
        props.onRequestRefresh();
        props.showSuccessMessage(
          getLocalisationMessage("successfully_saved", "Successfully saved"),
        );
      }}
    />
  );
}

export default enhancer(AdminSupplierItemPostcodesContainer);
