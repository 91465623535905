import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { Button } from "@material-ui/core";

ToggleButtonComponent.propTypes = {
  input: PropTypes.object,

  trueValue: PropTypes.any,
  trueLabel: PropTypes.string.isRequired,

  falseValue: PropTypes.any,
  falseLabel: PropTypes.string.isRequired,
};

ToggleButtonComponent.defaultProps = {
  trueValue: true,
  falseValue: false,
};

function ToggleButtonComponent(props) {
  const { input } = props;
  const isTrue = input.value === props.trueValue;

  return (
    <Button
      onBlur={() => input.onBlur()}
      onFocus={() => input.onFocus()}
      onClick={() =>
        input.onChange(isTrue ? props.falseValue : props.trueValue)
      }
    >
      {isTrue ? props.trueLabel : props.falseLabel}
    </Button>
  );
}

FormToggleButton.propTypes = {
  name: PropTypes.string.isRequired,

  trueValue: PropTypes.any,
  trueLabel: PropTypes.string.isRequired,

  falseValue: PropTypes.any,
  falseLabel: PropTypes.string.isRequired,
};

export function FormToggleButton(props) {
  return <Field {...props} component={ToggleButtonComponent} />;
}
