import { Observable } from "rxjs";
import React from "react";
import sprintf from "sprintf";
import { Map, Set, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AdminSupplierItemCurrencyContainer from "./AdminSupplierItemCurrencyContainer";
import AdminSupplierItemMonthlyQuotaContainer from "./AdminSupplierItemMonthlyQuotaContainer";
import AdminSupplierItemCommunicationContainer from "./AdminSupplierItemCommunicationContainer";
import AdminSupplierItemNeighborhoodsContainer from "./AdminSupplierItemPostcodesContainer";
import { isEqualData } from "../../helpers/DataUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  SUPPLIER_ITEM_CURRENCY_URL,
  SUPPLIER_ITEM_COMMUNICATION_URL,
  SUPPLIER_ITEM_MONTHLY_QUOTA_URL,
  SUPPLIER_ITEM_NEIGHBORHOODS_URL,
} from "../../constants/AdminPathConstants";
import {
  CURRENCY,
  COMMUNICATION,
  MONTHLY_QUOTA,
  NEIGHBORHOODS,
} from "../../constants/AdminSupplierItemTabs";
import { getSupplier } from "../../api/admin/AdminSupplierApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import Redirect from "../../components/router/Redirect";
import RoutingTabs from "../../components/deprecated/RoutingTabs";

const tabTypes = Set.of(MONTHLY_QUOTA, NEIGHBORHOODS, COMMUNICATION, CURRENCY);

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);
    return {
      getLocalisationMessage,
    };
  }),
  mapPropsStream(propsStream => {
    const supplierStream = propsStream
      .map(fp.flow(fp.get("params.supplierId"), fp.toFinite))
      .distinctUntilChanged()
      .switchMap(id => getSupplier(id).catch(error => Observable.of({ error })))
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
          fromJS,
        ),
      );

    return propsStream
      .combineLatest(supplierStream, (props, supplier) => ({
        ...props,
        supplier: supplier.get("payload"),
        isLoading: supplier.get("pending"),
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

AdminSupplierItem.propTypes = {
  params: PropTypes.object,
  location: PropTypes.object,
  isLoading: PropTypes.bool,
  supplier: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminSupplierItem(props) {
  const { params, getLocalisationMessage } = props;

  const monthlyQuotaUrl = sprintf(
    SUPPLIER_ITEM_MONTHLY_QUOTA_URL,
    params.supplierId,
  );

  return (
    <AdminAppLayout
      title={
        props.isLoading
          ? `${getLocalisationMessage("loading", "Loading")}...`
          : props.supplier.get("name")
      }
    >
      <Redirect when={!tabTypes.has(params.tab)} to={monthlyQuotaUrl} />

      <RoutingTabs
        width={300}
        mergeQuery={false}
        omitQueryParams={["page"]}
        tabs={[
          {
            label: getLocalisationMessage("monthly_quota", "Monthly Quota"),
            value: monthlyQuotaUrl,
          },
          {
            label: getLocalisationMessage("neighbourhoods", "Neighbourhoods"),
            value: sprintf(SUPPLIER_ITEM_NEIGHBORHOODS_URL, params.supplierId),
          },
          {
            label: getLocalisationMessage(
              "communication_settings",
              "Communication Settings",
            ),
            value: sprintf(SUPPLIER_ITEM_COMMUNICATION_URL, params.supplierId),
          },
          {
            label: getLocalisationMessage(
              "currency_settings",
              "Currency Settings",
            ),
            value: sprintf(SUPPLIER_ITEM_CURRENCY_URL, params.supplierId),
          },
        ]}
      />

      {params.tab === MONTHLY_QUOTA && (
        <AdminSupplierItemMonthlyQuotaContainer
          params={params}
          location={props.location}
        />
      )}

      {params.tab === NEIGHBORHOODS && (
        <AdminSupplierItemNeighborhoodsContainer
          params={params}
          location={props.location}
        />
      )}

      {params.tab === COMMUNICATION && (
        <AdminSupplierItemCommunicationContainer
          params={params}
          location={props.location}
        />
      )}

      {params.tab === CURRENCY && (
        <AdminSupplierItemCurrencyContainer
          supplierId={fp.toFinite(params.supplierId)}
        />
      )}
    </AdminAppLayout>
  );
}

export default enhancer(AdminSupplierItem);
