import React from "react";
import { OrderedSet } from "immutable";
import fp from "lodash/fp";
import { compose, withContext, withHandlers } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, FieldArray } from "redux-form";
import { Card, CardContent, Button, CardActions } from "@material-ui/core";
import { connect } from "react-redux";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FormCustomerAutoComplete from "../form/FormCustomerAutoComplete";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { formatText } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { ORDER_CREATED, ORDER_PICKED_UP } from "../../constants/TriggerType";

const typeOptions = OrderedSet.of("", ORDER_PICKED_UP, ORDER_CREATED);

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  withHandlers({
    onSubmit: props => values => {
      const ruleCustomers = [];

      const groupName = props.showGroupName ? values.groupName : null;
      values.ruleCustomers.forEach(item => {
        ruleCustomers.push({
          newCustomer: fp.get("newCustomer.id", item),
          oldCustomer: fp.get("oldCustomer.id", item),
        });
      });

      return props.onSubmit({
        ...values,
        groupName,
        ruleCustomers,
      });
    },
  }),
  reduxForm({ form: "FormCloneRules", enableReinitialize: true }),
  withContext(
    {
      getCachedCustomer: PropTypes.func,
      getCustomerPredictions: PropTypes.func,
    },
    props => ({
      getCachedCustomer: props.getCachedCustomer,
      getCustomerPredictions: props.getCustomerPredictions,
    }),
  ),
);

CloneRulesFormFields.propTypes = {
  fields: PropTypes.object,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function CloneRulesFormFields(props) {
  const { getLocalisationMessage } = props;
  return (
    <FlexBox gutter={8} direction="column">
      {props.fields.length > 0 && (
        <FlexBox gutter={8} direction="column">
          {props.fields.map((ruleCustomers, index) => (
            <FlexBox key={index} gutter={8}>
              <FlexBox flex={true}>
                <FormCustomerAutoComplete
                  name={`${ruleCustomers}.oldCustomer`}
                  fullWidth={true}
                  placeholder={getLocalisationMessage(
                    "old_customer",
                    "Old Customer",
                  )}
                  label={getLocalisationMessage("old_customer", "Old Customer")}
                />
              </FlexBox>

              <FlexBox flex={true}>
                <FormCustomerAutoComplete
                  name={`${ruleCustomers}.newCustomer`}
                  fullWidth={true}
                  placeholder={getLocalisationMessage(
                    "new_customer",
                    "New Customer",
                  )}
                  label={getLocalisationMessage("new_customer", "New Customer")}
                />
              </FlexBox>
            </FlexBox>
          ))}
        </FlexBox>
      )}
    </FlexBox>
  );
}

FormCloneRules.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  onDismiss: PropTypes.func,

  title: PropTypes.node,
  children: PropTypes.node,
  form: PropTypes.string,

  onRequestClose: PropTypes.func,
  className: PropTypes.string,
  validate: PropTypes.func,

  initialValues: PropTypes.object,
  enableReinitialize: PropTypes.bool,

  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,

  getCachedCustomer: PropTypes.func,
  showGroupName: PropTypes.bool,
  getLocalisationMessage: PropTypes.func.isRequired,
};

FormCloneRules.defaultProps = { showGroupName: false };

function FormCloneRules(props) {
  const { getLocalisationMessage } = props;
  return (
    <Card>
      <PageLoading isLoading={props.submitting} />

      <CardContent>
        <form onSubmit={props.handleSubmit}>
          {props.showGroupName && (
            <FlexBox flex={true} direction="column">
              <FormTextField
                name="groupName"
                fullWidth={true}
                label={getLocalisationMessage("group_name", "Group Name")}
              />
            </FlexBox>
          )}
          <FlexBox flex={true} direction="column">
            <FormTextField
              name="nameSuffix"
              fullWidth={true}
              label={getLocalisationMessage("name_suffix", "Name Suffix")}
            />
          </FlexBox>
          <FlexBox flex={true} direction="column">
            <FormSelectField
              name="triggerType"
              autoWidth={true}
              fullWidth={true}
              label={getLocalisationMessage("trigger_type", "Trigger Type")}
              options={typeOptions}
              formatOption={x =>
                getLocalisationMessage(x.toLocaleLowerCase(), formatText)
              }
            />
          </FlexBox>
          <FlexBox direction="column">
            <FieldArray
              name="ruleCustomers"
              component={CloneRulesFormFields}
              props={props}
              getLocalisationMessage={getLocalisationMessage}
            />
          </FlexBox>
          <FlexBox gutter={8} flex={true} justify="flex-end">
            <CardActions>
              <Button onClick={props.onDismiss}>
                {getLocalisationMessage("dismiss", "Dismiss")}
              </Button>
              <Button type="submit">
                {getLocalisationMessage("clone", "Clone")}
              </Button>
            </CardActions>
          </FlexBox>
        </form>
      </CardContent>
    </Card>
  );
}

export default enhancer(FormCloneRules);
