import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { formValues, reduxForm } from "redux-form";
import {
  Button,
  Card,
  CardContent as CardText,
  CardHeader,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormTextField from "../form/FormTextField";
import FlexBox, { JUSTIFY_SPACE_BETWEEN } from "../ui-core/FlexBox";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  reduxForm({
    form: "OrderDetailsDialogIssuance",
    enableReinitialize: true,
  }),
  useSheet({
    cardMedia: { margin: "0 6px", textAlign: "center" },
    card: {
      fontFamily: "Blogger Sans",
      height: "100%",
      maxHeight: "400px",
      minHeight: "300px",
    },
    image: {
      maxHeight: "144px",
      objectFit: "contain",
    },
    saveButton: {
      marginLeft: "20px",
      marginRight: "20px",
      marginBottom: "10px",
    },
  }),
  formValues({
    name: "name",
    passportId: "passportId",
  }),
);

OrderDetailsDialogIssuance.propTypes = {
  classes: PropTypes.object,
  header: PropTypes.string,
  getLocalisationMessage: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
};

function OrderDetailsDialogIssuance(props) {
  const { classes, getLocalisationMessage } = props;
  return (
    <Card className={classes.card}>
      <CardHeader title={props.header} />

      <CardText>
        <FlexBox>
          <FlexBox justify={JUSTIFY_SPACE_BETWEEN} flex={true}>
            <FormTextField
              style={{ marginRight: "1rem" }}
              name="name"
              fullWidth={true}
              label={getLocalisationMessage("recipient_name", "recipient_name")}
            />
            <FormTextField
              style={{ marginLeft: "1rem" }}
              name="passportId"
              readOnly={true}
              fullWidth={true}
              label={getLocalisationMessage("passport_id")}
            />
          </FlexBox>
        </FlexBox>
      </CardText>
      <FlexBox justify="flex-end">
        <Button className={classes.saveButton} onClick={props.handleSubmit}>
          {getLocalisationMessage("save", "Save")}
        </Button>
      </FlexBox>
    </Card>
  );
}

export default enhancer(OrderDetailsDialogIssuance);
