import { OrderedSet } from "immutable";

export const CITY_FROM = "CITY_FROM";
export const CITY_TO = "CITY_TO";
export const COD = "COD";
export const COMPANY = "COMPANY";
export const COST = "COST";
export const COUNTRY_FROM = "COUNTRY_FROM";
export const COUNTRY_TO = "COUNTRY_TO";
export const CREATED_DATE = "CREATED_DATE";
export const CURRENT_WAREHOUSE = "CURRENT_WAREHOUSE";
export const CUSTOMER = "CUSTOMER";
export const DESTINATION_WAREHOUSE = "DESTINATION_WAREHOUSE";
export const NEIGHBORHOOD_FROM = "NEIGHBORHOOD_FROM";
export const NEIGHBORHOOD_TO = "NEIGHBORHOOD_TO";
export const PAYMENT_DONE_BY = "PAYMENT_DONE_BY";
export const PAYMENT_METHOD = "PAYMENT_METHOD";
export const PARCEL_SIZE = "PARCEL_SIZE";
export const PICKUP_WAREHOUSE = "PICKUP_WAREHOUSE";
export const RATING = "RATING";
export const PRICE = "PRICE";
export const COURIER_TYPE = "COURIER_TYPE";
export const CREATED_TIME = "CREATED_TIME";
export const TIME_SLOT_DROP_OFF = "TIME_SLOT_DROP_OFF";
export const TIME_SLOT_PICK_UP = "TIME_SLOT_PICK_UP";
export const LIMIT = "LIMIT";
export const DAY_OF_WEEK = "DAY_OF_WEEK";
export const MERCHANT = "MERCHANT";
export const HAS_FETCH_ITEMS = "HAS_FETCH_ITEMS";
export const ORDER_TYPE = "ORDER_TYPE";
export const ORDER_STATUS = "ORDER_STATUS";
export const SENDER_ADDRESS_TYPE = "SENDER_ADDRESS_TYPE";
export const RECIPIENT_ADDRESS_TYPE = "RECIPIENT_ADDRESS_TYPE";
export const TRIGGER = "TRIGGER";
export const SELLER_NAME = "SELLER_NAME";
export const SELLER_ID = "SELLER_ID";
export const SENDER_NAME = "SENDER_NAME";
export const SENDER_ADDRESS = "SENDER_ADDRESS";
export const RECIPIENT_ADDRESS = "RECIPIENT_ADDRESS";
export const ITEM_VALUE = "ITEM_VALUE";
export const RTO_PRICE = "RTO_PRICE";
export const IS_RETURN = "IS_RETURN";
export const JURISDICTION_FROM = "JURISDICTION_FROM";
export const JURISDICTION_TO = "JURISDICTION_TO";
export const POSTCODE_FROM = "POSTCODE_FROM";
export const POSTCODE_TO = "POSTCODE_TO";
export const SOURCE = "SOURCE";

export default OrderedSet.of(
  CITY_FROM,
  CITY_TO,
  COD,
  COMPANY,
  COST,
  COUNTRY_FROM,
  COUNTRY_TO,
  CREATED_DATE,
  CURRENT_WAREHOUSE,
  PICKUP_WAREHOUSE,
  DESTINATION_WAREHOUSE,
  CUSTOMER,
  NEIGHBORHOOD_FROM,
  NEIGHBORHOOD_TO,
  PAYMENT_DONE_BY,
  PAYMENT_METHOD,
  PARCEL_SIZE,
  RATING,
  PRICE,
  COURIER_TYPE,
  CREATED_TIME,
  TIME_SLOT_DROP_OFF,
  TIME_SLOT_PICK_UP,
  LIMIT,
  DAY_OF_WEEK,
  MERCHANT,
  HAS_FETCH_ITEMS,
  ORDER_TYPE,
  SENDER_ADDRESS_TYPE,
  RECIPIENT_ADDRESS_TYPE,
  TRIGGER,
  SELLER_NAME,
  SELLER_ID,
  SENDER_NAME,
  SENDER_ADDRESS,
  RECIPIENT_ADDRESS,
  ITEM_VALUE,
  RTO_PRICE,
  IS_RETURN,
  JURISDICTION_FROM,
  JURISDICTION_TO,
  POSTCODE_FROM,
  POSTCODE_TO,
);
