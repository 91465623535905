import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getUser } from "../../reducers/ProfileReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  getCachedCustomer,
  getCustomerPredictions,
} from "../../api/admin/AdminCustomerApi";
import {
  getSupplier,
  updateSupplier,
  saveMarketplaces,
} from "../../api/admin/AdminSupplierApi";
import {
  getCachedReference,
  getReferencePredictions,
} from "../../api/shared/ReferenceApi";
import Tabs from "../../components/ui-core/Tabs";
import FlexBox from "../../components/ui-core/FlexBox";
import ModalPaper from "../../components/ui-core/ModalPaper";
import SupplierForm from "../../components/suppliers-core/SupplierForm";
import SupplierMarketplaceForm from "../../components/suppliers-core/SupplierMarketplaceForm";
import {
  ROLE_MARKETPLACE_ADMIN,
  ROLE_MARKETPLACE_OWNER,
} from "../../../shared/constants/Authorities";
import { hasRole } from "../../helpers/RoleUtils";

const DETAILS_TAB = "details";
const MARKETPLACE_TAB = "marketplace";

const enhancer = compose(
  renderIf(props => props.supplierId > 0),
  connect(
    state => ({
      roles: getUser(state).get("roles") || [],
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const supplierResponseStream = propsStream
      .distinctUntilKeyChanged("supplierId")
      .switchMap(props =>
        getSupplier(props.supplierId)
          .repeatWhen(() => onRequestRefreshStream)
          .catch(error => Observable.of({ error })),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    const isSuperAdminStream = propsStream
      .distinctUntilChanged(isEqualData)
      .map(
        props =>
          hasRole(props.roles, ROLE_MARKETPLACE_OWNER) ||
          hasRole(props.roles, ROLE_MARKETPLACE_ADMIN),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        supplierResponseStream,
        isSuperAdminStream,
        (props, supplierResponse, isSuperAdmin) => ({
          ...props,
          onRequestRefresh,
          isSuperAdmin,
          supplier: supplierResponse.get("payload"),
          isLoading: supplierResponse.get("pending"),
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

AdminSupplierEditDialogWrapper.propTypes = {
  tab: PropTypes.oneOf([DETAILS_TAB, MARKETPLACE_TAB]),
  onTabChange: PropTypes.func.isRequired,
  supplier: PropTypes.instanceOf(Map),
  isLoading: PropTypes.bool,
  onRequestRefresh: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  rightIcon: PropTypes.node,

  isSuperAdmin: PropTypes.bool,

  supplierId: PropTypes.number.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
};

AdminSupplierEditDialogWrapper.defaultProps = {
  tab: DETAILS_TAB,
};

function AdminSupplierEditDialogWrapper(props) {
  const { getLocalisationMessage } = props;
  return (
    <ModalPaper
      open={true}
      isLoading={props.isLoading}
      rightIcon={props.rightIcon}
      onRequestClose={props.onRequestClose}
      title={`${getLocalisationMessage("supplier", "Supplier")} #${
        props.supplierId
      }`}
    >
      <FlexBox container={8} direction="column">
        <FlexBox gutter={8} direction="column">
          {props.isSuperAdmin && false && (
            <FlexBox flex="none" direction="column">
              <Tabs
                width={300}
                value={props.tab}
                onChange={(e, v) => props.onTabChange(v)}
                tabs={[
                  {
                    label: getLocalisationMessage("details", "Details"),
                    value: DETAILS_TAB,
                  },
                  // {
                  //   label: getLocalisationMessage("marketplace", "Marketplace"),
                  //   value: MARKETPLACE_TAB,
                  // },
                ]}
              />
            </FlexBox>
          )}

          {Boolean(!props.isSuperAdmin || props.tab === DETAILS_TAB) && (
            <SupplierForm
              onDismiss={props.onRequestClose}
              item={props.supplier}
              // initialValues={toCamelCase(props.supplier)}
              onSubmit={values =>
                updateSupplier(props.supplierId, toSnakeCase(values)).catch(
                  ResponseError.throw,
                )
              }
              onSubmitSuccess={() => {
                props.onRequestClose();
                props.showSuccessMessage(
                  getLocalisationMessage(
                    "successfully_saved",
                    "Successfully saved",
                  ),
                );
              }}
              onSubmitFail={props.showErrorMessage}
              getCachedReference={getCachedReference}
              getReferencePredictions={getReferencePredictions}
              getCachedCustomer={getCachedCustomer}
              getCustomerPredictions={getCustomerPredictions}
            />
          )}

          {Boolean(
            props.isSuperAdmin && props.tab === MARKETPLACE_TAB && false,
          ) && (
            <SupplierMarketplaceForm
              initialValues={toCamelCase(props.supplier)}
              onSubmit={({ marketplaces }) =>
                saveMarketplaces(props.supplierId, marketplaces).catch(
                  ResponseError.throw,
                )
              }
              onSubmitFail={props.showErrorMessage}
              onSubmitSuccess={() => {
                props.showSuccessMessage(
                  getLocalisationMessage(
                    "successfully_saved",
                    "Successfully saved",
                  ),
                );
                props.onRequestRefresh();
              }}
            />
          )}
        </FlexBox>
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(AdminSupplierEditDialogWrapper);
