import fp from "lodash/fp";
import DataListFilter, { getListFilterSchema } from "./DataListFilter";
import {
  types,
  mapSchema,
  queryTypes,
} from "../../shared/helpers/ObjectMapper";

export const toUserFilterMapper = fp.compose(
  DataListFilter.create,

  mapSchema({
    ...getListFilterSchema(),
    authority: queryTypes.stringSeq,
    status: types.string,
    type: types.string,
    types: types.string,
    categoryId: types.ID,
    menuId: types.ID,
    supplier_ids: queryTypes.IDSeq,
    include_children: types.boolean,
  }),
);
