import { Observable } from "rxjs";
import React from "react";
import { List, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, getContext, componentFromStream } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { AutoCompleteComponent } from "./FormAutoComplete";
import { isEqualData, isEqualDataIn } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { ACTIVE } from "../../constants/OverallStatus";

const baseFilter = new DataListFilter({ page: 0, size: 20, status: ACTIVE });

const parseInput = name => ({ name });
const formatOption = fp.get("name");

const enhancer = compose(
  getContext({
    getCachedTimeSlot: PropTypes.func.isRequired,
    getTimeSlotPredictions: PropTypes.func.isRequired,
  }),
);

const TimeSlotAutoComplete = enhancer(
  componentFromStream(propsStream => {
    const valueStream = propsStream
      .distinctUntilChanged(isEqualDataIn("input.value"))
      .switchMap(({ getCachedTimeSlot, input: { value } }) =>
        value && value.id && !value.name
          ? getCachedTimeSlot(value.id)
              .map(item => ({ id: item.get("id"), name: item.get("name") }))
              .catch(() => Observable.of(value))
              .startWith(value)
          : Observable.of(value),
      );

    const optionsStream = propsStream
      .distinctUntilChanged(isEqualDataIn("input.value.name"))
      .switchMap(({ getTimeSlotPredictions, input: { value } }) =>
        getTimeSlotPredictions(baseFilter.setSearch(value && value.name))
          .map(fp.flow(fp.get("payload.data"), fp.toArray, fromJS))
          .catch(() => Observable.of(List())),
      )
      .distinctUntilChanged(isEqualData)
      .map(v => v.toJS());

    return propsStream
      .map(fp.omit(["getCachedTimeSlot", "getTimeSlotPredictions"]))
      .combineLatest(valueStream, optionsStream, (props, value, options) => (
        <AutoCompleteComponent
          {...props}
          options={options}
          filter={fp.stubTrue}
          parseInput={parseInput}
          formatOption={formatOption}
          input={{ ...props.input, value }}
        />
      ));
  }),
);

FormTimeSlotAutoComplete.propTypes = {
  name: PropTypes.string.isRequired,

  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  openOnFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  maxSearchResults: PropTypes.number,

  validate: PropTypes.func,

  hintText: PropTypes.node,
  label: PropTypes.node,
};

FormTimeSlotAutoComplete.defaultProps = {
  maxSearchResults: 10,
};

export default function FormTimeSlotAutoComplete(props) {
  return <Field {...props} component={TimeSlotAutoComplete} />;
}
