import { Observable } from "rxjs";
import React from "react";
import { OrderedSet } from "immutable";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData, toJS } from "../../helpers/DataUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { createUser, getCachedUserRoles } from "../../api/admin/AdminUserApi";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import FlexBox from "../../components/ui-core/FlexBox";
import ModalPaper from "../../components/ui-core/ModalPaper";
import UserForm from "../../components/users-core/UserForm";
import { getUserWarehouse } from "../../reducers/ProfileReducer";

const enhancer = compose(
  renderIf("open"),
  connect(
    (state) => ({
      userWarehouse: getUserWarehouse(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  mapPropsStream((propsStream) => {
    const userRolesResponseStream = getCachedUserRoles()
      .catch(() => Observable.of(OrderedSet()))
      .map((list) => list.toOrderedSet());

    return propsStream
      .combineLatest(userRolesResponseStream, (props, userRoles) => ({
        ...props,
        userRoles,
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

AdminUserCreateDialogWrapper.propTypes = {
  open: PropTypes.bool.isRequired,
  userRoles: PropTypes.instanceOf(OrderedSet),
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired,
  userWarehouse: PropTypes.object,

  onSupplierClick: PropTypes.func,
  onCreateSupplierClick: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function AdminUserCreateDialogWrapper(props) {
  return (
    <ModalPaper
      title={props.getLocalisationMessage("create_user", "Create User")}
      open={props.open}
      onRequestClose={props.onRequestClose}
    >
      <FlexBox container={8} direction="column">
        <UserForm
          initialValues={{
            warehouse: toJS(props.userWarehouse),
          }}
          disableEmail={false}
          onDismiss={props.onRequestClose}
          onSubmit={(values) => {
            const roles = values.roles.map((role) =>
              props.userRoles
                .find((uRole) => uRole.get("name") === role)
                .toJS(),
            );
            return createUser(
              toSnakeCase({ ...values, login: values.email, roles }),
            ).catch(ResponseError.throw);
          }}
          onSubmitSuccess={() => {
            props.onRequestClose();
            props.showSuccessMessage(
              props.getLocalisationMessage(
                "Successfully saved",
                "Successfully saved",
              ),
            );
          }}
          onSubmitFail={props.showErrorMessage}
          getCachedSupplier={getCachedSupplier}
          getSupplierPredictions={getSupplierPredictions}
          // getCachedWarehouse={getCachedWarehouse}
          // getWarehousePredictions={getWarehousePredictions}
          onSupplierClick={props.onSupplierClick}
          onCreateSupplierClick={props.onCreateSupplierClick}
          userRoles={props.userRoles.map((item) => item.get("name"))}
        />
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(AdminUserCreateDialogWrapper);
