import { Observable } from "rxjs";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { fromJS, OrderedMap } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { FieldArray } from "redux-form";
import { IconButton, CircularProgress, Fab, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { Add, Cancel, CloudDownload, Visibility } from "@material-ui/icons";
import { getValue, isEqualData } from "../../helpers/DataUtils";
import { stringifyArray } from "../../helpers/SerializeUtils";
import { getIsRTL } from "../../reducers/LocalizationReducer";
import { uploadFile, getFileUrlList } from "../../api/shared/FileApi";
import { muted2 } from "../../../shared/theme/main-theme";
import FlexBox from "../ui-core/FlexBox";

const enhancer = compose(
  useSheet({
    root: {
      padding: "12px",
      position: "relative",
      marginBottom: "24px",
      width: "100%",
      border: `1px dashed ${muted2}`,
    },
    label: {
      color: "rgba(0, 0, 0, 0.54)",
      userSelect: "none",
      fontSize: "12px",
      marginTop: "-6px",
      marginLeft: "-6px",
      marginBottom: "-6px",
    },
    button: {
      right: "12px",
      bottom: "-20px",
      position: "absolute",
      backgroundColor: "#ffc107",
      color: "white",
      "& input": { display: "none" },
    },

    buttonRtl: { right: "initial", left: "12px", extend: "button" },

    imageRow: {
      width: "100%",
    },
    imageRowScroll: {
      display: "flex",
      flexWrap: "wrap",
      overflowX: "auto",
      overflowY: "hidden",
    },

    image: { maxHeight: "100%", maxWidth: "100%", userSelect: "none" },
    imageItem: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "64px",
      maxHeight: "64px",
      minWidth: "64px",
      marginTop: "12px",
      marginLeft: "12px",
      position: "relative",
      width: "90%",
    },

    imageButton: {
      padding: 0,
      width: "24px",
      height: "24px",

      top: "-12px",
      right: "-12px",
      position: "absolute",

      "&:after": {
        zIndex: -1,
        top: "7px",
        left: "7px",
        right: "7px",
        bottom: "7px",
        content: '""',
        position: "absolute",
        backgroundColor: "#fff",
      },

      "& svg": { fill: "#ff2b2b" },
    },

    imageProgress: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  }),
  connect(state => ({
    isRTL: getIsRTL(state),
  })),
  mapPropsStream(propsStream => {
    const { handler: onUpload, stream: onUploadStream } = createEventHandler();

    const imageListStream = onUploadStream
      .map(fp.flow(fp.get("target.files"), fp.map(fp.identity)))
      .mergeMap(files => Observable.from(files))
      .mergeMap(
        file =>
          uploadFile(file)
            .retryWhen(error => error.delay(300))
            .map(
              fp.flow(r =>
                fromJS(r).update("payload", p => (p ? p.get("data") : p)),
              ),
            ),
        (file, response) => [file, response],
      )
      .withLatestFrom(propsStream)
      .do(([[, response], props]) => {
        if (response.get("payload")) {
          props.fields.push(response.get("payload"));
        }
      })
      .map(fp.nth(0))
      .startWith(OrderedMap())
      .scan((acc, [file, response]) =>
        response.get("pending") ? acc.set(file, response) : acc.delete(file),
      )
      .map(item => item.toList())
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(imageListStream, (props, imageList) => ({
        ...props,
        imageList,
        onUpload,
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

const ImageUploadComponent = enhancer(
  ({ classes, imageList, fields, onUpload, isRTL, ...custom }) => {
    const [imageUrlList, setImageUrlList] = useState([]);

    useEffect(() => {
      if (fields.length > 0 && fields.length < 5) {
        getFileUrlList({
          size: 50,
          page: 0,
          attachment_ids: stringifyArray(
            fields.getAll().map(f => getValue(f, "id")),
          ),
        }).then(res => {
          setImageUrlList(res.data);
        });
      }
    }, [fields.length]);

    return (
      <div className={classes.root}>
        <div className={classes.label}>{custom.label}</div>

        <div className={classes.imageRow}>
          <div className={classes.imageRowScroll}>
            {_.map(fields.getAll(), (url, index) => (
              <div key={index} className={classes.imageItem}>
                {imageUrlList && imageUrlList.length > 0 && (
                  <FlexBox flex={true} justify="center">
                    <FlexBox flex={true} align="center">
                      <Button
                        fullWidth={true}
                        variant="outlined"
                        disabled={true}
                        style={{ height: 45 }}
                      >
                        {getValue(url, "name")}
                      </Button>
                    </FlexBox>

                    <FlexBox justify="flex-end">
                      <IconButton
                        onClick={() => {
                          fetch(imageUrlList[index])
                            .then(response => response.blob())
                            .then(myBlob => {
                              const fileType = myBlob.type;

                              const allowedTypes = [
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // Word (.docx)
                                "application/vnd.ms-word", // Word (.doc)
                                "text/csv", // CSV (.csv)
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // Excel (.xlsx)
                                "application/vnd.ms-excel", // Excel (.xls)
                                "application/vnd.openxmlformats-officedocument.presentationml.presentation", // PowerPoint (.pptx)
                                "application/vnd.ms-powerpoint", // PowerPoint (.ppt)
                              ];

                              if (allowedTypes.includes(fileType)) {
                                const objectURL = URL.createObjectURL(myBlob);

                                const a = document.createElement("a");
                                a.href = objectURL;
                                a.download = getValue(url, "name"); // Fayl nomini kiriting
                                document.body.appendChild(a);
                                a.click();
                                document.body.removeChild(a);

                                URL.revokeObjectURL(objectURL);
                              } else {
                                let objectURL = URL.createObjectURL(myBlob);
                                if (myBlob && myBlob.size > 0) {
                                  const iframe = document.createElement(
                                    "iframe",
                                  );
                                  iframe.style.display = "none";
                                  document.body.appendChild(iframe);
                                  iframe.src = objectURL;
                                  objectURL = URL.revokeObjectURL(myBlob);
                                  window.setTimeout(() => {
                                    iframe.contentWindow.print();
                                  }, 1000);
                                }
                              }
                            });
                        }}
                      >
                        <Visibility />
                      </IconButton>
                      <IconButton href={imageUrlList[index]}>
                        <CloudDownload />
                      </IconButton>
                    </FlexBox>

                    <FlexBox>
                      <IconButton onClick={() => fields.remove(index)}>
                        <Cancel color="error" />
                      </IconButton>
                    </FlexBox>
                  </FlexBox>
                )}
              </div>
            ))}
            {imageList
              .map((item, index) => (
                <div key={index} className={classes.imageItem}>
                  <CircularProgress />
                </div>
              ))
              .toArray()}
          </div>
        </div>
        <input
          id="file-upload-v2-component"
          type="file"
          onChange={onUpload}
          style={{ display: "none" }}
        />
        {fields.length < 1 && (
          <label htmlFor="file-upload-v2-component">
            <Fab
              size="small"
              mini={true}
              className={isRTL ? classes.buttonRtl : classes.button}
              component="span"
            >
              <Add />
            </Fab>
          </label>
        )}
      </div>
    );
  },
);

FormMultipleIINNUpload.propTypes = {
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
};

export default function FormMultipleIINNUpload(props) {
  return <FieldArray {...props} component={ImageUploadComponent} />;
}
