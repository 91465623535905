import React from "react";
import { Map } from "immutable";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  List as Lists,
  Avatar,
  Dialog,
  ListItem,
  Button,
  ListItemAvatar,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import { ReportProblem } from "@material-ui/icons";
import { red } from "@material-ui/core/colors";
import FormTextField from "../form/FormTextField";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { getMessages } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),
  renderIf("open"),
  reduxForm({ form: "OrderMultiBoxEditDialogForm", enableReinitialize: true }),
);

OrderMultiBoxEditDialogForm.propTypes = {
  isLoading: PropTypes.bool,
  initialValues: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,

  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,

  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  i18n: PropTypes.instanceOf(Map),
};

function OrderMultiBoxEditDialogForm(props) {
  return (
    <Dialog open={true} onClose={props.onRequestClose}>
      <PageLoading isLoading={props.isLoading || props.submitting} />
      <DialogTitle>{props.i18n.get("update_shipment_box_count")}</DialogTitle>
      <DialogContent>
        <FlexBox container={8} direction="column">
          <FlexBox gutter={8} flex={true} align="center">
            <Lists>
              <ListItem
                disabled={true}
                style={{ color: red[700], lineHeight: "18px" }}
              >
                <ListItemAvatar>
                  <Avatar
                    size={60}
                    style={{
                      marginLeft: "-10px",
                      color: red[700],
                      backgroundColor: "white",
                    }}
                  >
                    <ReportProblem />
                  </Avatar>
                </ListItemAvatar>
                <strong>{props.i18n.get("IMPORTANT")}:</strong>{" "}
                {props.i18n.get("this_will_erase_the_previous_box_scans")}
              </ListItem>
            </Lists>
          </FlexBox>
          <FlexBox gutter={8} flex={true} align="center">
            <FlexBox>{props.i18n.get("box_count")}: </FlexBox>
            <FlexBox flex={true}>
              <FormTextField name="pieceCount" fullWidth={true} type="number" />
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={props.handleSubmit}>
            {props.i18n.get("update")}
          </Button>
          <Button onClick={props.onRequestClose}>
            {props.i18n.get("dismiss")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(OrderMultiBoxEditDialogForm);
