import React, { useEffect, useRef, useState } from "react";
import { OrderedSet } from "immutable";
import fp from "lodash/fp";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { formValueSelector, reduxForm } from "redux-form";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  InputAdornment,
  makeStyles,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import {
  DATE_URL_FORMAT,
  formatDate,
  formatText,
} from "../../helpers/FormatUtils";
import { getIsRTL, getMessage } from "../../reducers/LocalizationReducer";
import { CASH_CLIENTS, MERCHANTS } from "../../constants/MerchantTypes";
import { ACTIVE, INACTIVE } from "../../constants/OverallStatus";
import FormJMAutoComplete from "../form/FormJMAutoComplete";
import PostcodeAutoCompleteForOrder from "../form/PostcodeAutoCompleteForOrder";
import { getValue } from "../../helpers/DataUtils";
import MuiPhoneNumber from "material-ui-phone-number-uzpost";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../ui-core/CustomButton";
import {
  ArrowRightAlt,
  Cancel as NavigationCancel,
  Done,
  KeyboardArrowLeft,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import { CUSTOMER_LIST_ALL_URL } from "../../constants/AdminPathConstants";
import FormDateField from "../form/FormDateField";
import FormCustomToggle from "../form/FormCustomToggleV2";
import { showErrorMessage } from "../../reducers/NotificationsReducer";
import {
  createOtpValidator,
  createPaymentsValidator,
  createValidateStringValidator,
} from "../../helpers/FormUtils";
import { avvalSearchUrl } from "../orders-core/CommissionTINDialog";
import FormChipAutoComplete from "../form/FormChipAutoComplete";
import OrderPaymentTypes from "../../constants/OrderPaymentTypes";
import { formatLocalisedPaymentType } from "../../helpers/OrderHelper";
import FormCheckbox from "../form/FormCheckbox";
import { checkCompanyInn } from "../../api/admin/AdminCustomerApi";
import { validatePasswordForLocalisation } from "../../helpers/ValidateUtils";
import ConfirmDialogWithoutClose from "../deprecated/ConfirmDialogWithoutClose";
import FormMultipleFileUpload from "../form/FormMultipleFileUpload";
import FormMultipleIINNUpload from "../form/FormMultipleIINNUpload";

const Statuses = OrderedSet([ACTIVE, INACTIVE]);
const valueSelector = formValueSelector("AdminCustomerForm");
const customerGenderOptions = OrderedSet.of("male", "female");

const enhancer = compose(
  connect(
    state => ({
      isRTL: getIsRTL(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage },
  ),
  reduxForm({
    form: "AdminCustomerForm",
    enableReinitialize: true,
    validate: (values, { getLocalisationMessage, edit }) => ({
      login: !values.login && getLocalisationMessage("this_field_is_required"),
      source:
        !values.source && getLocalisationMessage("this_field_is_required"),
      merchantInn:
        values.merchantType === MERCHANTS
          ? !values.merchantInn
            ? getLocalisationMessage("this_field_is_required")
            : values.merchantInn &&
              !(
                values.merchantInn.length === 9 ||
                values.merchantInn.length === 14
              )
            ? getLocalisationMessage(
                "please_enter_a_number_in_this_field_that_has_either_9_or_14_digits",
                "Please enter a number in this field that has either 9 or 14 digits.",
              )
            : null
          : null,
      password:
        !edit &&
        validatePasswordForLocalisation(
          values.password,
          getLocalisationMessage,
        ),
      confirmPassword:
        values.password &&
        values.confirmPassword !== values.password &&
        getLocalisationMessage(
          "passwords_are_not_equal",
          "Passwords are not equal",
        ),
      firstName:
        !values.firstName && getLocalisationMessage("this_field_is_required"),
      lastName:
        !values.lastName && getLocalisationMessage("this_field_is_required"),
      phone:
        values.phone &&
        values.phone.length < 8 &&
        getLocalisationMessage("this_field_is_required"),
      merchantType:
        fp.isEmpty(values.merchantType) &&
        getLocalisationMessage("select_merchant_type", "Select Merchant Type"),
    }),
  }),
  getContext({
    setLocation: PropTypes.func.isRequired,
  }),
  connect(state => ({
    values: valueSelector(
      state,
      "jurisdiction",
      "postcode",
      "phone",
      "merchantType",
      "merchant",
      "merchantInn",
      "secondPhone",
      "company",
      "canCreateCodOrder",
    ),
  })),
);

const useStyles = makeStyles({
  prefixFieldError: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f44336",
    },
  },
  prefixField: {},
});

const AdminCustomerForm = ({
  getLocalisationMessage,
  handleSubmit,
  submitting,
  values,
  change,
  setLocation,
  setShow,
  reset,
  ...props
}) => {
  const [postcodeOptions, setPostcodeOptions] = useState([]);
  const { postcode, jurisdiction, phone, secondPhone } = values;
  const jurisdictionId = getValue(jurisdiction, "id");
  const countryCode = getValue(jurisdiction, "country_code");
  const [open, setOpen] = React.useState(false);
  const [openLoginString, setOpenLoginString] = React.useState("");

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [error, setError] = useState(null);

  const classes = useStyles();

  useEffect(() => {
    if (values.canCreateCodOrder) {
      if (
        values.merchantType === MERCHANTS &&
        values.merchantInn &&
        (values.merchantInn.length === 14 || values.merchantInn.length === 9)
      ) {
        const options = {
          method: "POST",
          headers: {
            Authorization: `Basic bG9naXN0aWthX3NlYXJjaDpFZGUkeWEwMzRjdSFXMzBMdFE/ZG8=`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            search_inn_comm: {
              comm_inn: values.merchantInn,
            },
          }),
        };

        fetch(avvalSearchUrl, options)
          .then(response => response.json())
          .then(res => {
            if (res && res.mess === "success") {
              setError(null);
            } else {
              setError(
                getLocalisationMessage("inn_is_not_found", "INN is not found"),
              );
            }
          })
          .catch(err => {
            props.showErrorMessage(err);
          });
      } else {
        setError(
          getLocalisationMessage("inn_is_not_found", "INN is not found"),
        );
      }
    }
  }, [values.merchantInn, values.canCreateCodOrder]);

  useEffect(() => {
    if (
      values.merchantType === MERCHANTS &&
      values.merchantInn &&
      (values.merchantInn.length === 14 || values.merchantInn.length === 9)
    ) {
      checkCompanyInn(values.merchantInn)
        .then(res => {
          setOpen(res.data);
        })
        .catch(() => setOpen(false));
    }
  }, [values.merchantInn]);

  const innPrefixRef = useRef(null);
  const innRef = useRef(null);

  useEffect(() => {
    if (values.merchant) {
      change("merchantType", MERCHANTS);
      change("company.unlimited", true);
      if (setShow) {
        setShow(true);
      }
    } else {
      change("merchantType", CASH_CLIENTS);
      if (setShow) {
        setShow(false);
      }
    }
  }, [values.merchant]);

  const [errorField, setErrorField] = useState(false);

  return (
    <Card style={{ width: "100%", height: "100%" }}>
      <PageLoading isLoading={submitting} />

      <ConfirmDialogWithoutClose
        modal={true}
        confirmButtonLabel={getLocalisationMessage("yes", "Yes")}
        dismissButtonLabel={getLocalisationMessage("no", "No")}
        onConfirm={() => {
          setOpenLoginString(true);
          setOpen(false);
          setErrorField(true);
          setTimeout(() => {
            if (innPrefixRef.current) {
              innPrefixRef.current.focus();
            }
          }, 100);
        }}
        open={open}
        onRequestClose={() => {
          setOpen(false);
          setTimeout(() => {
            if (innRef.current) {
              innRef.current.focus();
            }
          }, 100);
        }}
      >
        {`${getLocalisationMessage(
          "entered_inn_is_already_existed_do_you_want_to_create_new_sub_branch_for_it",
          "Entered INN is already existed. Do you want to create new sub-branch for it",
        )}?`}
      </ConfirmDialogWithoutClose>

      <form
        autoComplete="off"
        onSubmit={handleSubmit}
        style={{ height: "100%" }}
      >
        <FlexBox
          justify="space-between"
          direction="column"
          style={{ height: "100%" }}
        >
          <FlexBox
            direction="column"
            style={{
              height: "100%",
              overflowY: values.merchantType === MERCHANTS ? "auto" : "hidden",
            }}
          >
            <FlexBox justify="center" align="center" style={{ marginTop: 13 }}>
              <FormCustomToggle
                disabled={props.edit}
                leftLabel={getLocalisationMessage("cash_client", "Cash Client")}
                rightLabel={getLocalisationMessage("merchant", "Merchant")}
                name="merchant"
                onChange={() => reset()}
              />
            </FlexBox>

            <CardHeader
              title={
                values.merchantType === CASH_CLIENTS ? (
                  getLocalisationMessage("personal_details", "Personal Details")
                ) : (
                  <FlexBox align="center" flex={true} style={{ gap: 16 }}>
                    <FlexBox justify="center" align="center">
                      {" "}
                      {getLocalisationMessage(
                        "company_details",
                        "Company Details",
                      )}
                    </FlexBox>
                    <FlexBox>
                      <FormCheckbox
                        name="canCreateCodOrder"
                        label={getLocalisationMessage(
                          "can_create_order_with_cod",
                          "Can create order with COD",
                        )}
                        onChange={() => {
                          setError(null);
                          change("merchantInn", null);
                          change("merchantInnString", null);
                          change("login", null);
                        }}
                      />
                    </FlexBox>
                  </FlexBox>
                )
              }
            />
            {values.merchantType === CASH_CLIENTS && (
              <CardContent>
                <FlexBox style={{ gap: 13 }} flex={true} direction="column">
                  <FlexBox gutter={8} flex={true}>
                    <FlexBox flex={true}>
                      <FormTextField
                        name="firstName"
                        margin="normal"
                        fullWidth={true}
                        label={`${getLocalisationMessage("name")}*`}
                      />
                    </FlexBox>
                    <FlexBox flex={true}>
                      <FormTextField
                        name="lastName"
                        margin="normal"
                        fullWidth={true}
                        label={getLocalisationMessage("last_name", "Last name")}
                      />
                    </FlexBox>
                    <FlexBox flex={true}>
                      <MuiPhoneNumber
                        disableAreaCodes={true}
                        margin="normal"
                        isValid={value => value.length > 8}
                        defaultCountry={
                          countryCode ? countryCode.toLowerCase() : "uz"
                        }
                        name="phone"
                        value={phone || ""}
                        onChange={v => {
                          change("phone", v);
                          change("login", v);
                        }}
                        variant={OUTLINED}
                        size="small"
                        fullWidth={true}
                        label={`${getLocalisationMessage(
                          "phone_number",
                          "Phone Number",
                        )}*`}
                      />
                      <FormTextField
                        style={{ display: "none" }}
                        name="phone"
                        label={getLocalisationMessage(
                          "phone_number",
                          "Phone Number",
                        )}
                      />
                    </FlexBox>
                    <FlexBox justify="center" align="center">
                      <ArrowRightAlt
                        fontSize="large"
                        style={{ marginTop: 13 }}
                      />
                    </FlexBox>
                    <FlexBox flex={true}>
                      <FormTextField
                        disabled={true}
                        name="login"
                        margin="normal"
                        fullWidth={true}
                        label={`${getLocalisationMessage("login")}*`}
                        readOnly={true}
                      />
                    </FlexBox>
                  </FlexBox>

                  <FlexBox gutter={8} flex={true}>
                    <FlexBox flex={true} style={{ gap: 13 }}>
                      <FormSelectField
                        fullWidth={true}
                        name="gender"
                        autoWidth={true}
                        options={customerGenderOptions}
                        formatOption={x =>
                          getLocalisationMessage(x) || formatText(x)
                        }
                        label={getLocalisationMessage("gender")}
                      />
                    </FlexBox>
                    <FlexBox flex={true}>
                      <FormDateField
                        name="birthday"
                        fullWidth={true}
                        formatValue={DATE_URL_FORMAT}
                        formatDate={date => formatDate(date, DATE_URL_FORMAT)}
                        hintText={getLocalisationMessage(
                          "birthday",
                          "Birthday",
                        )}
                      />
                    </FlexBox>
                    <FlexBox flex={true} style={{ gap: 13 }}>
                      <FormSelectField
                        fullWidth={true}
                        name="status"
                        autoWidth={true}
                        options={Statuses}
                        formatOption={x =>
                          getLocalisationMessage(x) || formatText(x)
                        }
                        label={getLocalisationMessage("status")}
                      />
                    </FlexBox>
                    <FlexBox flex={true} style={{ gap: 13 }}>
                      <FormSelectField
                        fullWidth={true}
                        name="source"
                        autoWidth={true}
                        options={["UZPOST", "HYBRID"]}
                        formatOption={x =>
                          getLocalisationMessage(x) || formatText(x)
                        }
                        label={getLocalisationMessage("customer_type")}
                      />
                    </FlexBox>
                  </FlexBox>

                  <FlexBox gutter={8} flex={true}>
                    <FlexBox flex={true} style={{ gap: 13 }}>
                      <FormJMAutoComplete
                        name="jurisdiction"
                        margin="normal"
                        levelIds={[3]}
                        label={getLocalisationMessage("district", "District")}
                        fullWidth={true}
                        renderOption={option => (
                          <FlexBox direction="column">
                            <span>{option.name}</span>
                            <span
                              style={{
                                fontSize: ".8rem",
                                fontStyle: "italic",
                              }}
                            >
                              {option.value.hierarchy.map((v, i, arr) =>
                                i === arr.length - 1 ? v.name : `${v.name} > `,
                              )}
                            </span>
                          </FlexBox>
                        )}
                      />
                    </FlexBox>
                    <FlexBox flex={true} style={{ gap: 13 }}>
                      <PostcodeAutoCompleteForOrder
                        immediatelyShowError={postcode}
                        name="postcode"
                        margin="normal"
                        fullWidth={true}
                        jurisdictionId={jurisdictionId}
                        jurisdiction={jurisdiction}
                        disabled={!jurisdictionId}
                        postcodes={postcodeOptions}
                        getPostcodeOptions={v => setPostcodeOptions(v)}
                        // onChange={(e, v) => setValidationLevel(v, "PVLevel")}
                        label={getLocalisationMessage("index", "Index")}
                        hintText={getLocalisationMessage("type_to_search")}
                      />
                    </FlexBox>

                    <FlexBox flex={true}>
                      <FormTextField
                        name="address.address"
                        margin="normal"
                        fullWidth={true}
                        label={getLocalisationMessage("address")}
                      />
                    </FlexBox>

                    <FlexBox flex={true}>
                      <FormTextField
                        name="email"
                        margin="normal"
                        fullWidth={true}
                        label={getLocalisationMessage("email")}
                      />
                    </FlexBox>
                  </FlexBox>

                  <FlexBox gutter={8} flex={true} style={{ marginTop: 13 }}>
                    <FlexBox flex={true}>
                      <FormTextField
                        name="password"
                        type={showPassword ? "text" : "password"}
                        fullWidth={true}
                        label={`${getLocalisationMessage(
                          "password",
                          "Password",
                        )} *`}
                        withOutInputProps={false}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FlexBox>
                    <FlexBox flex={true}>
                      <FormTextField
                        name="confirmPassword"
                        type={showPassword ? "text" : "password"}
                        fullWidth={true}
                        label={`${getLocalisationMessage(
                          "confirm_password",
                          "Confirm Password",
                        )} *`}
                        withOutInputProps={false}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
              </CardContent>
            )}

            {values.merchantType === MERCHANTS && (
              <CardContent>
                <FlexBox style={{ gap: 13 }} direction="column">
                  <FlexBox flex={true} gutter={8}>
                    <FlexBox
                      flex={true}
                      wrap={openLoginString}
                      style={{ gap: 13 }}
                      justify="space-between"
                    >
                      <FlexBox
                        style={{ width: openLoginString ? "67%" : "100%" }}
                      >
                        <FormTextField
                          inputRef={innRef}
                          fullWidth={true}
                          name="merchantInn"
                          label={`${getLocalisationMessage(
                            "corporate_inn_pinfl",
                            "Corporate INN/PINFL",
                          )} *`}
                          type="number"
                          onInput={e => {
                            // eslint-disable-next-line radix
                            e.target.value = e.target.value
                              .toString()
                              .slice(0, 14);
                          }}
                          onChange={e => {
                            change("login", e.target.value);
                            setOpenLoginString(false);
                          }}
                          validate={createOtpValidator(error)}
                        />
                      </FlexBox>
                      {openLoginString && (
                        <FlexBox
                          style={{ width: "20%" }}
                          className={
                            errorField
                              ? classes.prefixFieldError
                              : classes.prefixField
                          }
                        >
                          <FormTextField
                            fullWidth={true}
                            inputRef={innPrefixRef}
                            name="merchantInnString"
                            type="number"
                            onInput={e => {
                              // eslint-disable-next-line radix
                              e.target.value = e.target.value
                                .toString()
                                .slice(0, 2);
                            }}
                            validate={createValidateStringValidator(
                              getLocalisationMessage("this_field_is_required"),
                            )}
                            onChange={e => {
                              setErrorField(false);

                              change(
                                "login",
                                `${values.merchantInn}${e.target.value}`,
                              );
                            }}
                          />
                        </FlexBox>
                      )}
                    </FlexBox>
                    <FlexBox flex={true}>
                      <FlexBox
                        justify="center"
                        align="center"
                        style={{ height: 45 }}
                      >
                        <ArrowRightAlt fontSize="large" />
                      </FlexBox>
                      <FlexBox flex={true}>
                        <FormTextField
                          fullWidth={true}
                          name="login"
                          disabled={true}
                          label={`${getLocalisationMessage("login")} *`}
                        />
                      </FlexBox>
                    </FlexBox>
                    <FlexBox flex={true}>
                      <FormTextField
                        fullWidth={true}
                        name="company.name"
                        label={`${getLocalisationMessage(
                          "company_name",
                          "Company name",
                        )} *`}
                        validate={createValidateStringValidator(
                          getLocalisationMessage("this_field_is_required"),
                        )}
                      />
                    </FlexBox>
                    <FlexBox flex={true}>
                      <FormSelectField
                        fullWidth={true}
                        name="status"
                        autoWidth={true}
                        options={Statuses}
                        formatOption={x =>
                          getLocalisationMessage(x) || formatText(x)
                        }
                        label={`${getLocalisationMessage("status")} *`}
                      />
                    </FlexBox>
                    <FlexBox flex={true}>
                      <FormSelectField
                        fullWidth={true}
                        name="source"
                        autoWidth={true}
                        options={["UZPOST", "HYBRID"]}
                        formatOption={x =>
                          getLocalisationMessage(x) || formatText(x)
                        }
                        label={`${getLocalisationMessage(
                          "customer_type",
                          "Customer type",
                        )} *`}
                      />
                    </FlexBox>
                  </FlexBox>

                  <FlexBox gutter={8} flex={true}>
                    <FlexBox flex={true}>
                      <FormJMAutoComplete
                        name="jurisdiction"
                        margin="normal"
                        levelIds={[3]}
                        label={getLocalisationMessage("district", "District")}
                        fullWidth={true}
                        renderOption={option => (
                          <FlexBox direction="column">
                            <span>{option.name}</span>
                            <span
                              style={{
                                fontSize: ".8rem",
                                fontStyle: "italic",
                              }}
                            >
                              {option.value.hierarchy.map((v, i, arr) =>
                                i === arr.length - 1 ? v.name : `${v.name} > `,
                              )}
                            </span>
                          </FlexBox>
                        )}
                      />
                    </FlexBox>
                    <FlexBox flex={true}>
                      <PostcodeAutoCompleteForOrder
                        immediatelyShowError={postcode}
                        name="postcode"
                        margin="normal"
                        fullWidth={true}
                        jurisdictionId={jurisdictionId}
                        jurisdiction={jurisdiction}
                        disabled={!jurisdictionId}
                        postcodes={postcodeOptions}
                        getPostcodeOptions={v => setPostcodeOptions(v)}
                        // onChange={(e, v) => setValidationLevel(v, "PVLevel")}
                        label={getLocalisationMessage("index", "Index")}
                        hintText={getLocalisationMessage("type_to_search")}
                      />
                    </FlexBox>

                    <FlexBox flex={true}>
                      <FormTextField
                        name="address.address"
                        margin="normal"
                        fullWidth={true}
                        label={getLocalisationMessage("address")}
                      />
                    </FlexBox>
                    <FlexBox flex={true}>
                      <FormTextField
                        name="company.website"
                        margin="normal"
                        fullWidth={true}
                        label={getLocalisationMessage(
                          "company_website",
                          "Company website",
                        )}
                      />
                    </FlexBox>
                  </FlexBox>

                  <FlexBox
                    gutter={8}
                    flex={true}
                    style={{ marginTop: "-10px" }}
                  >
                    <FlexBox flex={true}>
                      <FormTextField
                        name="firstName"
                        margin="normal"
                        fullWidth={true}
                        label={`${getLocalisationMessage(
                          "company_user_name",
                          "Company user name",
                        )} *`}
                      />
                    </FlexBox>
                    <FlexBox flex={true}>
                      <FormTextField
                        name="lastName"
                        margin="normal"
                        fullWidth={true}
                        label={`${getLocalisationMessage(
                          "company_user_last_name",
                          "Company user last name",
                        )} *`}
                      />
                    </FlexBox>
                    <FlexBox flex={true}>
                      <MuiPhoneNumber
                        disableAreaCodes={true}
                        margin="normal"
                        isValid={value => value.length > 8}
                        defaultCountry={
                          countryCode ? countryCode.toLowerCase() : "uz"
                        }
                        name="phone"
                        value={phone || ""}
                        onChange={v => {
                          change("phone", v);
                        }}
                        variant={OUTLINED}
                        size="small"
                        fullWidth={true}
                        label={`${getLocalisationMessage(
                          "phone_number",
                          "Phone Number",
                        )}*`}
                      />
                      <FormTextField
                        style={{ display: "none" }}
                        name="phone"
                        label={getLocalisationMessage(
                          "phone_number",
                          "Phone Number",
                        )}
                      />
                    </FlexBox>
                    <FlexBox flex={true}>
                      <MuiPhoneNumber
                        disableAreaCodes={true}
                        margin="normal"
                        defaultCountry={
                          countryCode ? countryCode.toLowerCase() : "uz"
                        }
                        name="secondPhone"
                        value={secondPhone || ""}
                        onChange={v => {
                          change("secondPhone", v);
                        }}
                        variant={OUTLINED}
                        size="small"
                        fullWidth={true}
                        label={getLocalisationMessage(
                          "second_phone_number",
                          "Second Phone Number",
                        )}
                      />
                      <FormTextField
                        style={{ display: "none" }}
                        name="secondPhone"
                        label={getLocalisationMessage(
                          "second_phone_number",
                          "Phone Number",
                        )}
                      />
                    </FlexBox>

                    <FlexBox flex={true}>
                      <FormTextField
                        name="email"
                        margin="normal"
                        fullWidth={true}
                        label={getLocalisationMessage("email")}
                      />
                    </FlexBox>
                  </FlexBox>

                  <FlexBox gutter={8} flex={true}>
                    <FlexBox flex={true}>
                      <FormTextField
                        name="password"
                        type={showPassword ? "text" : "password"}
                        fullWidth={true}
                        label={`${getLocalisationMessage(
                          "password",
                          "Password",
                        )} *`}
                        withOutInputProps={false}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FlexBox>
                    <FlexBox flex={true}>
                      <FormTextField
                        name="confirmPassword"
                        type={showPassword ? "text" : "password"}
                        fullWidth={true}
                        label={`${getLocalisationMessage(
                          "confirm_password",
                          "Confirm Password",
                        )} *`}
                        withOutInputProps={false}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
              </CardContent>
            )}

            {values.merchantType === MERCHANTS && (
              <React.Fragment>
                <CardHeader
                  title={getLocalisationMessage("contract", "Contract")}
                />

                <CardContent>
                  <FlexBox style={{ gap: 16 }} direction="column" flex={true}>
                    <FlexBox style={{ gap: 16 }} flex={true}>
                      <FlexBox flex={true} direction="column">
                        <FormMultipleFileUpload
                          fullWidth={true}
                          name="company.contractAttachments"
                          variant="outlined"
                          label={getLocalisationMessage(
                            "upload_contract",
                            "Upload Contract",
                          )}
                        />

                        <FormMultipleIINNUpload
                          fullWidth={true}
                          name="company.innAttachments"
                          variant="outlined"
                          label={getLocalisationMessage(
                            "upload_inn",
                            "Upload INN",
                          )}
                        />
                      </FlexBox>

                      <FlexBox
                        style={{ gap: 16, maxHeight: 300 }}
                        flex={true}
                        direction="column"
                      >
                        <FlexBox flex={true} style={{ gap: 13 }} align="center">
                          <FormDateField
                            fullWidth={true}
                            name="company.contractStartDate"
                            hintText={getLocalisationMessage(
                              "contract_created_date",
                              "Contract created date",
                            )}
                          />

                          <IconButton
                            onClick={() =>
                              change("company.contractStartDate", null)
                            }
                          >
                            <NavigationCancel />
                          </IconButton>
                        </FlexBox>

                        <FlexBox flex={true} style={{ gap: 13 }} align="center">
                          <FormDateField
                            fullWidth={true}
                            name="company.contractEndDate"
                            hintText={getLocalisationMessage(
                              "contract_expiration_date",
                              "Contract expiration date",
                            )}
                          />

                          <IconButton
                            onClick={() =>
                              change("company.contractEndDate", null)
                            }
                          >
                            <NavigationCancel />
                          </IconButton>
                        </FlexBox>
                        <FlexBox direction="column" flex={true}>
                          <FormChipAutoComplete
                            name="company.paymentTypes"
                            options={OrderPaymentTypes}
                            fullWidth={true}
                            formatOption={x =>
                              formatLocalisedPaymentType(
                                x,
                                getLocalisationMessage,
                              )
                            }
                            validate={createPaymentsValidator(
                              getLocalisationMessage("this_field_is_required"),
                            )}
                            hintText={getLocalisationMessage(
                              "type_here_to_search",
                            )}
                            label={`${getLocalisationMessage(
                              "payment_method",
                            )} *`}
                          />
                        </FlexBox>

                        <FlexBox direction="column" flex={true}>
                          <FormTextField
                            name="company.creditLimit"
                            fullWidth={true}
                            parseOnBlur={parseFloat}
                            disabled={getValue(values, "company.unlimited")}
                            label={getLocalisationMessage(
                              "credit_limit",
                              "Credit Limit",
                            )}
                          />

                          <FormCheckbox
                            name="company.unlimited"
                            label={getLocalisationMessage(
                              "unlimited",
                              "Unlimited",
                            )}
                            onChange={() => change("company.creditLimit", null)}
                          />
                        </FlexBox>
                      </FlexBox>
                    </FlexBox>
                  </FlexBox>
                </CardContent>
              </React.Fragment>
            )}
          </FlexBox>
          <CardActions>
            <FlexBox
              style={{ marginBottom: "1rem" }}
              flex={true}
              justify="flex-end"
            >
              <CustomButton
                style={{ marginRight: ".5rem" }}
                onClick={() => setLocation(CUSTOMER_LIST_ALL_URL)}
                startIcon={<KeyboardArrowLeft />}
                variant={CONTAINED}
                color={SECONDARY}
              >
                {getLocalisationMessage("back", "Back")}
              </CustomButton>
              <CustomButton
                style={{ marginLeft: ".5rem" }}
                variant={CONTAINED}
                color={SECONDARY}
                endIcon={<Done />}
                type="submit"
                onClick={handleSubmit}
              >
                {getLocalisationMessage("save")}
              </CustomButton>
            </FlexBox>
          </CardActions>
        </FlexBox>
      </form>
    </Card>
  );
};
AdminCustomerForm.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  showErrorMessage: PropTypes.func,
  values: PropTypes.object,
  getLocalisationMessage: PropTypes.func,
  change: PropTypes.func,
  setLocation: PropTypes.func,
  edit: PropTypes.func,
  setShow: PropTypes.func,
  reset: PropTypes.func,
};
export default enhancer(AdminCustomerForm);
