import { Observable } from "rxjs";
import React from "react";
import _ from "lodash";
import { List, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, getContext, componentFromStream } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { AutoCompleteComponent } from "./FormAutoComplete";
import { isEqualData, isEqualDataIn } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { ACTIVE } from "../../constants/OverallStatus";

export const ORDER_RULE = "ORDER_RULE";
export const SUPPLIER_RULE = "SUPPLIER_RULE";
export const PICKUP_JOB_AUTOMATION = "PICKUP_JOB_AUTOMATION";
export const ZONING_RULE = "ZONING_RULE";

const baseFilter = new DataListFilter({
  page: 0,
  size: 20,
  status: ACTIVE,
});

const parseInput = name => ({ name });
const formatOption = fp.get("name");

const enhancer = compose(
  getContext({
    getCachedRule: PropTypes.func.isRequired,
    getRulePredictions: PropTypes.func.isRequired,
  }),
);

const RuleAutoComplete = enhancer(
  componentFromStream(propsStream => {
    const valueStream = propsStream
      .distinctUntilChanged(isEqualDataIn("input.value"))
      .switchMap(({ getCachedRule, input: { value } }) =>
        value && value.id && !value.name
          ? getCachedRule(value.id)
              .map(item => ({ id: value.id, name: item.get("name") }))
              .catch(() => Observable.of(value))
              .startWith(value)
          : Observable.of(value),
      );

    const optionsStream = propsStream
      .distinctUntilChanged(isEqualDataIn("input.value.name"))
      .switchMap(({ getRulePredictions, input: { value }, ruleType }) =>
        getRulePredictions(
          baseFilter.setValueMap({
            search: value && value.name,
            rule_type: ruleType,
          }),
        )
          .map(fp.flow(fp.get("payload.data"), fp.toArray, fromJS))
          .catch(() => Observable.of(List())),
      )
      .distinctUntilChanged(isEqualData)
      .map(v => v.toJS());

    return propsStream
      .map(fp.omit(["getCachedRule", "getRulePredictions"]))
      .combineLatest(valueStream, optionsStream, (props, value, options) => {
        const restProps = _.omit(props, ["ruleType"]);

        return (
          <AutoCompleteComponent
            {...restProps}
            options={options}
            filter={fp.stubTrue}
            parseInput={parseInput}
            formatOption={formatOption}
            input={{ ...restProps.input, value }}
          />
        );
      });
  }),
);

FormRuleAutoComplete.propTypes = {
  name: PropTypes.string.isRequired,

  ruleType: PropTypes.oneOf([
    SUPPLIER_RULE,
    ORDER_RULE,
    PICKUP_JOB_AUTOMATION,
    ZONING_RULE,
  ]),

  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  openOnFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  maxSearchResults: PropTypes.number,

  hintText: PropTypes.node,
  label: PropTypes.node,
};

FormRuleAutoComplete.defaultProps = {
  maxSearchResults: 10,
  ruleType: SUPPLIER_RULE,
};

export default function FormRuleAutoComplete(props) {
  return <Field {...props} component={RuleAutoComplete} />;
}
