import React, { useState } from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  isEqualData,
  isEqualDataIn,
  isEqualWithoutFunctions,
} from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { COMMUNICATION_SETTINGS_LIST_URL } from "../../constants/AdminPathConstants";
import {
  getDateDeclaredMethods,
  getCommunicationMessage,
  deleteCommunicationMessage,
  saveAdminCommunicationMessage,
} from "../../api/shared/CommunicationApi";
import FormDialog from "../../components/form/FormDialog";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import CommunicationSettingsForm from "../../components/communication-settings/CommunicationSettingsForm";
import { updateQuery } from "../../../shared/helpers/UrlUtils";

const enhancer = compose(
  getContext({
    setLocation: PropTypes.func.isRequired,
  }),
  connect(
    (state) => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showSuccessMessage, showErrorMessage },
  ),
  mapPropsStream((propsStream) => {
    const itemCommunicationResponseStream = propsStream
      .distinctUntilChanged(isEqualDataIn(["params", "id"]))
      .switchMap((props) =>
        getCommunicationMessage(props.params.id).catch(ResponseError.throw),
      )
      .startWith({})
      .map(
        fp.flow(
          (response) => fromJS(response),
          (response) =>
            fromJS({
              item: response.getIn(["payload", "data"], Map()),
              isLoading: response.get("pending", false),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        itemCommunicationResponseStream,
        (props, itemCommunicationResponse) => ({
          ...props,
          item: itemCommunicationResponse.get("item"),
          isLoading: itemCommunicationResponse.get("isLoading"),
        }),
      )
      .distinctUntilChanged(isEqualWithoutFunctions);
  }),
);

const AdminCommunicationSettingsItem = (props) => {
  const { getLocalisationMessage } = props;
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);

  return (
    <AdminAppLayout
      title={getLocalisationMessage(
        "notification_settings",
        "Notification Settings",
      )}
    >
      <FormDialog
        onSubmitFail={props.showErrorMessage}
        onRequestClose={() => setIsOpenDeleteDialog(false)}
        open={isOpenDeleteDialog}
        onSubmit={() =>
          deleteCommunicationMessage(props.params.id).catch(ResponseError.throw)
        }
        onSubmitSuccess={() => {
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_removed",
              "Successfully Removed",
            ),
          );
          props.setLocation(
            updateQuery(COMMUNICATION_SETTINGS_LIST_URL, {
              ...props.location.query,
            }),
          );
        }}
      >
        {getLocalisationMessage(
          "confirm_deleted",
          "Are you sure you want to remove this message?",
        )}
      </FormDialog>

      <CommunicationSettingsForm
        isLoading={props.isLoading}
        onSubmitFail={props.showErrorMessage}
        getDateDeclaredMethods={getDateDeclaredMethods}
        initialValues={toCamelCase(props.item)}
        breadcrumbTitle={getLocalisationMessage("edit_message", "Edit Message")}
        onSubmitSuccess={() =>
          props.showSuccessMessage(
            getLocalisationMessage("successfully_saved", "Successfully saved"),
          )
        }
        onDeleteClick={() => setIsOpenDeleteDialog(true)}
        onSubmit={(values) =>
          saveAdminCommunicationMessage(toSnakeCase(values)).catch(
            ResponseError.throw,
          )
        }
      />
    </AdminAppLayout>
  );
};
AdminCommunicationSettingsItem.propTypes = {
  params: PropTypes.object,
  location: PropTypes.object,

  setLocation: PropTypes.func,

  item: PropTypes.instanceOf(Map),
  isLoading: PropTypes.bool,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

export default enhancer(AdminCommunicationSettingsItem);
