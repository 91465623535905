import { Observable } from "rxjs";
import React from "react";
import { Map, OrderedSet } from "immutable";
import fp from "lodash/fp";
import { compose, createEventHandler, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { getValue, isEqualData } from "../../helpers/DataUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  getCachedUserRoles,
  getUser,
  updateUser,
} from "../../api/admin/AdminUserApi";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import FlexBox from "../../components/ui-core/FlexBox";
import ModalPaper from "../../components/ui-core/ModalPaper";
import UserForm from "../../components/users-core/UserForm";
import _ from "lodash";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../api/admin/AdminWarehouseApi";
import { hasRole } from "../../helpers/RoleUtils";
import { ROLE_SUPER_ADMIN } from "../../../shared/constants/Authorities";
import { getUser as getUserReducer } from "../../reducers/ProfileReducer";

const enhancer = compose(
  connect(
    state => {
      const userRoles = getUserReducer(state).get("roles") || [];
      return {
        isSuperAdmin: hasRole(userRoles, ROLE_SUPER_ADMIN),
        getLocalisationMessage: (code, defaultMessage) =>
          getMessage(state, code, defaultMessage),
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  renderIf(props => props.userId > 0),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const userRolesResponseStream = getCachedUserRoles()
      .catch(() => Observable.of(OrderedSet()))
      .map(list => list.toOrderedSet());
    const userResponseStream = propsStream
      .distinctUntilKeyChanged("userId")
      .switchMap(props =>
        getUser(props.userId)
          .repeatWhen(() => onRequestRefreshStream)
          .catch(error => Observable.of({ error })),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"))),
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        userResponseStream,
        userRolesResponseStream,
        (props, userResponse, userRoles) => ({
          ...props,
          onRequestRefresh,
          user: userResponse.payload,
          userRoles,
          isLoading: userResponse.pending,
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

AdminUserEditDialogWrapper.propTypes = {
  user: PropTypes.instanceOf(Map),
  isLoading: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  userId: PropTypes.number,
  userRoles: PropTypes.instanceOf(OrderedSet),
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired,
  onSupplierClick: PropTypes.func,
  onCreateSupplierClick: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function AdminUserEditDialogWrapper(props) {
  const { getLocalisationMessage } = props;
  return (
    <ModalPaper
      open={true}
      isLoading={props.isLoading}
      onRequestClose={props.onRequestClose}
      title={`${getLocalisationMessage("user", "User")} - ${getValue(
        props.user,
        "full_name",
      )}`}
    >
      <FlexBox container={8} direction="column">
        <FlexBox gutter={8} direction="column">
          <FlexBox flex="none" direction="column">
            <UserForm
              isEdit={true}
              isSuperAdmin={props.isSuperAdmin}
              disableEmail={false}
              onDismiss={props.onRequestClose}
              initialValues={props.user}
              onSubmit={values => {
                const roles = values.roles.map(role => {
                  let found;
                  if (_.isString(role)) {
                    found = props.userRoles.find(
                      uRole => uRole.get("name") === role,
                    );
                  } else {
                    found = props.userRoles.find(
                      uRole => uRole.get("name") === role.name,
                    );
                  }
                  return found ? found.toJS() : null;
                });

                return updateUser(
                  props.userId,
                  toSnakeCase({ ...values, roles: roles.filter(Boolean) }),
                ).catch(ResponseError.throw);
              }}
              onSubmitSuccess={() => {
                props.onRequestClose();
                props.showSuccessMessage(
                  getLocalisationMessage(
                    "successfully_updated",
                    "Successfully Updated",
                  ),
                );
              }}
              onSubmitFail={props.showErrorMessage}
              getCachedSupplier={getCachedSupplier}
              getSupplierPredictions={getSupplierPredictions}
              getCachedWarehouse={getCachedWarehouse}
              getWarehousePredictions={getWarehousePredictions}
              onSupplierClick={props.onSupplierClick}
              onCreateSupplierClick={props.onCreateSupplierClick}
              userRoles={props.userRoles.map(item => item.get("name"))}
            />
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(AdminUserEditDialogWrapper);
