import React from "react";
import { Map } from "immutable";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ResponseError from "../../helpers/ResponseError";
import { getMessages } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  ORDER_RULE_ITEM_URL,
  ORDER_RULE_LIST_URL,
  ORDER_RULE_CREATE_URL,
} from "../../constants/AdminPathConstants";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../api/admin/AdminDriverApi";
import {
  getCachedCustomer,
  getCustomerPredictions,
} from "../../api/admin/AdminCustomerApi";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import {
  getCachedTimeSlot,
  getTimeSlotPredictions,
} from "../../api/admin/AdminTimeSlotApi";
import {
  getCachedWarehouse,
  getWarehousePolygons,
  getWarehousePredictions,
} from "../../api/admin/AdminWarehouseApi";
import {
  createRule,
  getCachedRule,
  getRulePredictions,
  getChildRulePredictions,
} from "../../api/admin/AdminOrderRulesApi";
import {
  getCachedPostcode,
  getNeighborhoodPolygons,
  getPostcodePredictions,
} from "../../api/admin/AdminPostcodesApi";
import {
  getCachedCity,
  getCachedCountry,
  getCityPredictions,
  getCountryPredictions,
} from "../../api/shared/CountryV2Api";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import OrderRuleTree from "../../components/order-rule-tree/OrderRuleTree";

const enhancer = compose(
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  getContext({ setLocation: PropTypes.func.isRequired }),
);

AdminOrderRuleCreateContainer.propTypes = {
  setLocation: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  i18n: PropTypes.instanceOf(Map),
};

function AdminOrderRuleCreateContainer(props) {
  return (
    <AdminAppLayout
      title={props.i18n.get("create_order_rule", "Create Order Rule")}
    >
      <OrderRuleTree
        onSubmit={values => createRule(values).catch(ResponseError.throw)}
        onSubmitSuccess={() => {
          props.setLocation(ORDER_RULE_LIST_URL);
          props.showSuccessMessage(
            props.i18n.get(
              "successfully_order_rule_created",
              "Successfully Order Rule Created",
            ),
          );
        }}
        onSubmitFail={error => props.showErrorMessage(error)}
        createRuleUrl={ORDER_RULE_CREATE_URL}
        getRuleUrl={rule => `${ORDER_RULE_ITEM_URL}/${rule.id}`}
        getWarehousePolygons={getWarehousePolygons}
        getNeighborhoodPolygons={getNeighborhoodPolygons}
        getCachedRule={getCachedRule}
        getRulePredictions={getRulePredictions}
        getChildRulePredictions={getChildRulePredictions}
        getCachedCustomer={getCachedCustomer}
        getCustomerPredictions={getCustomerPredictions}
        getCachedSupplier={getCachedSupplier}
        getSupplierPredictions={getSupplierPredictions}
        getCachedDriver={getCachedDriver}
        getDriverPredictions={getDriverPredictions}
        getCachedTimeSlot={getCachedTimeSlot}
        getTimeSlotPredictions={getTimeSlotPredictions}
        getCachedWarehouse={getCachedWarehouse}
        getWarehousePredictions={getWarehousePredictions}
        getCachedPostcode={getCachedPostcode}
        getPostcodePredictions={getPostcodePredictions}
        getCachedCountry={getCachedCountry}
        getCountryPredictions={getCountryPredictions}
        getCachedCity={getCachedCity}
        getCityPredictions={getCityPredictions}
      />
    </AdminAppLayout>
  );
}

export default enhancer(AdminOrderRuleCreateContainer);
