import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  Card,
  CardContent,
  Button,
  IconButton,
  CardActions,
  Tooltip,
} from "@material-ui/core";
import { CloudDownload } from "@material-ui/icons";
import FormFileField from "../form/FormFileField";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { CREATE_USERS_TEMPLATE_URL } from "../../../shared/constants/FileProxyControllerConstants";

UploadUsersForm.propTypes = {
  getLocalisationMessage: PropTypes.func,
  classes: PropTypes.object,
  loading: PropTypes.bool,
  onDismiss: PropTypes.func,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  valid: PropTypes.bool,
  dirty: PropTypes.bool,
};

const CSV_FILE_TYPES = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
].join(",");

const enchancer = compose(
  useSheet({
    root: {
      overflow: "hidden",
    },
  }),
  reduxForm({ form: "UploadUsersForm" }),
);

function UploadUsersForm({
  getLocalisationMessage,
  classes,
  loading,
  onDismiss,
  valid,
  dirty,
  ...props
}) {
  return (
    <FlexBox
      container={8}
      direction="column"
      className={classes.root}
      element={<form autoComplete="off" onSubmit={props.handleSubmit} />}
    >
      <FlexBox flex={true} direction="column">
        <Card>
          <CardContent>
            <PageLoading isLoading={loading} />
            <FlexBox gutter={8} flex={true}>
              <FlexBox flex={true}>
                <FormFileField
                  name="file"
                  fullWidth={true}
                  accept={CSV_FILE_TYPES}
                  label={getLocalisationMessage("select_file", "Select File")}
                />
              </FlexBox>

              <FlexBox align="center">
                <Tooltip
                  title={getLocalisationMessage(
                    "download_template",
                    "Download Template",
                  )}
                >
                  <IconButton
                    // target="_blank"
                    href={CREATE_USERS_TEMPLATE_URL}
                  >
                    <CloudDownload />
                  </IconButton>
                </Tooltip>
              </FlexBox>
            </FlexBox>
          </CardContent>
          <CardActions>
            <FlexBox gutter={8} flex={true}>
              <FlexBox flex={true} justify="flex-end">
                <Button onClick={onDismiss}>
                  {getLocalisationMessage("dismiss", "Dismiss")}
                </Button>
                <Button type="submit" disabled={!valid || !dirty}>
                  {getLocalisationMessage("submit", "Submit")}
                </Button>
              </FlexBox>
            </FlexBox>
          </CardActions>
        </Card>
      </FlexBox>
    </FlexBox>
  );
}

export default enchancer(UploadUsersForm);
