import React from "react";
import fp from "lodash/fp";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { mapObjectResponseStream } from "../../helpers/ApiUtils";
import { isEqualData, isEqualDataIn } from "../../helpers/DataUtils";
import { safeParseDate } from "../../helpers/DateUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import { formatDateToUrl } from "../../helpers/FormatUtils";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  getSupplierVehicleCount,
  updateSupplierMonthlyQuota,
} from "../../api/admin/AdminSupplierApi";
import PageLoading from "../../components/ui-core/PageLoading";
import SupplierItemMonthlyQuotaForm from "../../components/suppliers-core/SupplierItemMonthlyQuotaForm";

const quotaToCamelCase = fp.flow(
  toCamelCase,
  fp.update("applyFrom", safeParseDate),
);

const quotaToSnakeCase = fp.flow(
  toSnakeCase,
  fp.update("apply_from", formatDateToUrl),
);

const enhancer = compose(
  getContext({ setLocationHash: PropTypes.func }),
  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);
      return {
        getLocalisationMessage,
      };
    },
    { showErrorMessage, showSuccessMessage },
  ),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const initialValuesStream = propsStream
      .distinctUntilChanged(isEqualDataIn("params.supplierId"))
      .switchMap(props =>
        getSupplierVehicleCount(props.params.supplierId)
          .let(mapObjectResponseStream)
          .repeatWhen(() => onRequestRefreshStream),
      )
      .distinctUntilChanged(isEqualData)
      .map(x => x.update("payload", quotaToCamelCase));

    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,
        onRequestRefresh,
        isLoading: initialValues.get("pending"),
        initialValues: initialValues.get("payload"),
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

AdminSupplierItemMonthlyQuotaContainer.propTypes = {
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,

  isLoading: PropTypes.bool,
  initialValues: PropTypes.object,
  onRequestRefresh: PropTypes.func,
  params: PropTypes.object.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminSupplierItemMonthlyQuotaContainer(props) {
  const { params, getLocalisationMessage } = props;

  if (props.isLoading) {
    return <PageLoading isLoading={true} />;
  }

  return (
    <SupplierItemMonthlyQuotaForm
      initialValues={props.initialValues}
      onSubmit={fp.flow(
        fp.set("supplierId", params.supplierId),
        quotaToSnakeCase,
        values =>
          updateSupplierMonthlyQuota(params.supplierId, values).catch(
            ResponseError.throw,
          ),
      )}
      onSubmitSuccess={() => {
        props.onRequestRefresh();
        props.showSuccessMessage(
          getLocalisationMessage("successfully_saved", "Successfully saved"),
        );
      }}
      onSubmitFail={props.showErrorMessage}
    />
  );
}

export default enhancer(AdminSupplierItemMonthlyQuotaContainer);
