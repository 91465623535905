import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { pureComponent } from "../../helpers/HOCUtils";
import { toArray, isEqualData } from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import { pipeStreams } from "../../helpers/StreamUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { ORDER_RULES_GROUP_ITEM_URL } from "../../constants/AdminPathConstants";
import { ACTIVE } from "../../constants/UpperOverallStatus";
import {
  ASSIGN_TO_DRIVER,
  ASSIGN_TO_SUPPLIER,
} from "../../constants/DeliveryAllocationTypes";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../api/admin/AdminDriverApi";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import {
  getCachedRule,
  createRulesGroup,
  updateRulesGroup,
  getRulesGroupItem,
  getRulePredictions,
  updateRulesGroupSuppliersQuota,
} from "../../api/admin/AdminOrderRulesApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import RulesGroupForm from "../../components/rules/RulesGroupForm";
import RulesGroupItemForm from "../../components/rules/RulesGroupItemForm";
import FlexBox from "../../components/ui-core/FlexBox";
import PageLoading from "../../components/ui-core/PageLoading";

const mapDrivers = fp.flow(
  toArray,
  fp.map(item => ({
    driver: { id: item.driver_id },
    daily_quota: item.daily_quota,
    daily_limit: item.daily_limit,
  })),
);

const enhancer = compose(
  useSheet({
    root: {
      width: "1000px",
    },
  }),
  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  getContext({ replaceLocation: PropTypes.func.isRequired }),
  mapPropsStream(
    pipeStreams(
      propsStream =>
        propsStream
          .combineLatest(
            propsStream
              .map(props => fp.toFinite(props.params.groupId))
              .distinctUntilChanged(isEqualData),
            (props, groupId) => ({
              ...props,
              groupId,
              isItem: groupId > 0,
            }),
          )
          .distinctUntilChanged(isEqualData),
      propsStream => {
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const rulesGroupStream = propsStream
          .distinctUntilKeyChanged("groupId")
          .filter(fp.flow(fp.get("groupId"), Boolean))
          .switchMap(props =>
            getRulesGroupItem(props.groupId)
              .catch(error => Observable.of({ error }))
              .repeatWhen(() => onRequestRefreshStream),
          )
          .startWith({})
          .map(
            fp.flow(
              fp.update("pending", Boolean),
              fp.update(
                "payload",
                fp.flow(
                  fp.get("data"),
                  fp.update(
                    "rules",
                    fp.flow(fp.toArray, rules =>
                      rules.map(item => ({
                        ...item,
                        isSupplier:
                          fp.size(mapDrivers(item.drivers)) > 0
                            ? ASSIGN_TO_DRIVER
                            : ASSIGN_TO_SUPPLIER,
                      })),
                    ),
                  ),
                ),
              ),
              fromJS,
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(rulesGroupStream, (props, rulesGroup) => ({
            ...props,
            item: rulesGroup.get("payload"),
            isLoading: rulesGroup.get("pending"),
            onRequestRefresh,
          }))
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
  pureComponent(fp.pick(["isLoading", "item", "isItem"])),
);

AdminOrderRulesGroupItemContainer.propTypes = {
  classes: PropTypes.object,

  isLoading: PropTypes.bool,
  item: PropTypes.instanceOf(Map),

  isItem: PropTypes.bool,

  onRequestRefresh: PropTypes.func,

  showSuccessMessage: PropTypes.func,
  showErrorMessage: PropTypes.func,

  replaceLocation: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminOrderRulesGroupItemContainer(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <AdminAppLayout
      title={
        props.isItem
          ? getLocalisationMessage("edit_rules_group", "Edit Rules Group")
          : getLocalisationMessage("create_rules_group", "Create Rules Group")
      }
    >
      <PageLoading isLoading={props.isLoading} />

      <FlexBox container={8} justify="center" flex="none">
        <FlexBox gutter={8} direction="column" className={classes.root}>
          <FlexBox>
            <RulesGroupForm
              isItem={props.isItem}
              onSubmit={values => {
                const body = toSnakeCase({
                  ...values,
                  any_supplier: fp.isEmpty(values.rules),
                });

                return props.isItem
                  ? updateRulesGroup(body)
                  : createRulesGroup(body);
              }}
              initialValues={
                props.isItem
                  ? toCamelCase(props.item)
                  : { ruleGroupStatus: ACTIVE }
              }
              onSubmitSuccess={({ data }) => {
                props.showSuccessMessage(
                  getLocalisationMessage(
                    "rules_group_successfully_saved",
                    "Rules Group Successfully Saved",
                  ),
                );

                if (props.isItem) {
                  props.onRequestRefresh();
                } else if (data && data.id) {
                  props.replaceLocation(
                    `${ORDER_RULES_GROUP_ITEM_URL}/${data.id}`,
                  );
                }
              }}
              onSubmitFail={props.showErrorMessage}
              getCachedRule={getCachedRule}
              getRulePredictions={getRulePredictions}
            />
          </FlexBox>

          {Boolean(props.isItem && fp.size(props.item.get("rules")) > 0) && (
            <FlexBox flex={true}>
              <RulesGroupItemForm
                onSubmit={values =>
                  updateRulesGroupSuppliersQuota(
                    toSnakeCase({
                      ...values,
                      rules:
                        fp.size(values.rules) > 0
                          ? values.rules.map(i => ({
                              ...i,
                              suppliers:
                                i.isSupplier === ASSIGN_TO_SUPPLIER &&
                                fp.size(i.suppliers) > 0
                                  ? i.suppliers.map(x => ({
                                      ...x,
                                      supplierId:
                                        fp.size(x.supplier) > 0
                                          ? x.supplier.id
                                          : null,
                                    }))
                                  : null,
                              drivers:
                                i.isSupplier === ASSIGN_TO_DRIVER &&
                                fp.size(i.drivers) > 0
                                  ? i.drivers.map(x => ({
                                      ...x,
                                      driverId:
                                        fp.size(x.driver) > 0
                                          ? x.driver.id
                                          : null,
                                    }))
                                  : null,
                            }))
                          : null,
                      any_supplier: fp.isEmpty(values.rules),
                    }),
                  )
                }
                onSubmitSuccess={() => {
                  props.onRequestRefresh();
                  props.showSuccessMessage(
                    getLocalisationMessage(
                      "suppliers_quota_successfully_updated",
                      "Suppliers Quota Successfully Updated",
                    ),
                  );
                }}
                showClearButton={!props.isItem}
                initialValues={toCamelCase(props.item)}
                getCachedSupplier={getCachedSupplier}
                getSupplierPredictions={getSupplierPredictions}
                getCachedDriver={getCachedDriver}
                getDriverPredictions={getDriverPredictions}
              />
            </FlexBox>
          )}
        </FlexBox>
      </FlexBox>
    </AdminAppLayout>
  );
}

export default enhancer(AdminOrderRulesGroupItemContainer);
