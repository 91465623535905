import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import ResponseError from "../../helpers/ResponseError";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  getOrder,
  setAdminOrderPiecesSummary,
} from "../../api/admin/AdminOrderApi";
import PageLoading from "../../components/ui-core/PageLoading";
import OrderMultiBoxEditDialogForm from "../../components/orders-core/OrderMultiBoxEditDialogForm";

const enhancer = compose(
  renderIf("open"),
  connect(null, { showErrorMessage, showSuccessMessage }),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const chargesListResponseStream = propsStream
      .distinctUntilKeyChanged("orderId")
      .switchMap(props =>
        getOrder(props.orderId)
          .repeatWhen(() => onRequestRefreshStream)
          .catch(error => Observable.of({ error })),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.get("data")),
          fromJS,
        ),
      );

    return propsStream
      .combineLatest(chargesListResponseStream, (props, chargesList) => ({
        ...props,
        onRequestRefresh,
        isLoading: chargesList.get("pending"),
        order: chargesList.get("payload"),
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

AdminOrderMultiBoxUpdateDialogWrapper.propTypes = {
  orderId: PropTypes.number.isRequired,
  order: PropTypes.instanceOf(Map),
  messages: PropTypes.instanceOf(Map),
  open: PropTypes.bool,
  isLoading: PropTypes.bool,
  onRequestRefresh: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
};

function AdminOrderMultiBoxUpdateDialogWrapper(props) {
  if (props.isLoading) {
    return <PageLoading isLoading={props.isLoading} />;
  }
  const { messages } = props;

  return (
    <OrderMultiBoxEditDialogForm
      messages={messages}
      open={true}
      initialValues={{
        pieceCount: props.order.get("piece_count", 0),
      }}
      isLoading={props.isLoading}
      onSubmit={values => {
        const request = {
          pieces: fp.toFinite(values.pieceCount),
          weight: 0,
        };

        return setAdminOrderPiecesSummary(
          props.order.get("order_number"),
          request,
        ).catch(ResponseError.throw);
      }}
      onRequestClose={props.onRequestClose}
      onSubmitSuccess={() => {
        props.showSuccessMessage(
          messages.get("orders_box_count_has_been_successfully_updated"),
        );
        props.onRequestClose();
      }}
      onSubmitFail={props.showErrorMessage}
    />
  );
}

export default enhancer(AdminOrderMultiBoxUpdateDialogWrapper);
