import React from "react";
import Immutable from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  PanTo,
  FitBounds,
  InfoWindow,
  DataPolygon,
} from "react-google-map-components";
import {
  PanTo as LPanTo,
  FitBounds as LFitBounds,
  DataPolygon as LDataPolygon,
} from "react-leflet-map-components";
import AutoSizeMarker from "./AutoSizeMarker";
import GoogleMapWrapper from "./GoogleMapWrapper";
import LeafletMapWrapper from "../maps-leaflet/LeafletMapWrapper";
import { isEqualData } from "../../helpers/DataUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import { getMapsStream } from "../../helpers/GoogleMapsHelper";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  getMapProvider,
  isMapKeyAvailable,
} from "../../../shared/reducers/AppReducer";
import { MAP_PROVIDERS } from "../../../shared/constants/MapsControllerConstants";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    mapProvider: getMapProvider(state),
    isMapKeyAvailable: isMapKeyAvailable(state),
  })),
  mapPropsStream(
    pipeStreams(
      propsStream =>
        propsStream.combineLatest(getMapsStream(), (props, maps) => ({
          ...props,
          maps,
        })),
      propsStream => {
        const stateStream = propsStream
          .distinctUntilKeyChanged("zones", isEqualData)
          .map(props => {
            const centers = [];
            const polygons = [];
            const mapBounds = [];

            props.zones.forEach(x => {
              const center = x.get("center");

              polygons.push(x.get("polygon").toJS());

              if (center) {
                const latLng = center.toJS();

                centers.push(latLng);
                mapBounds.push(latLng);
              } else {
                centers.push(null);
              }
            });

            return { centers, polygons, mapBounds };
          });

        return propsStream.combineLatest(stateStream, (props, state) => ({
          ...props,
          ...state,
        }));
      },
    ),
  ),
);

ZoneListMap.propTypes = {
  centers: PropTypes.array,
  polygons: PropTypes.array,
  mapBounds: PropTypes.array,

  children: PropTypes.node,

  onActivate: PropTypes.func,
  onDeactivate: PropTypes.func,
  activeId: PropTypes.number,

  selectedColor: PropTypes.string,
  hasSupplierColor: PropTypes.string,
  selectedIds: PropTypes.arrayOf(PropTypes.number),

  style: PropTypes.object,
  className: PropTypes.string,

  onZoneClick: PropTypes.func,

  mapProvider: PropTypes.oneOf(MAP_PROVIDERS),
  isMapKeyAvailable: PropTypes.bool,

  zones: PropTypes.instanceOf(Immutable.List).isRequired,
  getLocalisationMessage: PropTypes.func,
};

ZoneListMap.defaultProps = {
  onZoneClick: fp.noop,
  selectedIds: [],
  selectedColor: "#0000FF",
  hasSupplierColor: "#00FF00",
};

function ZoneListMap(props) {
  const {
    zones,
    centers,
    activeId,
    polygons,
    mapBounds,
    selectedIds,
    getLocalisationMessage,
  } = props;

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {props.isMapKeyAvailable ? (
        <GoogleMapWrapper style={props.style} className={props.className}>
          {props.children}

          {mapBounds.length === 1 && <PanTo latLng={mapBounds[0]} />}
          {mapBounds.length > 1 && <FitBounds latLngBounds={mapBounds} />}

          {polygons.map((x, idx) => {
            const zone = zones.get(idx);
            const id = zone.get("id");
            const center = centers[idx];
            const activePolygon = zone.get("active");

            return (
              <div key={idx}>
                {activeId === id && <FitBounds latLngBounds={x[0]} />}

                <DataPolygon
                  geometry={x}
                  fillColor={
                    !fp.includes(id, selectedIds)
                      ? zone.get("has_supplier")
                        ? props.hasSupplierColor
                        : activePolygon
                        ? props.hasSupplierColor
                        : "#000000"
                      : props.selectedColor
                  }
                  onClick={() => props.onZoneClick(zone, idx)}
                />

                {Boolean(center && zone.has("order_count")) && (
                  <AutoSizeMarker
                    position={center}
                    label={zone.get("order_count")}
                    onClick={() => props.onActivate(zone, idx)}
                  />
                )}

                {Boolean(center && activeId === id) && (
                  <InfoWindow
                    open={true}
                    position={center}
                    onCloseClick={props.onDeactivate}
                  >
                    <div>
                      {id} - <strong>{zone.get("name")}</strong>
                      {zone.get("has_supplier") && (
                        <div>
                          <strong>
                            {getLocalisationMessage("courier", "Courier")}:
                          </strong>{" "}
                          {zone.get("suppllier_name")}{" "}
                        </div>
                      )}
                      <div>
                        <strong>
                          {getLocalisationMessage("merchant", "Merchant")}:
                        </strong>{" "}
                        {zone.get("customer_name")}{" "}
                      </div>
                    </div>
                  </InfoWindow>
                )}
              </div>
            );
          })}
        </GoogleMapWrapper>
      ) : (
        <LeafletMapWrapper
          zoom={9}
          style={{ position: "relative" }}
          className={props.className}
        >
          {props.children}

          {mapBounds.length === 1 && <LPanTo latLng={mapBounds[0]} />}
          {mapBounds.length > 1 && <LFitBounds latLngBounds={mapBounds} />}

          {polygons.map((x, idx) => {
            const zone = zones.get(idx);
            const id = zone.get("id");
            // const center = centers[idx];
            const activePolygon = zone.get("active");
            const fillColor = !fp.includes(id, selectedIds)
              ? zone.get("has_supplier")
                ? props.hasSupplierColor
                : activePolygon
                ? props.hasSupplierColor
                : "#000000"
              : props.selectedColor;

            const isChanged = !fp.includes(id, selectedIds)
              ? zone.get("has_supplier")
                ? true
                : !!activePolygon
              : true;

            return (
              <div key={idx}>
                {activeId === id && <FitBounds latLngBounds={x[0]} />}

                <LDataPolygon
                  geometry={x}
                  editable={isChanged}
                  stroke={true}
                  weight={3}
                  opacity={1}
                  fill={true}
                  fillOpacity={0.4}
                  color={fillColor}
                  fillColor={fillColor}
                  onClick={() => props.onZoneClick(zone, idx)}
                />
              </div>
            );
          })}
        </LeafletMapWrapper>
      )}
    </div>
  );
}

export default enhancer(ZoneListMap);
