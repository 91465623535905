import { Observable } from "rxjs";
import React from "react";
import _ from "lodash";
import { Map, Set, List, fromJS, OrderedSet } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import { Card, CardContent, Button } from "@material-ui/core";
import { connect } from "react-redux";
import NeighborhoodsCopyFormTable from "./NeighborhoodsCopyFormTable";
import NavigationPrompt from "../router/NavigationPrompt";
import FlexBox from "../ui-core/FlexBox";
import { isEqualData, isEqualWithoutFunctions } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import FormSelectField from "../form/FormSelectField";
import { getJurisdictions } from "../../api/admin/AdminJurisdictionsApi";

const JURISDICTION = "JURISDICTION";
const POSTAL_CODE = "POSTAL_CODE";
const CHILD = "CHILD";

const NeighborhoodTypes = OrderedSet.of(POSTAL_CODE, JURISDICTION, CHILD);

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);
    return {
      getLocalisationMessage,
    };
  }),
  reduxForm({
    form: "AdminSupplierNeighborhoods",
    enableReinitialize: true,
  }),
  formValues({
    neighborhoodIds: "neighborhoodIds",
    coverage_type: "coverage_type",
    jurisdiction: "jurisdiction",
    postal_codes: "postal_codes",
    jurisdictions: "jurisdictions",
    suppliers: "suppliers",
  }),
  mapPropsStream((propsStream: Observable) => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();
    const {
      handler: onLinkedRowSelection,
      stream: onAllRowSelectionStream,
    } = createEventHandler();
    const {
      handler: onUnlinkedRowSelection,
      stream: onSupplierRowSelectionStream,
    } = createEventHandler();

    const linkedSelectedIdsStream = onAllRowSelectionStream
      .map(ids => Set(ids))
      .startWith(Set());

    const unlinkedSelectedIdsStream = onSupplierRowSelectionStream
      .map(ids => Set(ids))
      .startWith(Set());

    const allNeighborhoodsStream = propsStream.switchMap(props => {
      const baseFilter = new DataListFilter({ size: 200, order_by: "name" });
      const createFetchPageStream = filter =>
        props
          .getPostcodeIndexList(filter)
          .catch(() => Observable.of({ pending: false }))
          .filter(fp.flow(fp.get("pending"), fp.eq(false)))
          .map(response => fromJS(response))
          .map(response =>
            Map({
              list: response.getIn(["payload", "data", "list"], List()),
              total: response.getIn(["payload", "data", "total"], 0),
            }),
          );

      switch (props.coverage_type) {
        case POSTAL_CODE:
          return createFetchPageStream(baseFilter)
            .switchMap(response => {
              const maxPage = Math.ceil(
                response.get("total") / baseFilter.getSize(),
              );
              const streams = _.map(_.range(maxPage), page =>
                page === 0
                  ? Observable.of(response)
                  : createFetchPageStream(baseFilter.setPage(page)),
              );

              return Observable.from(streams);
            })
            .concatAll()
            .reduce(
              (list, response) => list.concat(response.get("list")),
              List(),
            )
            .repeatWhen(() => onRequestRefreshStream)
            .startWith(List());
        case JURISDICTION:
          return Observable.from(
            getJurisdictions(
              new DataListFilter({ parent_id: props.jurisdiction.get("id") }),
            ),
          )
            .map(fp.get("data"))
            .map(fromJS)
            .repeatWhen(() => onRequestRefreshStream)
            .startWith(List());
        case CHILD:
          return createFetchPageStream(baseFilter)
            .switchMap(response => {
              const maxPage = Math.ceil(
                response.get("total") / baseFilter.getSize(),
              );
              const streams = _.map(_.range(maxPage), page =>
                page === 0
                  ? Observable.of(response)
                  : createFetchPageStream(baseFilter.setPage(page)),
              );
              return Observable.from(streams);
            })
            .concatAll()
            .reduce(
              (list, response) => list.concat(response.get("list")),
              List(),
            )
            .repeatWhen(() => onRequestRefreshStream)
            .startWith(List());
        default:
          return createFetchPageStream(baseFilter)
            .switchMap(response => {
              const maxPage = Math.ceil(
                response.get("total") / baseFilter.getSize(),
              );
              const streams = _.map(_.range(maxPage), page =>
                page === 0
                  ? Observable.of(response)
                  : createFetchPageStream(baseFilter.setPage(page)),
              );

              return Observable.from(streams);
            })
            .concatAll()
            .reduce(
              (list, response) => list.concat(response.get("list")),
              List(),
            )
            .repeatWhen(() => onRequestRefreshStream)
            .startWith(List());
      }
    });

    // const allNeighborhoodsStream = propsStream
    //   .take(1)
    //   .pluck("getPostcodeIndexList")
    //   .switchMap(getPostcodeIndexList => {
    //     const baseFilter = new DataListFilter({ size: 200, order_by: "name" });

    //     const createFetchPageStream = filter =>
    //       getPostcodeIndexList(filter)
    //         .catch(() => Observable.of({ pending: false }))
    //         .filter(fp.flow(fp.get("pending"), fp.eq(false)))
    //         .map(response => fromJS(response))
    //         .map(response =>
    //           Map({
    //             list: response.getIn(["payload", "data", "list"], List()),
    //             total: response.getIn(["payload", "data", "total"], 0),
    //           }),
    //         );

    //     return createFetchPageStream(baseFilter)
    //       .switchMap(response => {
    //         const maxPage = Math.ceil(
    //           response.get("total") / baseFilter.getSize(),
    //         );
    //         const streams = _.map(_.range(maxPage), page =>
    //           page === 0
    //             ? Observable.of(response)
    //             : createFetchPageStream(baseFilter.setPage(page)),
    //         );

    //         return Observable.from(streams);
    //       })
    //       .concatAll()
    //       .reduce((list, response) => list.concat(response.get("list")), List())
    //       .repeatWhen(() => onRequestRefreshStream)
    //       .startWith(List());
    //   })
    //   .share();

    const idsStream = propsStream
      .map(props => {
        switch (props.coverage_type) {
          case POSTAL_CODE:
            return props.postal_codes;
          case JURISDICTION:
            return props.jurisdictions;
          case CHILD:
            return props.suppliers;
          default:
            return props.suppliers;
        }
      })
      .map(ids => Set(ids))
      .distinctUntilChanged(isEqualData)
      .share();

    const linkedListStream = idsStream
      .combineLatest(allNeighborhoodsStream, (ids, list) =>
        list.filter(item => ids.has(item.get("id"))),
      )
      .distinctUntilChanged(isEqualData);

    const unlinkedListStream = idsStream
      .combineLatest(allNeighborhoodsStream, (ids, list) =>
        list.filter(item => !ids.has(item.get("id"))),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        linkedListStream,
        linkedSelectedIdsStream,
        unlinkedListStream,
        unlinkedSelectedIdsStream,
        allNeighborhoodsStream,
        (
          props,
          linkedList,
          linkedSelectedIds,
          unlinkedList,
          unlinkedSelectedIds,
          allList,
        ) => ({
          ...props,
          onRequestRefresh,
          linkedList,
          linkedSelectedIds,
          onLinkedRowSelection,
          unlinkedList,
          unlinkedSelectedIds,
          onUnlinkedRowSelection,
          allList,
        }),
      )
      .distinctUntilChanged(isEqualWithoutFunctions);
  }),
);

NeighborhoodsCopyForm.propTypes = {
  dirty: PropTypes.bool,
  handleSubmit: PropTypes.func,
  linkedList: PropTypes.instanceOf(List),
  unlinkedList: PropTypes.instanceOf(List),
  onLinkedRowSelection: PropTypes.func,
  linkedSelectedIds: PropTypes.instanceOf(Set),
  allList: PropTypes.instanceOf(List),
  onUnlinkedRowSelection: PropTypes.func,
  unlinkedSelectedIds: PropTypes.instanceOf(Set),
  getPostcodeIndexList: PropTypes.func.isRequired,
  neighborhoodIds: PropTypes.array,
  change: PropTypes.func,
  submitting: PropTypes.bool,
  getLocalisationMessage: PropTypes.func.isRequired,
  coverage_type: PropTypes.string,
  getJurisdictionList: PropTypes.func,
  jurisdictions: PropTypes.array,
  postal_codes: PropTypes.array,
  suppliers: PropTypes.array,
};

function NeighborhoodsCopyForm(props) {
  const { getLocalisationMessage } = props;

  return (
    <Card>
      <NavigationPrompt
        when={props.dirty}
        message={getLocalisationMessage(
          "you_havent_saved_your_changes_yet_do_you_want_to_leave_without_saving",
          "You haven't saved your changes yet. Do you want to leave without saving?",
        )}
      />
      <CardContent>
        <FlexBox flex={true} style={{ marginBottom: 10, marginTop: 10 }}>
          <FormSelectField
            name="coverage_type"
            label={getLocalisationMessage(
              "warehouse_cover_type",
              "Coverage Type",
            )}
            options={NeighborhoodTypes}
            formatOption={option => {
              switch (option) {
                case POSTAL_CODE:
                  return getLocalisationMessage(
                    "warehouse_by_postalcode",
                    option,
                  );
                case JURISDICTION:
                  return getLocalisationMessage(
                    "warehouse_by_jurisdiction",
                    option,
                  );
                case CHILD:
                  return getLocalisationMessage(
                    "warehouse_by_children",
                    option,
                  );
                default:
                  return getLocalisationMessage(
                    "warehouse_by_postalcode",
                    option,
                  );
              }
            }}
          />
        </FlexBox>
        <FlexBox container={8}>
          <FlexBox gutter={8} flex={true}>
            <FlexBox flex={true}>
              <FlexBox gutter={8} flex={true} direction="column">
                <FlexBox>
                  <h4>
                    {getLocalisationMessage(
                      "covered_neighbourhoods",
                      "Covered Neighbourhoods",
                    )}
                  </h4>
                </FlexBox>
                <FlexBox flex={true}>
                  <NeighborhoodsCopyFormTable
                    list={props.linkedList}
                    selectedIds={props.linkedSelectedIds}
                    onRowSelection={props.onLinkedRowSelection}
                    isLoading={!props.allList.size || props.submitting}
                    headerActions={
                      <Button
                        onClick={() => {
                          props.onLinkedRowSelection([]);
                          switch (props.coverage_type) {
                            case POSTAL_CODE:
                              props.change(
                                "postal_codes",
                                _.difference(
                                  props.postal_codes,
                                  props.linkedSelectedIds.toArray(),
                                ),
                              );
                              break;
                            case JURISDICTION:
                              props.change(
                                "jurisdictions",
                                _.difference(
                                  props.postal_codes,
                                  props.linkedSelectedIds.toArray(),
                                ),
                              );
                              break;
                            case CHILD:
                              props.change(
                                "suppliers",
                                _.difference(
                                  props.suppliers,
                                  props.linkedSelectedIds.toArray(),
                                ),
                              );
                              break;
                            default:
                              break;
                          }
                        }}
                      >
                        {getLocalisationMessage(
                          "remove_selected",
                          "Remove Selected",
                        )}
                      </Button>
                    }
                    formatRow={row => {
                      switch (props.coverage_type) {
                        case POSTAL_CODE:
                          return <strong>{row.cellData.get("index")}</strong>;
                        case JURISDICTION:
                          return <strong>{row.cellData.get("name")}</strong>;
                        case CHILD:
                          return <strong>{row.cellData.get("index")}</strong>;
                        default:
                          return <strong>{row.cellData.get("index")}</strong>;
                      }
                    }}
                  />
                </FlexBox>
              </FlexBox>
            </FlexBox>
            <FlexBox flex={true}>
              <FlexBox gutter={8} flex={true} direction="column">
                <FlexBox>
                  <h4>
                    {getLocalisationMessage(
                      "non_service_neighbourhoods",
                      "Non Service Neighbourhoods",
                    )}
                  </h4>
                </FlexBox>
                <FlexBox flex={true}>
                  <NeighborhoodsCopyFormTable
                    list={props.unlinkedList}
                    isLoading={!props.allList.size || props.submitting}
                    selectedIds={props.unlinkedSelectedIds}
                    onRowSelection={props.onUnlinkedRowSelection}
                    formatRow={row => {
                      switch (props.coverage_type) {
                        case POSTAL_CODE:
                          return <strong>{row.cellData.get("index")}</strong>;
                        case JURISDICTION:
                          return <strong>{row.cellData.get("name")}</strong>;
                        case CHILD:
                          return <strong>{row.cellData.get("name")}</strong>;
                        default:
                          return <strong>{row.cellData.get("name")}</strong>;
                      }
                    }}
                    headerActions={
                      <Button
                        onClick={() => {
                          props.onUnlinkedRowSelection([]);
                          switch (props.coverage_type) {
                            case POSTAL_CODE:
                              props.change(
                                "postal_codes",
                                _.union(
                                  props.postal_codes,
                                  props.unlinkedSelectedIds.toArray(),
                                ),
                              );
                              break;
                            case JURISDICTION:
                              props.change(
                                "jurisdictions",
                                _.union(
                                  props.jurisdictions,
                                  props.unlinkedSelectedIds.toArray(),
                                ),
                              );
                              break;
                            case CHILD:
                              props.change(
                                "suppliers",
                                _.union(
                                  props.suppliers,
                                  props.unlinkedSelectedIds.toArray(),
                                ),
                              );
                              break;
                            default:
                              break;
                          }
                        }}
                      >
                        {getLocalisationMessage("add_selected", "Add Selected")}
                      </Button>
                    }
                  />
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
        <Button onClick={() => props.handleSubmit()}>
          {getLocalisationMessage("save", "Save")}
        </Button>
      </CardContent>
    </Card>
  );
}

export default enhancer(NeighborhoodsCopyForm);
