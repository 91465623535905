import { Observable } from "rxjs";
import React from "react";
import { List, fromJS } from "immutable";
import fp from "lodash/fp";
import { componentFromStream } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { AutoCompleteComponent } from "./FormAutoComplete";
import { isEqualData } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { ACTIVE } from "../../constants/OverallStatus";
import { getCachedTag, getTagPredictions } from "../../api/admin/AdminTagApi";

const baseFilter = new DataListFilter({ page: 0, size: 20, status: ACTIVE });

const parseInput = name => ({ name });
const formatOption = fp.get("name");

const TagAutoComplete = componentFromStream(propsStream => {
  const valueStream = propsStream
    .map(fp.get("input.value"))
    .distinctUntilChanged(isEqualData)
    .switchMap(value =>
      value && value.id && !value.name
        ? getCachedTag(value.id)
            .map(item => item.toJS())
            .catch(() => Observable.of(value))
            .startWith(value)
        : Observable.of(value),
    );

  const optionsStream = propsStream
    .map(
      fp.flow(fp.get("input.value.name"), name => baseFilter.setSearch(name)),
    )
    .distinctUntilChanged(isEqualData)
    .switchMap(filter =>
      getTagPredictions(filter)
        .map(fp.flow(fp.get("payload.data"), fp.toArray, fromJS))
        .catch(() => Observable.of(List())),
    )
    .distinctUntilChanged(isEqualData)
    .map(v => v.toJS());

  return propsStream.combineLatest(
    valueStream,
    optionsStream,
    (props, value, options) => (
      <AutoCompleteComponent
        {...props}
        options={options}
        filter={fp.stubTrue}
        parseInput={parseInput}
        formatOption={formatOption}
        input={{ ...props.input, value }}
      />
    ),
  );
});

FormTagAutoComplete.propTypes = {
  sheet: PropTypes.object,
  classes: PropTypes.object,

  name: PropTypes.string.isRequired,

  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  openOnFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  maxSearchResults: PropTypes.number,

  validate: PropTypes.func,

  hintText: PropTypes.node,
  label: PropTypes.node,
};

FormTagAutoComplete.defaultProps = {
  maxSearchResults: 10,
};

export default function FormTagAutoComplete(props) {
  return <Field {...props} component={TagAutoComplete} />;
}
