import React from "react";
import { OrderedSet } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream, withContext } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Button, Card, CardActions, CardContent } from "@material-ui/core";
import { connect } from "react-redux";
import FormSelectField from "../form/FormSelectField";
import FormRuleTypeChips from "../form/FormRuleTypeChips";
import FormSupplierChips from "../form/FormSupplierChips";
import FlexBox from "../ui-core/FlexBox";
import { isEqualData } from "../../helpers/DataUtils";
import { getObjectId } from "../../helpers/FormUtils";
import { formatText } from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import {
  parseIntString,
  parseString,
  stringifyArray,
} from "../../helpers/SerializeUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const idsToObjectArray = fp.flow(
  parseIntString,
  fp.map(id => ({ id })),
);
const ACTIVE = "ACTIVE";
const INACTIVE = "INACTIVE";
const shopMenuOptions = OrderedSet.of(ACTIVE, INACTIVE);

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    card: {
      "& > div": { display: "flex", flexDirection: "column", flex: "1 1 0%" },
    },
  }),
  withContext(
    {
      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,
      getCachedVehicle: PropTypes.func,
      getVehiclePredictions: PropTypes.func,
      getCachedServiceType: PropTypes.func,
      getServiceTypePredictions: PropTypes.func,
      getCachedCustomer: PropTypes.func.isRequired,
      getCustomerPredictions: PropTypes.func.isRequired,
    },
    props => ({
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
      getCachedVehicle: props.getVehiclePredictions,
      getVehiclePredictions: props.getVehiclePredictions,
      getCachedServiceType: props.getCachedServiceType,
      getServiceTypePredictions: props.getServiceTypePredictions,
      getCachedCustomer: props.getCachedCustomer,
      getCustomerPredictions: props.getCustomerPredictions,
    }),
  ),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("filter", isEqualData)
      .map(props => ({
        supplierIds: idsToObjectArray(props.filter.getValue("supplier_ids")),
        serviceTypes: parseString(props.filter.getValue("service_types")),
        vehicleTypes: parseString(props.filter.getValue("vehicle_types")),
        ruleStatus: props.filter.getValue("rule_status"),
        ruleTypes: parseString(props.filter.getValue("rule_types")),
      }))
      .distinctUntilChanged(isEqualData);

    const onSubmit = (values, dispatch, props) =>
      props.onFilterChange(
        props.filter.withMutations((filter: DataListFilter) => {
          filter.setValueMap({
            supplier_ids: null,
            service_types: null,
            vehicle_types: null,
            rule_status: null,
            rule_types: null,
          });

          if (!fp.isEmpty(values.supplierIds)) {
            filter.setValue(
              "supplier_ids",
              stringifyArray(values.supplierIds.map(getObjectId)),
            );
          }

          if (!fp.isEmpty(values.ruleStatus)) {
            filter.setValue("rule_status", values.ruleStatus);
          }
          if (!fp.isEmpty(values.serviceTypes)) {
            filter.setValue(
              "service_types",
              stringifyArray(values.serviceTypes),
            );
          }
          if (!fp.isEmpty(values.vehicleTypes)) {
            filter.setValue(
              "vehicle_types",
              stringifyArray(values.vehicleTypes),
            );
          }
          if (!fp.isEmpty(values.ruleTypes)) {
            filter.setValue("rule_types", stringifyArray(values.ruleTypes));
          }
        }),
      );

    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,

        onSubmit,
        initialValues,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  reduxForm({
    form: "OrderRuleFilterForm",
    enableReinitialize: true,
  }),
);

OrderRuleFilterForm.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func,
  change: PropTypes.func,
  includeSuppliers: PropTypes.bool,
  dirty: PropTypes.bool,
  onDismiss: PropTypes.func,
  onFilterChange: PropTypes.func,
  reset: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function OrderRuleFilterForm(props) {
  return (
    <Card>
      <CardContent>
        <FlexBox justify="space-between" style={{ gap: 13 }}>
          <FlexBox md={5} style={{ width: `40%`, gap: 16 }} direction="column">
            <FormSupplierChips
              name="supplierIds"
              fullWidth={true}
              label={props.getLocalisationMessage("supplier", "Supplier")}
            />
            <FormSelectField
              name="ruleStatus"
              autoWidth={true}
              fullWidth={true}
              label={props.getLocalisationMessage("status", "Status")}
              options={shopMenuOptions}
              formatOption={x =>
                props.getLocalisationMessage(x) || formatText(x)
              }
            />
          </FlexBox>
          <FlexBox style={{ width: `55%` }} md={6} direction="column">
            <FormRuleTypeChips
              name="ruleTypes"
              fullWidth={true}
              label={props.getLocalisationMessage("rule_types", "Rule Types")}
            />
          </FlexBox>
        </FlexBox>
      </CardContent>
      <FlexBox justify="flex-end">
        <CardActions>
          <Button
            onClick={() => {
              props.change("supplierIds", null);
              props.change("serviceTypes", null);
              props.change("vehicleTypes", null);
              props.change("ruleStatus", null);
              props.change("ruleTypes", null);
            }}
          >
            {props.getLocalisationMessage("clear", "Clear")}
          </Button>
          {props.dirty ? (
            <Button onClick={props.reset}>
              {props.getLocalisationMessage("reset", "Reset")}
            </Button>
          ) : (
            Boolean(props.onDismiss) && (
              <Button onClick={props.onDismiss}>
                {props.getLocalisationMessage("dismiss", "Dismiss")}
              </Button>
            )
          )}

          <Button onClick={props.handleSubmit}>
            {props.getLocalisationMessage("submit", "Submit")}
          </Button>
        </CardActions>
      </FlexBox>
    </Card>
  );
}

export default enhancer(OrderRuleFilterForm);
