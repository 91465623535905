import { Observable } from "rxjs";
import React from "react";
import sprintf from "sprintf";
import { fromJS, List, Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  createEventHandler,
  getContext,
  mapPropsStream,
} from "recompose";
import PropTypes from "prop-types";
import { IconButton, MenuItem, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { Add } from "@material-ui/icons";
import { isEqualData } from "../../helpers/DataUtils";
import { formatText } from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getUser } from "../../reducers/ProfileReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  SUPPLIER_ITEM_COMMUNICATION_URL,
  SUPPLIER_ITEM_CURRENCY_URL,
  SUPPLIER_ITEM_MONTHLY_QUOTA_URL,
  SUPPLIER_ITEM_NEIGHBORHOODS_URL,
  SUPPLIER_ITEM_PACKAGES_URL,
} from "../../constants/AdminPathConstants";
import { loadSupplierList } from "../../actions/admin/AdminSupplierActions";
import AdminSupplierEditDialogWrapper from "../../wrappers/admin/AdminSupplierEditDialogWrapper";
import AdminSupplierCreateDialogWrapper from "../../wrappers/admin/AdminSupplierCreateDialogWrapper";
import Toggle from "../../components/form/Toggle";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import MenuButtonMore from "../../components/ui-core/MenuButtonMore";
import OverallStatusTabs from "../../components/ui-core/OverallStatusTabs";
import DataList, { DataListColumn } from "../../components/data-list/DataList";
import DateTimeCell from "../../components/data-list/DateTimeCell";
import MultiLineCell from "../../components/data-list/MultiLineCell";
import CheckOverallStatus from "../../components/route-validators/CheckOverallStatus";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { ROLE_ADMIN_VIEWER } from "../../../shared/constants/Authorities";
import { CREATE_SUPPLIER_CSV_URL } from "../../../shared/constants/FileProxyControllerConstants";
import { hasRole } from "../../helpers/RoleUtils";

const enhancer = compose(
  useSheet({
    appBarRightAction: {
      marginTop: "5px",
      marginRight: "5px",
      fontSize: "15px",
    },
    appBarRightActionToggle: {
      whiteSpace: "nowrap",
      float: "right",
      marginLeft: "12px",
    },
  }),
  getContext({
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
  }),
  connect(
    (state) => {
      const userRoles = getUser(state).get("roles") || [];

      return {
        getLocalisationMessage: (code, defaultMessage) =>
          getMessage(state, code, defaultMessage),
        isAdminViewer: hasRole(userRoles, ROLE_ADMIN_VIEWER),
      };
    },
    { loadSupplierList },
  ),
  mapPropsStream((propsStream) => {
    const { handler: onRequestRefresh, stream: onRequestRefreshStream } =
      createEventHandler();

    const filterStream = propsStream
      .map(fp.get("location.query"))
      .distinctUntilChanged()
      .map((query) => new DataListFilter(query))
      .distinctUntilChanged(isEqualData);

    const listResponseStream = filterStream
      .distinctUntilChanged(isEqualData)
      .withLatestFrom(propsStream)
      .switchMap(([filter, props]) =>
        props
          .loadSupplierList(filter)
          .repeatWhen(() => onRequestRefreshStream)
          .catch(() => Observable.of({})),
      )
      .startWith({})
      .map((response) => fromJS(response))
      .map((response) =>
        Map({
          pending: response.get("pending", false),
          total: response.getIn(["payload", "data", "total"], 0),
          list: response.getIn(["payload", "data", "list"], List()),
        }),
      );

    return propsStream
      .combineLatest(
        filterStream,
        listResponseStream,
        (props, filter, listResponse) => ({
          ...props,
          filter,
          onRequestRefresh,
          list: listResponse.get("list"),
          total: listResponse.get("total"),
          isLoading: listResponse.get("pending"),
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

AdminSupplierList.propTypes = {
  sheet: PropTypes.object,
  location: PropTypes.object,
  setLocationQueryFilter: PropTypes.func,
  setLocationQuery: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  total: PropTypes.number,
  isLoading: PropTypes.bool,
  list: PropTypes.instanceOf(List),
  filter: PropTypes.instanceOf(DataListFilter),
  getLocalisationMessage: PropTypes.func,

  isAdminViewer: PropTypes.bool,
};

function AdminSupplierList(props) {
  const {
    sheet: { classes },
    location,
    getLocalisationMessage,
  } = props;
  const useSolr = props.filter.getBoolValue("use_solr");

  return (
    <AdminAppLayout
      slug="couriers"
      title={getLocalisationMessage("suppliers", "Suppliers")}
      appBarRightAction={
        <div className={classes.appBarRightAction}>
          <Toggle
            accent={true}
            label={getLocalisationMessage("fast_search", "Fast Search")}
            value={useSolr}
            className={classes.appBarRightActionToggle}
            onChange={(value) =>
              props.setLocationQueryFilter(
                props.filter.setValue("use_solr", value),
              )
            }
          />
        </div>
      }
    >
      <CheckOverallStatus location={props.location} />
      <OverallStatusTabs location={props.location} />

      <AdminSupplierCreateDialogWrapper
        open={props.location.query.create === "true"}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("create"));
        }}
      />

      <AdminSupplierEditDialogWrapper
        tab={location.query.view_tab}
        onTabChange={(tab) => props.setLocationQuery(fp.set("view_tab", tab))}
        supplierId={fp.toFinite(props.location.query.view)}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("view"));
        }}
        rightIcon={
          <MenuButtonMore color="white">
            <MenuItem
              target="_blank"
              component="a"
              href={sprintf(
                SUPPLIER_ITEM_MONTHLY_QUOTA_URL,
                fp.toFinite(props.location.query.view),
              )}
            >
              {getLocalisationMessage("monthly_quota", "Monthly Quota")}
            </MenuItem>
            <MenuItem
              target="_blank"
              component="a"
              href={sprintf(
                SUPPLIER_ITEM_NEIGHBORHOODS_URL,
                fp.toFinite(props.location.query.view),
              )}
            >
              {getLocalisationMessage("neighbourhoods", "Neighbourhoods")}
            </MenuItem>
            {false && (
              <MenuItem
                target="_blank"
                component="a"
                href={sprintf(
                  SUPPLIER_ITEM_PACKAGES_URL,
                  fp.toFinite(props.location.query.view),
                )}
              >
                {getLocalisationMessage("packages", "Packages")}
              </MenuItem>
            )}
            <MenuItem
              target="_blank"
              component="a"
              href={sprintf(
                SUPPLIER_ITEM_COMMUNICATION_URL,
                fp.toFinite(props.location.query.view),
              )}
            >
              {getLocalisationMessage(
                "communication_settings",
                "Communication Settings",
              )}
            </MenuItem>
            <MenuItem
              target="_blank"
              component="a"
              href={sprintf(
                SUPPLIER_ITEM_CURRENCY_URL,
                fp.toFinite(props.location.query.view),
              )}
            >
              {getLocalisationMessage("currency_settings", "Currency Settings")}
            </MenuItem>
          </MenuButtonMore>
        }
      />

      <DataList
        isLoading={props.isLoading}
        totalCount={props.total}
        filter={props.filter}
        onFilterChange={(filter) => props.setLocationQueryFilter(filter)}
        overscanRowCount={6}
        rowCount={props.list.size}
        rowGetter={(options) => props.list.get(options.index)}
        cardActionIcons={
          <div>
            {!props.isAdminViewer && (
              <Tooltip
                title={getLocalisationMessage(
                  "create_new_supplier",
                  "Create New Supplier",
                )}
              >
                <IconButton
                  onClick={() => props.setLocationQuery(fp.set("create", true))}
                >
                  <Add />
                </IconButton>
              </Tooltip>
            )}

            <MenuButtonMore>
              <MenuItem
                target="_blank"
                component="a"
                href={updateQuery(
                  CREATE_SUPPLIER_CSV_URL,
                  props.filter.getDefinedValues(),
                )}
              >
                {getLocalisationMessage("download_csv", "Download CSV")}
              </MenuItem>
              <MenuItem
                target="_blank"
                component="a"
                href={updateQuery(CREATE_SUPPLIER_CSV_URL, {
                  ...props.filter.getDefinedValues(),
                  is_denied_party_screening: true,
                })}
              >
                {getLocalisationMessage("dps_data_csv", "DPS Data CSV")}
              </MenuItem>
            </MenuButtonMore>
          </div>
        }
      >
        <DataListColumn
          width={96}
          label={getLocalisationMessage("id", "ID")}
          dataKey="id"
          disableSort={true}
          justifyContent="center"
          cellRenderer={(row) => (
            <strong>
              {!props.isAdminViewer ? (
                <Link
                  to={updateQuery(
                    props.location,
                    fp.set("view", row.cellData.get("id")),
                  )}
                >
                  {row.cellData.get("id")}
                </Link>
              ) : (
                row.cellData.get("id")
              )}
            </strong>
          )}
        />

        <DataListColumn
          width={128}
          label={getLocalisationMessage("courier_name", "Courier Name")}
          dataKey="supplier_name"
          disableSort={true}
          cellRenderer={(row) => (
            <div>
              {row.cellData.get("name") || getLocalisationMessage("na", "N/a")}
            </div>
          )}
        />

        <DataListColumn
          width={128}
          label={getLocalisationMessage("courier_contact", "Courier Contact")}
          dataKey="contact_first_name"
          disableSort={true}
          cellRenderer={(row) => (
            <MultiLineCell
              fallBackValue={getLocalisationMessage("na", "N/a")}
              firstLine={row.cellData.get("contact_first_name")}
              secondLine={row.cellData.get("contact_last_name")}
            />
          )}
        />

        <DataListColumn
          width={128}
          label={getLocalisationMessage("contacts", "Contacts")}
          disableSort={true}
          dataKey="supplier_email"
          cellRenderer={(row) => (
            <MultiLineCell
              fallBackValue={getLocalisationMessage("na", "N/a")}
              firstLine={row.cellData.get("supplier_email")}
              secondLine={row.cellData.get("contact_phone")}
            />
          )}
        />

        <DataListColumn
          width={128}
          disableSort={true}
          label={getLocalisationMessage(
            "drivers_and_fleet",
            "Drivers and Fleet",
          )}
          dataKey="number_of_drivers"
          justifyContent="center"
          cellRenderer={(row) => (
            <MultiLineCell
              firstLine={`${getLocalisationMessage(
                "drivers",
                "Drivers",
              )}: ${fp.toFinite(row.cellData.get("number_of_drivers"))}`}
              secondLine={`${getLocalisationMessage(
                "fleet",
                "Fleet",
              )}: ${fp.toFinite(row.cellData.get("number_of_fleet"))}`}
            />
          )}
        />

        <DataListColumn
          width={128}
          disableSort={true}
          label={getLocalisationMessage(
            "daily_limit_quota",
            "Daily Limit / Quota",
          )}
          dataKey="daily_limit_and_quota"
          justifyContent="center"
          cellRenderer={(row) => (
            <MultiLineCell
              firstLine={`${getLocalisationMessage(
                "limit",
                "Limit",
              )}: ${fp.toFinite(row.cellData.get("daily_limit"))}`}
              secondLine={`${getLocalisationMessage(
                "quota",
                "Quota",
              )}: ${fp.toFinite(row.cellData.get("daily_quota"))}`}
            />
          )}
        />

        <DataListColumn
          width={96}
          justifyContent="center"
          label={getLocalisationMessage("status", "Status")}
          dataKey="status"
          disableSort={true}
          cellRenderer={(row) => {
            const status = row.cellData.get("status");
            return getLocalisationMessage(status, formatText(status));
          }}
        />

        <DataListColumn
          width={96}
          justifyContent="center"
          label={getLocalisationMessage("prefix", "Prefix")}
          dataKey="prefix"
          disableSort={true}
          cellRenderer={(row) =>
            row.cellData.get("prefix") || getLocalisationMessage("na", "N/a")
          }
        />

        <DataListColumn
          width={96}
          justifyContent="center"
          label={getLocalisationMessage("joined_date", "Joined Date")}
          dataKey="created_date"
          disableSort={true}
          cellRenderer={(row) => (
            <DateTimeCell date={row.cellData.get("created_date")} />
          )}
        />
      </DataList>
    </AdminAppLayout>
  );
}

export default enhancer(AdminSupplierList);
