import React from "react";
import _ from "lodash";
import { OrderedSet } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import { Card, CardContent, Button, CardActions } from "@material-ui/core";
import { connect } from "react-redux";
import FormChipAutoComplete from "../form/FormChipAutoComplete";
import FlexBox from "../ui-core/FlexBox";
import { pureComponent } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { formatText } from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { parseString, stringifyArray } from "../../helpers/SerializeUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("filter", isEqualData)
      .map(props => {
        const x: DataListFilter = props.filter;
        return {
          authorities: parseString(x.getValue("authority")),
        };
      })
      .distinctUntilChanged(isEqualData);

    const onSubmit = (values, dispatch, props) =>
      props.onFilterChange(
        props.filter.withMutations((filter: DataListFilter) => {
          filter.setValueMap({
            authority: null,
            /* supplier_ids: null, */
          });
          if (!fp.isEmpty(values.authorities)) {
            filter.setValueMap({
              authority: stringifyArray(values.authorities),
            });
          }
        }),
      );

    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,

        onSubmit,
        initialValues,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  reduxForm({ form: "UserFilterForm", enableReinitialize: true }),
  pureComponent(fp.pick(["dirty", "initialValues"])),
  formValues({
    authorities: "authorities",
  }),
);

UserFilterForm.propTypes = {
  classes: PropTypes.object,

  dirty: PropTypes.bool,
  reset: PropTypes.func,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,

  onDismiss: PropTypes.func,
  getLocalisationMessage: PropTypes.func,

  userRoles: PropTypes.instanceOf(OrderedSet),
};

function UserFilterForm(props) {
  const {
    getLocalisationMessage,
    reset,
    dirty,
    handleSubmit,
    onDismiss,
    userRoles,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardContent>
          <FlexBox flex={true}>
            <FormChipAutoComplete
              name="authorities"
              fullWidth={true}
              options={userRoles}
              formatOption={option =>
                _.toUpper(getLocalisationMessage(option) || formatText(option))
              }
              hintText={getLocalisationMessage("type_here_to_search")}
              label={getLocalisationMessage("role", "Role")}
            />
          </FlexBox>
        </CardContent>
        <FlexBox justify="flex-end">
          <CardActions>
            {dirty ? (
              <Button onClick={reset}>
                {getLocalisationMessage("reset", "Reset")}
              </Button>
            ) : (
              Boolean(onDismiss) && (
                <Button onClick={onDismiss}>
                  {getLocalisationMessage("dismiss", "Dismiss")}
                </Button>
              )
            )}
            <Button type="submit">
              {getLocalisationMessage("submit", "Submit")}
            </Button>
          </CardActions>
        </FlexBox>
      </Card>
    </form>
  );
}

export default enhancer(UserFilterForm);
