import React from "react";
import _ from "lodash";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import NavTabs from "../ui-core/NavTabs";

const enhancer = compose(withRouter);

RoutingTabs.propTypes = {
  location: PropTypes.object,
  tabs: PropTypes.array,
  mergeQuery: PropTypes.bool,
  omitQueryParams: PropTypes.arrayOf(PropTypes.string),
};

RoutingTabs.defaultProps = { mergeQuery: true };

function RoutingTabs({
  tabs,
  location: { query, pathname },
  mergeQuery,
  omitQueryParams,
  ...restProps
}) {
  const nextQuery = mergeQuery ? _.omit(query, omitQueryParams || []) : {};
  const routingTabs = _.map(tabs, ({ value, ...tabProps }) => ({
    ...tabProps,
    value,
    to: { pathname: value, query: nextQuery },
  }));

  const tabsProps = _.omit(restProps, ["dispatch"]);

  return <NavTabs {...tabsProps} value={pathname} tabs={routingTabs} />;
}

export default enhancer(RoutingTabs);
